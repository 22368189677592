import { styled } from "@mui/material/styles"
import { Box, Popover, Typography } from "@mui/material"

export const StyledDivError = styled("div")(({ theme }) => ({
  color: theme.palette.error.main,
  margin: "3px 14px 0 14px",
  lineHeight: "1.66",
  fontSize: "12px"
}))

export const StyledSpinnerBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: "5",
  "& .MuiTab-iconWrapper": {
    width: "50px",
    height: "50px"
  }
})

export const StyledPopover = styled(Popover)(({ theme }) => ({
  "& .MuiPopover-paper": {
    boxShadow: theme.shadows[5],
    transform: "translate(24px, -20px)"
  },
  "& .MuiMenuItem-root": {
    paddingRight: "50px",
    "&:hover": {
      backgroundColor: "#f0f0f0"
    }
  }
}))

export const StyledGreyTypography = styled(Typography)<{ active: boolean }>(({ theme, active }) => ({
  color: active ? "inherit" : theme.palette.grey[500]
}))