import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { faEdit, faFolder, faFolderPlus, faGlobe, faUserLock } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Stack,
  Tooltip,
  Typography
} from "@mui/material"
import { hasRole } from "auth/hasRole"
import { IApiFunc, IApiResult } from "auth/interface/api.interface"
import { useApi } from "auth/useApi"
import { AppContext } from "components/AppContextProvider"
import BodyRichContent from "components/Fields/BodyRichContent"
import LoadingPage from "components/Loading/LoadingPage"
import PaperBox from "components/PaperBox"
import { Sidebar } from "components/navigation/Sidebar"
import theme from "css/theme/light"
import { apiEndpoints } from "generated/apiEndpoints"
import { allRoles } from "generated/roles"
import { allRoutes } from "generated/routes"
import FileList from "pages/documents/components/FileList"
import FolderForm from "pages/documents/components/Form/FolderForm"
import { useStyles } from "pages/documents/components/useStyles"
import { IDocumentFolder, IDocumentFormValues } from "pages/documents/interface/folder.interface"
import { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import Moment from "react-moment"
import { Link, NavLink, useHistory } from "react-router-dom"

const DetailsFolder = ({ match }: any) => {
  const { t } = useTranslation("translation", { keyPrefix: "documents" })
  const appContext = useContext(AppContext)
  const classes = useStyles()
  const [folderResult, getFolderApi] = useApi() as [IApiResult, IApiFunc]
  const [deleteResult, deleteFolder] = useApi() as [IApiResult, IApiFunc]
  const [updateFolderResult, updateFolder] = useApi() as [IApiResult, IApiFunc]
  const [initialValues, setInitialValues] = useState<IDocumentFolder | undefined>()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const [newFolderValues, setNewFolderValues] = useState<any>({
    id: 0,
    name: "",
    description: "",
    body: "",
    isVisibleStartPage: false,
    companyIds: [],
    departmentIds: [],
    customRoleIds: [],
    attachments: [],
    totalAttachments: 0,
    folderId: 0,
    folder: null
  })
  const routerHistory = useHistory()
  const [addFolderResult, addFolder] = useApi() as [IApiResult, IApiFunc]

  const handleSubmitNewfolder = (values: IDocumentFormValues) => {
    addFolder.post(apiEndpoints.documents.adddocument, values)
  }

  useEffect(() => {
    if (appContext.currentCompanyId !== null) {
      getFolderApi.get(
        apiEndpoints.documents.getdocument
          .replace("{id}", match.params.id)
          .replace("{companyId}", appContext.currentCompanyId)
      )
    }
  }, [getFolderApi, match.params.id, appContext.currentCompanyId])

  useEffect(() => {
    if (folderResult.status === 2) {
      setInitialValues(folderResult.data)
      setNewFolderValues((prevValues: any) => ({
        ...prevValues,
        folderId: folderResult.data?.id ?? 0
      }))
    }
  }, [folderResult])

  useEffect(() => {
    if (updateFolderResult.status === 2 || addFolderResult.status === 2) {
      getFolderApi.get(
        apiEndpoints.documents.getdocument
          .replace("{id}", match.params.id)
          .replace("{companyId}", appContext.currentCompanyId)
      )
      appContext.toggleSideBar(false)
    }
  }, [updateFolderResult, addFolderResult])

  useEffect(() => {
    if (deleteResult.status === 2) {
      routerHistory.push(allRoutes.Documents())
    }
  }, [deleteResult])

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      {hasRole(appContext.appUser, allRoles.DocumentsAdmin) && (
        <Sidebar>
          <FolderForm
            formValues={newFolderValues}
            handleSubmit={handleSubmitNewfolder}
            results={addFolderResult}
          />
        </Sidebar>
      )}

      <Container>
        <Box marginY={3} mb={2}>
          <Box display="flex" flexDirection="row" alignItems="center">
            <Box order={2} p={1} flexGrow={2}>
              <Grid item>
                <Typography variant="h1"> {t(`title`)}</Typography>
                <Typography variant="paragraphMedium" color="textSecondary">
                  {
                    appContext.appUser.customer.companies.find(
                      (x: any) => x.id === appContext.currentCompanyId
                    ).name
                  }
                </Typography>
              </Grid>
            </Box>
            <Box order={3} p={1}>
              {hasRole(appContext.appUser, allRoles.DocumentsAdmin) && (
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    id="edit-button"
                    aria-controls={open ? "edit-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}>
                    Redigera
                  </Button>
                  <Menu
                    id="edit-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "edit-button"
                    }}>
                    <MenuList>
                      <MenuItem component={NavLink} to={allRoutes.DocumentsEdit(match.params.id)}>
                        <ListItemIcon>
                          <FontAwesomeIcon icon={faEdit} />
                        </ListItemIcon>
                        <ListItemText>Redigera mapp</ListItemText>
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          appContext.toggleSideBar(true)
                          handleClose()
                        }}>
                        <ListItemIcon>
                          <FontAwesomeIcon icon={faFolderPlus} />
                        </ListItemIcon>
                        <ListItemText> {t(`add_button`)}</ListItemText>
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </>
              )}
            </Box>
          </Box>
        </Box>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          spacing={3}
          marginBottom={3}
          marginTop={2}>
          <Grid item xs={12} md={4} lg={5}>
            <Breadcrumbs aria-label="breadcrumb">
              <Box
                component={Link}
                to={allRoutes.Documents()}
                style={{ color: theme.palette.primary.main, textDecoration: "none" }}>
                Dokument
              </Box>
              {folderResult.status === 2 &&
                folderResult?.data?.breadcrumbs?.map(({ name, id }: any, index: number) => {
                  return index !== folderResult?.data?.breadcrumbs.length - 1 ? (
                    <Box
                      component={Link}
                      to={allRoutes.DocumentsDetails(id)}
                      style={{ color: theme.palette.primary.main, textDecoration: "none" }}>
                      {name}
                    </Box>
                  ) : (
                    <Typography key={index} color="text.primary" fontWeight={600}>
                      {name}
                    </Typography>
                  )
                })}
            </Breadcrumbs>
          </Grid>
        </Grid>

        <Grid container direction="row" justifyContent="space-between" spacing={3} marginBottom={3}>
          <Grid item xs={12} md={4} lg={5}>
            {folderResult.status === 2 &&
              folderResult?.data?.folders?.map((folder: IDocumentFolder, index: number) => {
                return (
                  <Box gap={2} mb={1}>
                    <NavLink
                      style={{ color: "inherit", textDecoration: "inherit" }}
                      className={classes.folderFrame}
                      to={allRoutes.DocumentsDetails(folder.id)}
                      isActive={(match, location): any => {
                        if (!match) {
                          return false
                        }
                        return true
                      }}>
                      <Grid container direction="row" spacing={1} justifyContent="space-between">
                        <Grid item xs="auto">
                          <FontAwesomeIcon
                            size="xs"
                            color={theme.palette.warning["main"]}
                            icon={faFolder as IconProp}
                          />
                        </Grid>
                        <Grid item xs>
                          <Stack direction="row" alignItems="center" spacing={1}>
                            <Typography display="inline-block" variant="bodyMedium">
                              {folder.name}
                            </Typography>
                          </Stack>
                          <Grid container direction="row" alignItems="center" spacing={1}>
                            <Grid item>
                              {folder.permissions !== undefined && folder.permissions?.length > 0 ? (
                                <Tooltip title={folder?.permissions?.map((x: string) => x).join(", ")}>
                                  <IconButton color="success" size="small" sx={{ padding: 0 }}>
                                    <FontAwesomeIcon
                                      size="xs"
                                      color={theme.palette.grey[500]}
                                      icon={faUserLock as IconProp}
                                    />
                                  </IconButton>
                                </Tooltip>
                              ) : (
                                <Tooltip title="Delat med alla">
                                  <IconButton color="success" size="small" sx={{ padding: 0 }}>
                                    <FontAwesomeIcon
                                      size="xs"
                                      color={theme.palette.grey[500]}
                                      icon={faGlobe as IconProp}
                                    />
                                  </IconButton>
                                </Tooltip>
                              )}
                            </Grid>
                            <Grid item>
                              <Typography variant="bodyXsmall" color="textSecondary">
                                |
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography variant="bodyXsmall" color="textSecondary">
                                {folder.totalAttachments}{" "}
                                {folder.totalAttachments === 1 ? t(`list.file`) : t(`list.files`)}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography variant="bodyXsmall" color="textSecondary">
                                |
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography variant="bodyXsmall" color="textSecondary">
                                Uppdaterad{" "}
                                <Moment utc local format="YYYY-MM-DD">
                                  {folder.updatedDate}
                                </Moment>
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography variant="bodyXsmall" color="textSecondary">
                                |
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography variant="bodyXsmall" color="textSecondary">
                                {folder.createdByUser?.name}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </NavLink>
                  </Box>
                )
              })}

            <FileList
              attachments={initialValues?.attachments}
              downloadUrl={apiEndpoints.documents.getattachment}
            />
          </Grid>
          <Grid item xs={12} md={8} lg={7}>
            {folderResult.status === 1 ? (
              <Container>
                <LoadingPage />
              </Container>
            ) : (
              folderResult.status === 2 && (
                <PaperBox marginTop={0}>
                  {folderResult.data?.name !== null ? (
                    <>
                      <Grid container direction="row" spacing={1} justifyContent="space-between">
                        <Grid item xs="auto">
                          <FontAwesomeIcon
                            color={theme.palette.warning["main"]}
                            size="1x"
                            icon={faFolder as IconProp}
                          />
                        </Grid>
                        <Grid item xs>
                          <Stack direction="row" alignItems="center" spacing={1}>
                            <Typography display="inline-block" variant="h4">
                              {folderResult.data?.name}
                            </Typography>
                          </Stack>
                          <Grid container direction="row" alignItems="center" spacing={1}>
                            <Grid item>
                              {folderResult.data?.permissions?.length > 0 ? (
                                <Tooltip
                                  title={folderResult.data?.permissions.map((x: string) => x).join(", ")}>
                                  <IconButton color="success" size="small" sx={{ padding: 0 }}>
                                    <FontAwesomeIcon
                                      size="xs"
                                      color={theme.palette.grey[500]}
                                      icon={faUserLock as IconProp}
                                    />
                                  </IconButton>
                                </Tooltip>
                              ) : (
                                <Tooltip title="Delat med alla">
                                  <IconButton color="success" size="small" sx={{ padding: 0 }}>
                                    <FontAwesomeIcon
                                      size="xs"
                                      color={theme.palette.grey[500]}
                                      icon={faGlobe as IconProp}
                                    />
                                  </IconButton>
                                </Tooltip>
                              )}
                            </Grid>
                            <Grid item>
                              <Typography variant="bodyXsmall" color="textSecondary">
                                |
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography variant="bodyXsmall" color="textSecondary">
                                Uppdaterad{" "}
                                <Moment utc local format="YYYY-MM-DD">
                                  {folderResult.data?.updatedDate}
                                </Moment>
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography variant="bodyXsmall" color="textSecondary">
                                |
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography variant="bodyXsmall" color="textSecondary">
                                {folderResult.data?.createdByUser?.name}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Box marginY={2}>
                        <Divider />
                      </Box>
                      <Typography variant="bodyLarge" marginBottom={3}>
                        {folderResult.data?.description}
                      </Typography>
                      <BodyRichContent content={folderResult.data?.body} />
                    </>
                  ) : (
                    <Grid container direction="row" spacing={1} justifyContent="space-between">
                      <Grid item xs="auto">
                        <Typography variant="h1" gutterBottom>
                          Mappen är låst
                        </Typography>
                        <Typography variant="paragraphMedium" color="textSecondary">
                          Kontakta din administratör för att få åtkomst till mappen.
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                </PaperBox>
              )
            )}
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}
export default DetailsFolder
