import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { faDownload, faSpinnerThird } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, Grid, IconButton, Stack, Typography } from "@mui/material"
import { IApiFunc, IApiResult } from "auth/interface/api.interface"
import { useApi } from "auth/useApi"
import theme from "css/theme/light"
import React, { useState } from "react"
import Moment from "react-moment"
import { getFileType } from "../helpers/getFileType"
import { IDocumentAttachment } from "../interface/folder.interface"
import { useStyles } from "./useStyles"

const FileList = ({ attachments, downloadUrl }: any) => {
  const [clickedFileId, setClickedFile] = useState<string | null>(null)
  const classes = useStyles()
  const [getAttachmentResult, getAttachment] = useApi() as [IApiResult, IApiFunc]

  const downloadAttachment = React.useCallback(
    (fileId, fileName) => {
      getAttachment.getBlob(downloadUrl.replace("{id}", fileId), fileName)
    },
    [getAttachment]
  )

  return (
    <>
      <div>
        {attachments &&
          attachments.length > 0 &&
          attachments.map((attachment: IDocumentAttachment, index: number) => (
            <Box gap={2} mb={attachments.length - 1 !== index ? 1 : 0} className={classes.folderFrame}>
              <Grid container direction="row" spacing={1} justifyContent="space-between">
                <Grid item xs="auto">
                  <FontAwesomeIcon
                    size="xs"
                    color={theme.palette.primary["dark"]}
                    icon={getFileType(attachment.fileId) as IconProp}
                  />
                </Grid>
                <Grid item xs>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Typography
                      display="inline-block"
                      variant="bodyMedium"
                      sx={{
                        "&:hover": {
                          cursor: "pointer"
                        }
                      }}
                      onClick={() => {
                        setClickedFile(attachment.fileId)
                        downloadAttachment(attachment.id, attachment.fileId)
                      }}>
                      {attachment.name}
                    </Typography>
                  </Stack>
                  <Grid container direction="row" alignItems="center" spacing={1}>
                    <Grid item>
                      <Typography variant="bodyXsmall" color="textSecondary">
                        Uppdaterad{" "}
                        <Moment utc local format="YYYY-MM-DD">
                          {attachment.updatedDate}
                        </Moment>
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="bodyXsmall" color="textSecondary">
                        |
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="bodyXsmall" color="textSecondary">
                        {attachment.createdByUser?.name}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs>
                  <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={1}>
                    <IconButton
                      edge="end"
                      type="button"
                      color="inherit"
                      aria-label="Ladda ner"
                      onClick={() => {
                        setClickedFile(attachment.fileId)
                        downloadAttachment(attachment.id, attachment.fileId)
                      }}>
                      <FontAwesomeIcon
                        color={theme.palette.grey["600"]}
                        spin={getAttachmentResult.status === 1 && clickedFileId === attachment.fileId}
                        icon={
                          getAttachmentResult.status === 1 && clickedFileId === attachment.fileId
                            ? (faSpinnerThird as IconProp)
                            : (faDownload as IconProp)
                        }
                        size="xs"
                      />
                    </IconButton>
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          ))}
      </div>
    </>
  )
}
export default FileList
