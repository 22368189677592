import { faUser, faUserSlash } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, Container, Grid, Tooltip, Typography } from "@mui/material"
import { hasRole } from "auth/hasRole"
import { useApiGet } from "auth/useApi2"
import { AppContext } from "components/AppContextProvider"
import Button from "components/Button"
import ClpTable from "components/ClpTable"
import { SelectColumnFilter } from "components/ClpTable/ClpTableFilterTypes"
import { GlobalLoaderContext } from "components/GlobalLoadingProvider"
import globalUseStyles from "css/theme/globalUseStyles.js"
import theme from "css/theme/light"
import { apiEndpoints } from "generated/apiEndpoints"
import { allRoles } from "generated/roles"
import { allRoutes } from "generated/routes"
import { useContext, useEffect, useMemo } from "react"
import { Link, useHistory } from "react-router-dom"

const truncate = (str) => {
  return str.length > 39 ? str.substring(0, 40) + "..." : str
}

const EditCustomerUsers = () => {
  const globalClasses = globalUseStyles()
  const routerHistory = useHistory()
  const [usersResult, getUsers] = useApiGet()
  const loadingContext = useContext(GlobalLoaderContext)

  const appContext = useContext(AppContext)
  const isLimitedManager = hasRole(appContext.appUser, allRoles.CustomerAdminLimitedManagerAccess)

  useEffect(() => {
    getUsers(apiEndpoints.customeradmin.getcustomerusers)
  }, [getUsers])

  useEffect(() => {
    loadingContext.setLoading(() => usersResult.status === 1)
  })

  const columns = useMemo(
    () => [
      {
        accessor: "name",
        numeric: false,
        Header: "Namn"
      },
      {
        accessor: "username",
        numeric: true,
        disablePadding: false,
        Header: "Användarnamn"
      },
      {
        accessor: "email",
        numeric: false,
        disablePadding: false,
        Header: "E-post"
      },
      {
        accessor: "companies",
        Header: "Bolag",
        Cell: ({ row }) => (
          <>
            <Tooltip title={row.original.companies.join(", ")}>
              <Typography variant="caption">{truncate(row.original.companies.join(", "))}</Typography>
            </Tooltip>
          </>
        )
      },
      {
        accessor: "departments",
        Header: "Avdelningar",
        Cell: ({ row }) => (
          <>
            <Tooltip title={row.original.departments.join(", ")}>
              <Typography variant="caption">{truncate(row.original.departments.join(", "))}</Typography>
            </Tooltip>
          </>
        )
      },
      {
        accessor: "manager.name",
        Header: "Närmsta chef"
      },
      {
        accessor: "disabled",
        Cell: ({ row }) => {
          var label = row.original.disabled ? (
            <FontAwesomeIcon color={theme.palette.error["main"]} icon={faUserSlash} />
          ) : (
            <FontAwesomeIcon color={theme.palette.primary["main"]} icon={faUser} />
          )
          return <>{label}</>
        },
        Header: "Status",
        FilterHeader: "Status",
        defaultValue: "False",
        mainFilter: true,
        Filter: SelectColumnFilter,
        FilterOptions: [
          {
            name: "Aktiva användare",
            value: "False"
          },
          {
            name: "Inaktiverad användare",
            value: "True"
          }
        ]
      }
    ],
    []
  )
  const initialFilters = [
    {
      id: "disabled",
      value: "False"
    }
  ]

  return (
    <div>
      <Container>
        <Box mb={2}>
          <Grid container direction="row" justifyContent="space-between" alignItems="flex-end">
            <Typography variant="h1">Användare</Typography>
            <Button color="primary" component={Link} to={allRoutes.CustomerSettingsUsersAdd()}>
              Lägg till användare
            </Button>
          </Grid>
        </Box>
      </Container>
      <Box className={globalClasses.tableContainer}>
        <ClpTable
          columns={columns}
          rowProps={(row) => ({
            onClick: () => routerHistory.push(allRoutes.CustomerSettingsUsersEdit(row.original.id))
          })}
          rows={usersResult.status === 2 && usersResult.data}
          status={usersResult.status}
          initialFilters={initialFilters}
        />

        {isLimitedManager && (
          <Box padding={2}>
            <Typography variant="bodySmall">
              Listan visar endast de användare som har dig som chef.
            </Typography>
          </Box>
        )}
      </Box>
    </div>
  )
}
export default EditCustomerUsers
