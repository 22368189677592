import { CloudUpload } from "@mui/icons-material"
import { Box } from "@mui/material"
import Button from "@mui/material/Button"
import Grid from "@mui/material/Grid"
import Popover from "@mui/material/Popover"

import { makeStyles } from "@mui/styles"
import { IApiFunc, IApiResult } from "auth/interface/api.interface"
import { useApi } from "auth/useApi"
import { EditorState, convertToRaw } from "draft-js"
import MUIRichTextEditor, { TAsyncAtomicBlockResponse, TMUIRichTextEditorRef } from "mui-rte"
import React, { FunctionComponent, useEffect, useRef, useState } from "react"
const useStyles = makeStyles((theme) => ({
  rte: {
    minHeight: "300px",
    width: "100%",
    borderRadius: "4px",
    border: "1px solid #d6d6d6",
    "& img": {
      maxWidth: "100%",
      height: "auto"
    },
    "& video": {
      maxWidth: "100%",
      height: "auto"
    },
    "& .MUIRichTextEditor-container-69": {
      margin: 0
    },
    "& #mui-rte-container > div:nth-child(2)": {
      padding: "0px 14px",
      minHeight: "190px"
    },

    "& .MuiSvgIcon-root": {
      fontSize: "1.25rem"
    }
  }
}))
interface IUploadImagePopoverProps {
  anchor: TAnchor
  onSubmit: (data: TUploadImageData, insert: boolean) => void
}

type TUploadImagePopoverState = {
  anchor: TAnchor
  isCancelled: boolean
}

type TUploadImageData = {
  file?: File
}

type TAnchor = HTMLElement | null

const cardPopverStyles = makeStyles({
  root: {
    padding: 10,
    maxWidth: 350
  },
  textField: {
    width: "100%"
  },
  input: {
    display: "none"
  }
})

const uploadImage = (fileUploadResult: IApiResult, fileType: string) => {
  return new Promise<TAsyncAtomicBlockResponse>(async (resolve, reject) => {
    await fileUploadResult
    resolve({
      data: {
        url: `${fileUploadResult.data?.blobUrl}/${fileUploadResult.data?.fileId}${fileUploadResult.data?.sasKey}`,
        width: "auto",
        height: "auto",
        alignment: "left", // or "center", "right"
        type: fileType // or "video"
      }
    })
  })
}

const UploadImagePopover: FunctionComponent<IUploadImagePopoverProps> = (props) => {
  const classes = cardPopverStyles(props)
  const [state, setState] = useState<TUploadImagePopoverState>({
    anchor: null,
    isCancelled: false
  })
  const [data, setData] = useState<TUploadImageData>({})

  useEffect(() => {
    setState({
      anchor: props.anchor,
      isCancelled: false
    })
    setData({
      file: undefined
    })
  }, [props.anchor])

  useEffect(() => {
    if (data.file !== undefined) {
      setState({
        anchor: null,
        isCancelled: false
      })
      props.onSubmit(data, !state.isCancelled)
    }
  }, [data])
  return (
    <Popover
      anchorEl={state.anchor}
      open={state.anchor !== null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right"
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left"
      }}>
      <Grid container spacing={1} className={classes.root} justifyContent="center">
        {/* <Grid item xs={12}>
          {data.file?.name}
        </Grid> */}

        <Grid item container xs={12} justifyContent="space-between">
          <Button
            onClick={() => {
              setState({
                anchor: null,
                isCancelled: true
              })
            }}>
            Avbryt
          </Button>
          <Box>
            <input
              accept="image/*,video/*"
              className={classes.input}
              id="contained-button-file"
              type="file"
              onChange={(event) => {
                setData({
                  ...data,
                  file: event.target.files![0]
                })
              }}
            />
            <label htmlFor="contained-button-file">
              <Button aria-label="upload image" variant="contained" component="span">
                Välj media fil
              </Button>
            </label>
          </Box>
        </Grid>
      </Grid>
    </Popover>
  )
}

interface ITextEditor {
  setData: (value: any) => void
  value: string
  uploadApi: string
}

const TextEditor = (props: ITextEditor) => {
  const { setData, value, uploadApi } = props
  const [fileUploadResult, uploadFile] = useApi() as [IApiResult, IApiFunc]
  const ref = useRef<TMUIRichTextEditorRef>(null)
  const [anchor, setAnchor] = useState<HTMLElement | null>(null)
  const classes = useStyles()
  const handleFileUpload = (file: File) => {
    uploadFile.file(uploadApi, file)
  }
  useEffect(() => {
    if (fileUploadResult.status === 2) {
      const split = fileUploadResult.data.name.split(".")
      const fileExtension = split[split.length - 1]
      const isVideoFormat = ["mp4", "webm", "mov", "avi", "wmv"].includes(fileExtension)
      const fileType = isVideoFormat ? "video" : "image"
      ref.current?.insertAtomicBlockAsync("IMAGE", uploadImage(fileUploadResult, fileType), "Laddar upp")
    }

    return () => {}
  }, [fileUploadResult])

  const change = (state: EditorState) => {
    if (state.getCurrentContent().hasText()) {
      setData(JSON.stringify(convertToRaw(state.getCurrentContent())))
    }
  }

  return (
    <>
      <UploadImagePopover
        anchor={anchor}
        onSubmit={(data, insert) => {
          if (insert && data.file) {
            handleFileUpload(data.file)
          }
          setAnchor(null)
        }}
      />
      <Box className={classes.rte}>
        <MUIRichTextEditor
          ref={ref}
          controls={[
            "title",
            "bold",
            "italic",
            "underline",
            "strikethrough",
            "highlight",
            "undo",
            "redo",
            "link",
            "numberList",
            "bulletList",
            "quote",
            "code",
            "media",
            "upload-image"
          ]}
          customControls={[
            {
              name: "upload-image",
              icon: <CloudUpload />,
              type: "callback",
              onClick: (_editorState, _name, anchor) => {
                setAnchor(anchor)
              }
            }
          ]}
          draftEditorProps={{
            handleDroppedFiles: (_selectionState, files) => {
              if (files.length && (files[0] as File).name !== undefined) {
                handleFileUpload(files[0] as File)
                return "handled"
              }
              return "not-handled"
            }
          }}
          defaultValue={value}
          onChange={(e: any) => change(e)}
        />
      </Box>
    </>
  )
}

export default TextEditor
