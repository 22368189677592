import { styled } from "@mui/material/styles"
import { Box, Tab } from "@mui/material"

export const StyledTabVertical = styled(Tab)<{ tabValue: number }>(({ theme, tabIndex, tabValue }) => ({
  alignItems: "flex-start",
  minHeight: "30px",
  backgroundColor: tabValue === tabIndex ? theme.palette.grey[100] : "inherit",
  "& > .MuiBox-root": {
    padding: "0",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    "& > .MuiBox-root": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor:  theme.palette.error.main,
      borderRadius: "50%",
      color: "white",
    }
  }
}))

export const StyledGridBorderGrey = styled(Box)(({ theme }) => ({
  height: "100%",
  borderRight: "2px solid " + theme.palette.grey[100]
}))