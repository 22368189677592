import { faFileArrowUp, faPenField, faXmark } from "@fortawesome/pro-solid-svg-icons"
import {
  Box,
  Button,
  DialogContent,
  Divider,
  InputLabel,
  List,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@mui/material"
import ListItem from "@mui/material/ListItem"
import { DateTimePicker } from "@mui/x-date-pickers"
import { IApiFunc, IApiResult } from "auth/interface/api.interface"
import { Formik } from "formik"
import { t } from "i18next"
import moment from "moment/moment"
import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from "react"
import * as Yup from "yup"
import { hasRole } from "auth/hasRole"
import { useApi } from "auth/useApi3"
import { AppContext } from "components/AppContextProvider"
import { RouterPrompt } from "components/RouterPrompt"
import { CircleIcon } from "components/icons/CircleIcon"
import CloseSidebarButton from "components/navigation/CloseSidebarButton"
import { apiEndpoints } from "generated/apiEndpoints"
import { allRoles } from "generated/roles"
import { WorkshopData } from "pages/customer/pages/settings/pages/companies/components/Workshop/Workshop.interfaces"
import { Sidebar } from "pages/vehicleregistry/pages/vehicles/pages/customfields/components/Sidebar"
import { StyledListItemText } from "../InspectionService.styled"
import { ActionType, BookingData, ModalState } from "./InspectionBookingSidebar.interfaces"
import { initialModalState, ModalDialog } from "./ModalDialog"
import { styled } from "@mui/material/styles"
import Grid from "@mui/material/Grid"
import {
  StyledBox,
  StyledBoxClose,
  StyledFontAwesomeIcon,
  StyledTypographyModal
} from "pages/vehicleregistry/pages/checks/InspectionsHistoryTable.styled"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"
import Dialog from "@mui/material/Dialog"
import { allRoutes } from "generated/routes"
import { useHistory } from "react-router-dom"

const inspectionBookingValidationSchema = Yup.object().shape({
  workshopId: Yup.number()
    .required("Verkstad är obligatoriskt")
    .integer("Välj verkstad")
    .positive("Välj verkstad"),
  dateTime: Yup.date().required("Datum är obligatoriskt"),
  inspectionType: Yup.string().required("Besiktningstyp är obligatoriskt")
})

const initialFormValues = {
  id: 0,
  workshopId: 0,
  dateTime: "",
  inspectionType: "Kontrollbesiktning"
}

const StyledTextFieldHidden = styled(TextField)({
  display: "none"
})

interface NoWorkshopReminderProps {
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
  clickedInspection: any
}

export const NoWorkshopReminder = ({ isOpen, setIsOpen, clickedInspection }: NoWorkshopReminderProps) => {
  const history = useHistory()

  const handleClose = () => {
    setIsOpen(false)
  }

  const redirect = (query: string) => {
    handleClose()
    history.push(allRoutes.CustomerSettingsCompaniesEdit(clickedInspection?.companyId) + query)
  }
  
  return (
    <Dialog
      open={isOpen}
      onClose={(event, reason) => {
        if (reason && reason === "backdropClick") {
          return
        }
        handleClose()
      }}
      maxWidth="xs"
    >
      <Grid container direction="column" alignItems="center">
        <DialogContent>
          <Grid container flexDirection={"row"}>
            <Box sx={{marginTop: "8px"}}>
              <Typography variant="h3">Inga verkstäder hittades</Typography>
            </Box>
            <StyledBoxClose onClick={() => { handleClose() }}>
              <FontAwesomeIcon size="xl" color="grey" icon={faXmark} />
            </StyledBoxClose>
          </Grid>
          <Grid container flexDirection={"column"} mb={5}>
            <StyledTypographyModal align="left" variant="paragraphMedium" color="textSecondary">
              Det finns inga verkstäder att boka besiktningen på. Du kan skapa eller aktivera verkstäder via länken nedan.
              {clickedInspection?.inspectionBooking && (
                <Typography mt={2}>
                  Denna besiktning är bokad på verkstad: 
                  <Typography color="secondary" fontWeight={700}>
                    {clickedInspection.inspectionBooking.carWorkshop.name}
                  </Typography>
                </Typography>
              )}
            </StyledTypographyModal>
          </Grid>
          <Divider sx={{ width: "calc(100% + ) + 48px", marginLeft: "-24px", marginRight: "-24px" }}/>
          <Box mt={3}>
            <Box
              style={{ textDecoration: "none", width: "100%", cursor: "pointer" }}
              onClick={() => { redirect("?tab=workshops") }}
            >
              <StyledBox display="flex" justifyContent="space-between" gap={3} alignItems={"center"}>
                <Grid container gap={2} ml={0.3}>
                  <FontAwesomeIcon size="xl" color="grey" icon={faFileArrowUp} />
                  <Typography variant="h5" color="primary">Gå till verkstäder</Typography>
                </Grid>
                <StyledFontAwesomeIcon size="xl" icon={faArrowRight} />
              </StyledBox>
            </Box>
          </Box>
        </DialogContent>
      </Grid>
    </Dialog>
  )
}

export const InspectionBookingSidebar = ({
  loadInspections,
  clickedInspection,
  showSidebar,
  setShowSidebar
}: any) => {
  const appContext = useContext(AppContext)
  const [workshopResult, workshopApi] = useApi() as [IApiResult, IApiFunc]
  const [inspectionBookingResult, inspectionBookingApi] = useApi() as [IApiResult, IApiFunc]
  const [formikValues, setFormikValues] = useState(initialFormValues)
  const [modalData, setModalData] = useState<ModalState>(initialModalState)
  const [showNoWorkshopReminder, setShowNoWorkshopReminder] = useState(false)

  useEffect(() => {
    if (showSidebar) {
      const url = `${apiEndpoints.vehicleregistrychecks.getactivecompanycarworkshops}?companyId=${clickedInspection?.companyId}`
      workshopApi.get(url)
    } else {
      setFormikValues(initialFormValues)
    }
  }, [showSidebar])

  useEffect(() => {
    if (!clickedInspection?.inspectionBooking) return
    setFormikValues({
      id: clickedInspection.inspectionBooking.id,
      dateTime: clickedInspection.inspectionBooking.dateTime,
      workshopId: clickedInspection.inspectionBooking.carWorkshopId,
      inspectionType: clickedInspection.inspectionBooking.inspectionType
    })
  }, [clickedInspection])

  useEffect(() => {
    if (workshopResult.status !== 2) return
    if (workshopResult.data.length === 0) {
      setFormikValues(prevState => ({ ...prevState, workshopId: 0 }))
      setShowNoWorkshopReminder(true)
    } else if (clickedInspection?.inspectionBooking === null || clickedInspection?.inspectionBooking === undefined) {
      setFormikValues(prevState => ({ ...prevState, workshopId: workshopResult.data[0].id }))
    }
  }, [workshopResult])

  const handleFormikSubmit = (formValues: any) => {
    const urlPrefix = apiEndpoints.vehicleregistrychecks
    const bookingData: BookingData = {
      customFieldVehicleValueId: clickedInspection.customFieldVehicleValueId,
      carWorkshopId: formValues.workshopId,
      dateTime: moment(formValues.dateTime).utc(),
      inspectionType: "Kontrollbesiktning"
    }
    if (clickedInspection.inspectionBooking) {
      bookingData.id = clickedInspection.inspectionBooking.id
      inspectionBookingApi.put(urlPrefix.updateinspectionbooking, bookingData)
    } else {
      inspectionBookingApi.post(urlPrefix.createinspectionbooking, bookingData)
    }
  }

  useEffect(() => {
    if (inspectionBookingResult.status !== 2) return
    loadInspections()
    setShowSidebar(false)
  }, [inspectionBookingResult])

  return (
    <>
      {hasRole(appContext.appUser, allRoles.VehicleRegistryAdmin) && (
        <Sidebar showSidebar={showSidebar}>
          <Box sx={{ height: "100%", margin: "20px" }}>
            <Formik
              initialValues={formikValues}
              validationSchema={inspectionBookingValidationSchema}
              onSubmit={(values) => {
                handleFormikSubmit(values)
              }}
              enableReinitialize={true}>
              {({ values, errors, touched, handleChange, handleSubmit, setFieldValue }) => (
                <form style={{ height: "100%" }} id="CustomFieldForm" onSubmit={handleSubmit} noValidate>
                  <NoWorkshopReminder
                    isOpen={showNoWorkshopReminder}
                    setIsOpen={setShowNoWorkshopReminder}
                    clickedInspection={clickedInspection}
                  />
                  <ModalDialog
                    modalData={modalData}
                    setModalData={setModalData}
                    setShowSidebar={setShowSidebar}
                    handleSubmit={() => {
                      handleFormikSubmit(values)
                    }}
                  />
                  <RouterPrompt />
                  <List sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
                    <Box style={{ position: "absolute", left: "-20px" }}>
                      <CloseSidebarButton
                        toggleMenu={() => {
                          setShowSidebar(!showSidebar)
                        }}
                      />
                    </Box>
                    <ListItem sx={{ borderBottom: "1px solid #e0e0e0" }}>
                      <Box pt={5} pb={3} display="flex" alignItems="center" justifyContent="space-between">
                        <Box display="flex" alignItems="center">
                          <CircleIcon
                            icon={faPenField}
                            color="primary"
                            size="lg"
                            sx={{ marginRight: "15px" }}
                          />
                          <Box justifyContent="center">
                            <ListItemText
                              primary={
                                <Typography color="textPrimary" variant="h2">
                                  {values.id < 1 ? "Boka besiktning lokalt" : "Redigera besiktning"}
                                </Typography>
                              }
                              secondary={
                                <Typography color="textSecondary" variant="bodyMedium">
                                  {clickedInspection?.regNumber} / {clickedInspection?.internNumber}
                                </Typography>
                              }
                            />
                          </Box>
                        </Box>
                      </Box>
                    </ListItem>
                    <ListItem>
                      <StyledListItemText
                        primary={
                          <InputLabel>
                            <Typography variant="h6" color={"black"}>
                              Verkstad
                            </Typography>
                          </InputLabel>
                        }
                        secondary={
                          <>
                            <Select
                              fullWidth
                              name="workshopId"
                              value={values.workshopId}
                              onChange={handleChange}
                              disabled={workshopResult.status === 2 && workshopResult.data.length === 0}
                              error={touched.workshopId && errors.workshopId ? true : false}
                            >
                              {workshopResult.data &&
                                workshopResult.data
                                  .map((workshop: WorkshopData) => (
                                      <MenuItem key={workshop.name} value={workshop.id}>
                                        {workshop.name}
                                      </MenuItem>
                                    )
                                  )}
                            </Select>
                            {touched.workshopId && errors.workshopId && (
                              <div style={{ color: "red" }}>
                                {errors.workshopId}
                              </div>
                            )}
                          </>
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <StyledListItemText
                        primary={
                          <InputLabel>
                            <Typography variant="h6" color={"black"}>
                              Datum & tid
                            </Typography>
                          </InputLabel>
                        }
                        secondary={
                          <DateTimePicker
                            value={values.dateTime ? moment(values.dateTime) : null}
                            format={"YYYY-MM-DD HH:mm"}
                            onChange={(value) => {
                              setFieldValue(
                                "dateTime",
                                moment(value).isValid() ? moment(value).format("YYYY-MM-DD HH:mm") : null
                              )
                            }}
                            slotProps={{
                              textField:
                                {
                                  variant: "outlined",
                                  helperText: touched.dateTime && errors.dateTime ? errors.dateTime : null,
                                  error: touched.dateTime && errors.dateTime ? true : false,
                                  sx: { width: "100%" }
                                }
                            }}
                          />
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <StyledTextFieldHidden name="inspectionType" defaultValue={initialFormValues.inspectionType} />
                    </ListItem>
                    <Box sx={{ marginTop: "Auto", marginBottom: "26px", width: "100%" }}>
                      <ListItem sx={{ flexDirection: "column", gap: "14px" }}>
                        <Button
                          sx={{ width: "100%", margin: "0 10px" }}
                          type="button"
                          variant="contained"
                          onClick={() => {
                            setModalData(prevValue => ({
                              actionType: ActionType.Confirm,
                              isOpen: true,
                              title: "Spara ändringar",
                              message: "Är du säker på att du vill spara dina ändringar?"
                            }))
                          }}
                        >
                          {clickedInspection?.inspectionBooking !== null ? t("global.buttons.save") : "Boka"}
                        </Button>
                        <Button
                          sx={{ width: "100%", margin: "0 10px" }}
                          type="button"
                          variant={"outlined"}
                          onClick={() => {
                            setModalData(prevValue => ({
                              actionType: ActionType.Cancel,
                              isOpen: true,
                              title: "Avbryt ändringar",
                              message: "Dina ändringar kommer inte att sparas. Är du säker på att du vill avbryta?"
                            }))
                          }}>
                          {t("global.buttons.cancel")}
                        </Button>
                      </ListItem>
                    </Box>
                  </List>
                </form>
              )}
            </Formik>
          </Box>
        </Sidebar>
      )}
    </>
  )
}
