import { Box, Button, ButtonGroup, Container, Grid } from "@mui/material"
import { useApi } from "auth/useApi3"
import ClpTable from "components/ClpTable"
import { GlobalLoaderContext } from "components/GlobalLoadingProvider"
import globalUseStyles from "css/theme/globalUseStyles.js"
import { apiEndpoints } from "generated/apiEndpoints"
import { useContext, useEffect, useMemo, useState } from "react"
import CustomFieldForm from "./CustomFieldForm"

const CustomFields = () => {
  const loadingContext = useContext(GlobalLoaderContext)
  const [listResult, listApi] = useApi()
  const [addResult, addApi] = useApi()
  const [updateResult, updateApi] = useApi()
  const [editItemResult, editItemApi] = useApi()
  const [editMode, setEditMode] = useState(false)
  const [showSidebarForm, setShowSidebarForm] = useState(false)
  const globalClasses = globalUseStyles()

  useEffect(() => {
    loadingContext.setLoading(() => listResult.status === 1)
  })

  useEffect(() => {
    if (updateResult.status === 2 || addResult.status === 2) {
      listApi.get(apiEndpoints.vehicleregistryadmin.listcustomfields)
      setShowSidebarForm(false)
    }
  }, [updateResult, addResult, listApi])

  useEffect(() => {
    listApi.get(apiEndpoints.vehicleregistryadmin.listcustomfields)

    return () => {
      listApi.leave()
    }
  }, [listApi])

  const onAddSubmit = (values) => {
    addApi.post(apiEndpoints.vehicleregistryadmin.addcustomfield, values)
  }

  const onUpdateSubmit = (values) => {
    updateApi.put(apiEndpoints.vehicleregistryadmin.updatecustomfield, values)
  }

  const openEdit = (id) => {
    setEditMode(true)
    setShowSidebarForm(true)
    editItemApi.get(apiEndpoints.vehicleregistryadmin.getcustomfieldbyid.replace("{id}", id))
    updateApi.reset()
    addApi.reset()
  }

  const openAdd = () => {
    setEditMode(false)
    setShowSidebarForm(true)
    editItemApi.reset()
    updateApi.reset()
    addApi.reset()
  }

  const columns = useMemo(
    () => [
      {
        accessor: "name",
        Header: "Namn"
      },
      {
        accessor: "type",
        Header: "Typ",
        Cell: (cell) => {
          switch (cell.row.original.type) {
            case "Numeric":
              return "Nummer"
            case "Date":
              return "Datum"
            case "Boolean":
              return "Ja/Nej"
            case "Text":
              return "Textfält"
            case "RepeatedNotification":
              return "Repeterande Påminnelse"
            case "Notification":
              return "Påminnelse"
            default:
              return ""
          }
        }
      },
      {
        accessor: "category",
        Header: "Kategori",
        Cell: (cell) => cell.row.original.category
      },
      {
        accessor: "isRequired",
        Header: "Obligatorisk",
        Cell: (cell) => (cell.row.original.isRequired ? "Ja" : "Nej")
      },
      {
        accessor: "active",
        Header: "Aktiv",
        Cell: (cell) => (cell.row.original.active ? "Ja" : "Nej")
      }
    ],
    []
  )

  return (
    <div>
      <Container>
        <CustomFieldForm
          showSidebar={showSidebarForm}
          setShowSidebarForm={setShowSidebarForm}
          editItemResult={editItemResult}
          addResult={addResult}
          updateResult={updateResult}
          editMode={editMode}
          onAddSubmit={onAddSubmit}
          onUpdateSubmit={onUpdateSubmit}
        />
        <Box mb={4}>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <ButtonGroup color="primary">
              <Button
                color="primary"
                onClick={() => {
                  openAdd()
                }}
                variant="contained">
                Lägg till fält
              </Button>
            </ButtonGroup>
          </Grid>
        </Box>
      </Container>
      <Box className={globalClasses.tableContainer}>
        <ClpTable
          columns={columns}
          cellProps={(row) => ({
            onClick: () => {
              openEdit(row.original.id)
            }
          })}
          rows={listResult.status === 2 && listResult.data}
          status={listResult.status}
        />
      </Box>
    </div>
  )
}

export default CustomFields