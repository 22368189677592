import { faCheck, faCircleNotch } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button } from "@mui/material"
import ApiResult from "components/ApiResultFeedback"
import { useEffect, useState } from "react"

function DefaultButton(props) {
  const { children, result, feedback } = props
  const [loadingResult, setLoadingResult] = useState(null)
  useEffect(() => {
    setLoadingResult(result)
    if (result && result.status === 2) {
      const clearResult = setTimeout(() => {
        setLoadingResult(null)
      }, 1000)
      return () => clearTimeout(clearResult)
    }
  }, [result])

  if (loadingResult && loadingResult.status === 1) {
    //LOADING
    return <LoadingButton {...props} />
  } else if (loadingResult && loadingResult.status === 2) {
    //SUCCESS
    return (
      <Button startIcon={<FontAwesomeIcon icon={faCheck} />} variant="contained" {...props}>
        Sparat
      </Button>
    )
  } else if (loadingResult && loadingResult.status === 3) {
    //ERROR
    return (
      <div>
        <ApiResult result={loadingResult} feedback={feedback} />
        <Button variant="contained" {...props}>
          {children}
        </Button>
      </div>
    )
  } else {
    return <StandardButton {...props} />
  }
}
export const StandardButton = (props) => {
  const { children } = props

  return (
    <Button variant="contained" {...props}>
      {children}
    </Button>
  )
}
export const LoadingButton = (props) => {
  return (
    <Button
      startIcon={<FontAwesomeIcon spin icon={faCircleNotch} />}
      variant="contained"
      disabled={true}
      {...props}>
      Laddar
    </Button>
  )
}
export default DefaultButton
