import { Container, Grid, Typography } from "@mui/material"
import { useApi } from "auth/useApi3"
import { GlobalLoaderContext } from "components/GlobalLoadingProvider"
import PaperBox from "components/PaperBox"
import SubTabs from "components/SubTabs"
import { TabPanel } from "components/navigation/TabPanel"
import { apiEndpoints } from "generated/apiEndpoints"
import { useContext, useEffect, useState } from "react"
import CustomRoleList from "shared/customer/CustomRoles/CustomRoleList"
import { CustomerForm } from "../../../../components/CustomerForm"
import { EditCustomerNavigation } from "../../components/EditCustomerNavigation"

const tabData = [
  { index: 0, label: "Kunduppgifter", queryParam: "customerSettings" },
  { index: 1, label: "Roller", queryParam: "roles" }
]
const EditCustomer = ({ match }) => {
  const [customerResult, customerFuncs] = useApi()
  const [saveResult, saveFuncs] = useApi()
  const [tabValue, setTabValue] = useState(0)
  const [logoResult, logoFuncs] = useApi()
  const loadingContext = useContext(GlobalLoaderContext)

  useEffect(() => {
    const getData = async () => {
      await customerFuncs.get(
        apiEndpoints.systemadmincustomers.getcustomerbyid.replace("{id}", match.params.id)
      )
    }
    getData()
  }, [customerFuncs, match.params.id])

  useEffect(() => {
    if (customerResult.data && customerResult.data.logoName) {
      logoFuncs.get(
        apiEndpoints.systemadmincustomers.getcustomerlogobyname.concat(
          "?name=" + encodeURIComponent(customerResult.data.logoName)
        )
      )
    }
  }, [customerResult, logoFuncs])

  useEffect(() => {
    loadingContext.setLoading(() => customerResult.status === 1)
  })

  useEffect(() => {
    loadingContext.setLoading(() => logoResult.status === 1)
  })

  const feedback = {
    errorTitle: "Kunde inte spara kund"
  }

  const handleSubmit = async (values) => {
    saveFuncs.put(apiEndpoints.systemadmincustomers.updatecustomer, {
      ...values
    })
  }

  return (
    <div>
      <EditCustomerNavigation customerId={match.params.id} />
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h1" gutterBottom>
              Kunduppgifter
            </Typography>
          </Grid>
        </Grid>
        <SubTabs tabs={tabData} value={tabValue} setValue={setTabValue} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TabPanel value={tabValue} index={0}>
              <PaperBox>
                {customerResult.status === 2 && (logoResult.status === 2 || !customerResult.data.logoName) ? (
                  <CustomerForm
                    editCustomer={customerResult.data}
                    saveResult={saveResult}
                    feedback={feedback}
                    logoResult={logoResult}
                    handleSubmit={handleSubmit}
                  />
                ) : null}
              </PaperBox>
            </TabPanel>
          </Grid>
          <Grid item xs={12} md={8}>
            <TabPanel value={tabValue} index={1}>
              {customerResult.status === 2 && (
                <PaperBox>
                  <CustomRoleList isSystemAdmin={true} customerId={customerResult?.data?.id} />
                </PaperBox>
              )}
            </TabPanel>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}
export default EditCustomer
