import { Box, Grid, Typography } from "@mui/material"
import { Container } from "@mui/system"
import { useApiGet } from "auth/useApi2"
import { useApi } from "auth/useApi3"
import { AppContext } from "components/AppContextProvider"
import { TodoListWrapper } from "components/dataDisplay/TodoList/TodoListWrapper"
import ContinueDialog from "components/Dialogs/ContinueDialog"
import { GlobalLoaderContext } from "components/GlobalLoadingProvider"
import PaperBox from "components/PaperBox"
import { Formik } from "formik"
import { apiEndpoints } from "generated/apiEndpoints"
import { Fragment, useContext, useEffect, useState } from "react"
import * as Yup from "yup"
import ExternalDetailsCard from "./components/ExternalDetailsCard"
import ExternalHeader from "./components/ExternalHeader"
import ExternalIssueReports from "./components/ExternalIssueReports"
import ExternalReadMessageCard from "./components/ExternalReadMessageCard"
import ExternalSendButton from "./components/ExternalSendButton"
import ExternalStatusBox from "./components/ExternalStatusBox"
import { useStyles } from "./components/useStyles"

const ExternalValidationSchema = Yup.object().shape({
  plannedDate: Yup.date().required("Datum är obligatoriskt").nullable()
})

const Workorder = ({ match }) => {
  const classes = useStyles()
  const appContext = useContext(AppContext)
  const [workorderResult, workorderApi] = useApi()
  const [logoResult, getLogoData] = useApiGet()
  const loadingContext = useContext(GlobalLoaderContext)
  const [sendBookingResult, sendBookingApi] = useApi()
  const [sendReportResult, sendReportApi] = useApi()
  const [cancelReportResult, cancelReportApi] = useApi()
  const [showUpdateModal, setShowUpdateModal] = useState(false)
  const [showSendReportModal, setShowSendReportModal] = useState(false)
  // const [issueReportStatus, setIssueReportStatus] = useState()

  // fetch data and filter
  useEffect(() => {
    workorderApi.get(apiEndpoints.issuereportexternal.getworkorderbyguid.replace("{guid}", match.params.guid))
  }, [workorderApi, match])

  // update workorder
  const updateStatus = (values) => {
    if (values.status === 2) {
      sendBookingApi.put(apiEndpoints.issuereportexternal.sendworkorderbooking, values)
    }
    if (values.status === 3) {
      cancelReportApi.put(apiEndpoints.issuereportexternal.declineworkorder, values)
    }
  }

  // send report
  const sendReport = (values) => {
    setShowSendReportModal(false)
    sendReportApi.put(apiEndpoints.issuereportexternal.sendworkorderresolved, values)
  }

  // handle submit formik form & show continue modal
  const handleSubmit = () => {
    setShowUpdateModal(true)
  }

  // handle submit formik form & show continue modal
  const handleSendReport = () => {
    setShowSendReportModal(true)
  }

  // fetch data and filter
  useEffect(() => {
    if (cancelReportResult.status === 2) {
      workorderApi.get(
        apiEndpoints.issuereportexternal.getworkorderbyguid.replace("{guid}", match.params.guid)
      )
      setShowUpdateModal(false)
      return () => {
        workorderApi.leave()
      }
    }
  }, [workorderApi, match, cancelReportResult])

  // fetch data and filter
  useEffect(() => {
    if (sendReportResult.status === 2) {
      workorderApi.get(
        apiEndpoints.issuereportexternal.getworkorderbyguid.replace("{guid}", match.params.guid)
      )
      setShowUpdateModal(false)
      return () => {
        workorderApi.leave()
      }
    }
  }, [workorderApi, match, sendReportResult])

  // fetch data and filter
  useEffect(() => {
    if (sendBookingResult.status === 2) {
      workorderApi.get(
        apiEndpoints.issuereportexternal.getworkorderbyguid.replace("{guid}", match.params.guid)
      )
      setShowUpdateModal(false)
      return () => {
        workorderApi.leave()
      }
    }
  }, [workorderApi, match, sendBookingResult])

  useEffect(() => {
    loadingContext.setLoading(() => workorderResult.status === 1)
  })

  useEffect(() => {
    if (workorderResult.status === 2 && workorderResult.data.customerLogo) {
      getLogoData(
        apiEndpoints.issuereportexternal.getcustomerlogobyname.concat(
          "?name=" + workorderResult.data.customerLogo
        )
      )
    }
  }, [workorderResult, getLogoData])

  const [initialValues, setInitialValues] = useState({})

  useEffect(() => {
    if (workorderResult.status === 2) {
      setInitialValues(workorderResult.data)
    }
    console.log(initialValues)
  }, [workorderResult])

  return (
    <Container>
      {workorderResult && workorderResult.status === 2 && (
        <Fragment>
          <ExternalHeader workorderResult={workorderResult} logoResult={logoResult} />
          <Formik
            validationSchema={ExternalValidationSchema}
            initialValues={initialValues}
            onSubmit={handleSubmit}
            enableReinitialize={true}>
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              isSubmitting,
              handleSubmit,
              setFieldValue
            }) => (
              <form id="WorkOrderForm" onSubmit={handleSubmit} noValidate>
                <Grid mt={2} pb={3} container spacing={2} justifyContent="space-between">
                  <Grid item xs={12} md={8}>
                    <PaperBox m={0} p={5}>
                      <ExternalReadMessageCard
                        bgColor="#F9FAFB"
                        title="Meddelande"
                        message={values.message}
                      />
                      <ExternalDetailsCard
                        title="Fordonsdata"
                        values={workorderResult.data}
                        bgColor="#F9FAFB"
                      />

                      {values.status === 2 && (
                        <Box mt={5}>
                          <Typography fontWeight={500} mb={1} color="primary" variant="h5">
                            Lista över felrapporter
                          </Typography>
                          <PaperBox sx={{ p: 0 }} className={classes.boxShadow}>
                            <TodoListWrapper>
                              {values.issueReports.map((issueReport, index) => (
                                <ExternalIssueReports
                                  handleChange={handleChange}
                                  setFieldValue={setFieldValue}
                                  values={values}
                                  issueReport={issueReport}
                                  index={index}
                                />
                              ))}
                            </TodoListWrapper>
                          </PaperBox>
                        </Box>
                      )}
                    </PaperBox>

                    {values.status > 2 && (
                      <Box mt={5}>
                        <Typography fontWeight={400} variant="h1">
                          Arbetsrapport
                        </Typography>
                        <PaperBox sx={{ p: values.status > 2 ? 4 : 0 }} className={classes.boxShadow}>
                          <Box>
                            {values.status > 2 && (
                              <Typography fontWeight={500} mb={1} color="primary" variant="h5">
                                Lista över felrapporter
                              </Typography>
                            )}
                            {values.status === 3 && (
                              <Typography mb={2} variant="bodyMedium">
                                Markera dom felrapporter som är åtgärdade.
                              </Typography>
                            )}
                          </Box>
                          <TodoListWrapper>
                            {values.issueReports.map((issueReport, index) => (
                              <ExternalIssueReports
                                setFieldValue={setFieldValue}
                                values={values}
                                issueReport={issueReport}
                                index={index}
                              />
                            ))}
                          </TodoListWrapper>
                          {values.status === 3 && (
                            <ExternalSendButton
                              onAddSubmit={handleSendReport}
                              sendReport={sendReport}
                              buttonFeedbackResult={sendReportResult}
                            />
                          )}
                        </PaperBox>
                      </Box>
                    )}
                  </Grid>
                  <ExternalStatusBox
                    status={values?.status ? values?.status : 2}
                    values={values}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    touched={touched}
                    buttonFeedbackResult={values.status < 3 ? sendBookingResult : cancelReportResult}
                    errors={errors}
                    setFieldValue={setFieldValue}
                  />
                </Grid>

                {showUpdateModal && (
                  <ContinueDialog
                    result={values.status < 3 ? sendBookingResult : cancelReportResult}
                    onAddSubmit={updateStatus}
                    values={values}
                    setShowContinueModal={setShowUpdateModal}
                    cancelBtnText="Avbryt"
                    continueBtnText={values.status < 3 ? "Skicka" : "Avboka"}
                    message={
                      values.status < 3
                        ? "Vill du skicka in datumbokning för arbetsorder?"
                        : "Vill du avboka arbetsorder?"
                    }
                  />
                )}

                {showSendReportModal && (
                  <ContinueDialog
                    result={sendReportResult}
                    onAddSubmit={sendReport}
                    values={values}
                    setShowContinueModal={setShowSendReportModal}
                    cancelBtnText="Avbryt"
                    continueBtnText="Skicka"
                    message="Vill du skicka in arbetsorder?"
                  />
                )}
              </form>
            )}
          </Formik>
        </Fragment>
      )}
    </Container>
  )
}

export default Workorder
