import React, { useCallback, useState } from "react"

import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { faDownload, faMagnifyingGlass, faTrashXmark, faFile } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, Button, Grid, IconButton, Stack, Typography } from "@mui/material"
import theme from "css/theme/light"
import FormUploadPreviewModal from "./FormUploadPreviewModal"
import { IFormUploadAttachmentProps, IFormUploadPreviewListProps } from "./formuploadfile.interface"
import { useApi } from "auth/useApi"
import { IApiFunc, IApiResult } from "auth/interface/api.interface"
import { apiEndpoints } from "generated/apiEndpoints"

const FormUploadPreviewList = ({ files, handleRemove, feature }: IFormUploadPreviewListProps) => {
  const [open, setOpen] = useState(false)
  const [selectedImage, setSelectedImage] = useState<IFormUploadAttachmentProps | null>(null)
  const [_, downloadApi] = useApi() as [IApiResult, IApiFunc]

  const downloadAttachment = useCallback((fileId, fileName) => {
    const downloadUrl = apiEndpoints.attachment.downloadattachment
    const fileIdEncoded = fileId.replace("/", "%2F")
    downloadApi.getBlob(downloadUrl.replace("{fileId}", fileIdEncoded), fileName)
  }, [downloadApi])

  const isImageFile = (fileId: string): boolean => {
    const imageExtensions = ['png', 'jpg', 'jpeg', 'gif', 'bmp', 'svg', 'webp'];
    const fileExtension = fileId.split('.').pop()?.toLowerCase();
    return imageExtensions.includes(fileExtension || '');
  }

  return files ? (
    <>
      {files.length > 0 &&
        files?.map((attachment: IFormUploadAttachmentProps, index: number) => (
          <>
            <Box
              gap={2}
              mb={1}
              px={2}
              py={1}
              borderColor="#EEEEEE"
              borderRadius={3}
              sx={{
                backgroundColor: theme.palette.primary.light
              }}>
              <Grid
                container
                direction="row"
                alignItems="center"
                spacing={0}
                justifyContent="space-between"
                style={{ color: theme.palette.primary["dark"], textDecoration: "inherit" }}>
                <Grid item xs={8} md={attachment?.Data ? 8 : 10}>
                  <Stack direction="row" alignItems="start" spacing={1}>
                    {isImageFile(attachment.fileId) && (
                      <FontAwesomeIcon
                        color={theme.palette.primary["dark"]}
                        icon={faFile as IconProp}
                        style={{ paddingTop: 3 }}
                      />
                    )}
                    <Typography sx={{ wordBreak: "break-word" }} textAlign="start" display="inline-block" variant="bodyMedium" fontWeight="600">
                      {attachment?.fileName}{attachment?.name}
                    </Typography>
                  </Stack>
                </Grid>

                <Grid item xs={4} sm={1}>
                  <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={1}>
                    {attachment.Data && (
                      <Button
                        id={`lock-button`}
                        onClick={(e) => {
                          setOpen(true)
                          setSelectedImage(attachment)
                        }}
                        sx={{ color: theme.palette.primary["dark"] }}>
                        <FontAwesomeIcon
                          color={theme.palette.primary["dark"]}
                          icon={faMagnifyingGlass as IconProp}
                          style={{ marginRight: 2 }}
                        />
                        Öppna
                      </Button>
                    )}
                    {feature === "usercustomfield" && (
                      <IconButton
                        edge="end"
                        id={`lock-button`}
                        aria-haspopup={`listbox`}
                        aria-controls={`lock-menu`}
                        aria-label="when device is locked"
                        color="primary"
                        onClick={() => downloadAttachment(attachment.fileId, attachment.fileName)}>
                        <FontAwesomeIcon
                          color={theme.palette.primary["dark"]}
                          icon={faDownload as IconProp}
                          size="xs"
                        />
                      </IconButton>
                    )}
                    <IconButton
                      edge="end"
                      id={`lock-button`}
                      aria-haspopup={`listbox`}
                      aria-controls={`lock-menu`}
                      aria-label="when device is locked"
                      color="error"
                      onClick={() => handleRemove && handleRemove(attachment, index)}>
                      <FontAwesomeIcon
                        color={theme.palette.error["main"]}
                        icon={faTrashXmark as IconProp}
                        size="xs"
                      />
                    </IconButton>
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          </>
        ))}
      {selectedImage && <FormUploadPreviewModal attachment={selectedImage} open={open} />}
    </>
  ) : (
    <></>
  )
}

export default FormUploadPreviewList
