import styled from "@emotion/styled"
import { faFolder } from "@fortawesome/pro-solid-svg-icons"
import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  Typography
} from "@mui/material"
import { IApiFunc, IApiResult } from "auth/interface/api.interface"
import { useApi } from "auth/useApi3"
import { AppContext } from "components/AppContextProvider"
import Button from "components/Button"
import TextEditor from "components/Fields/TextEditor"
import PermissionAndRolesSelect from "components/PermissionAndRoles/PermissionAndRolesSelect"
import { StyledFormControlLabelBold } from "components/PermissionAndRoles/PermissionAndRolesSelect.styled"
import { RouterPrompt } from "components/RouterPrompt"
import { CircleIcon } from "components/icons/CircleIcon"
import CloseSidebarButton from "components/navigation/CloseSidebarButton"
import { Formik } from "formik"
import { apiEndpoints } from "generated/apiEndpoints"
import { t } from "i18next"
import { useContext, useEffect, useRef, useState } from "react"
import { IDocumentFolder, IDocumentFormValues } from "../../interface/folder.interface"
import { folderValidationSchema } from "../folderValidationSchema"
export const StyledList = styled(List)({
  "& > li > ul": {
    width: "100%"
  }
})

const FolderForm = (props: IDocumentFormValues) => {
  const { formValues, handleSubmit, results } = props
  const { id, body, companyIds, customRoleIds } = formValues as IDocumentFolder
  const [roleResult, listRoleApi] = useApi() as [IApiResult, IApiFunc]
  const appContext = useContext(AppContext)
  const [sharedByAll, setSharedToAll] = useState(true)

  useEffect(() => {
    listRoleApi.get(apiEndpoints.customeradmin.getcustomercustomroles)
  }, [])

  useEffect(() => {
    console.log(
      companyIds?.length,
      customRoleIds?.length,
      companyIds?.length < 1 && customRoleIds?.length < 1
    )
    setSharedToAll(companyIds?.length < 1 && customRoleIds?.length < 1)
  }, [companyIds, customRoleIds])

  const formRef = useRef<any>(null)

  useEffect(() => {
    if (results.status === 2 && formRef.current) {
      formRef.current.resetForm()
    }
  }, [results])

  return (
    <Formik
      innerRef={formRef}
      initialValues={formValues}
      validationSchema={folderValidationSchema}
      onSubmit={handleSubmit}
      enableReinitialize={true}>
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
        <form id="CustomFieldForm" onSubmit={handleSubmit} noValidate>
          <RouterPrompt />
          <StyledList>
            {id < 1 && (
              <CloseSidebarButton
                toggleMenu={() => {
                  appContext.toggleSideBar()
                }}
              />
            )}

            {id < 1 && (
              <ListItem>
                <Box pt={5} pb={3} display="flex" alignItems="center" justifyContent="space-between">
                  <Box display="flex" alignItems="center">
                    <CircleIcon icon={faFolder} color="primary" size="lg" sx={{ marginRight: "15px" }} />
                    <Box justifyContent="center">
                      <ListItemText
                        primary={
                          <Typography color="textPrimary" variant="h2">
                            {t(`documents.add_button`)}
                          </Typography>
                        }
                      />
                    </Box>
                  </Box>
                </Box>
              </ListItem>
            )}
            <ListItem>
              <ListItemText
                primary={
                  <InputLabel>
                    <Typography variant="labelMedium" fontWeight={500}>
                      {t(`documents.folder_form.name`)}
                    </Typography>
                  </InputLabel>
                }
                secondary={
                  <TextField
                    autoComplete="off"
                    fullWidth
                    id="name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.name && errors.name ? true : false}
                    helperText={touched.name && errors.name ? errors.name : null}
                    variant="outlined"
                  />
                }
              />
            </ListItem>

            <ListItem>
              <ListItemText
                primary={
                  <InputLabel>
                    <Typography variant="labelMedium" fontWeight={500}>
                      {t(`documents.folder_form.description`)}
                    </Typography>
                  </InputLabel>
                }
                secondary={
                  <TextField
                    multiline
                    rows={2}
                    autoComplete="off"
                    fullWidth
                    id="description"
                    value={values?.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.description && errors.description ? true : false}
                    helperText={touched.description && errors.description ? errors.description : null}
                    variant="outlined"
                  />
                }
              />
            </ListItem>

            <ListItem>
              <ListItemText
                primary={
                  <InputLabel>
                    <Typography variant="labelMedium" fontWeight={500}>
                      {t(`documents.folder_form.bodytext`)}
                    </Typography>
                  </InputLabel>
                }
                secondary={
                  <TextEditor
                    value={body}
                    setData={(e: any) => {
                      setFieldValue("body", e)
                    }}
                    uploadApi={apiEndpoints.documents.uploadattachment}
                  />
                }
              />
            </ListItem>
            <ListItem>
              <FormControlLabel
                control={
                  <Switch
                    onChange={(e) => {
                      setFieldValue("isVisibleStartPage", e.target.checked)
                    }}
                    id="company"
                    checked={values.isVisibleStartPage}
                  />
                }
                label={t(`documents.folder_form.list_startpage`)}
              />
            </ListItem>
            <ListItem>
              <List>
                <Typography variant="h4" gutterBottom>
                  Dela åtkomst
                </Typography>
                <Typography variant="bodySmall">Välj behörigheter för detta dokument</Typography>
                <Box marginTop={2}></Box>
                <FormControl>
                  <RadioGroup
                    aria-labelledby="roles-button-group"
                    defaultValue={true}
                    value={sharedByAll}
                    name="radio-buttons-group">
                    <StyledFormControlLabelBold
                      value={true}
                      control={<Radio />}
                      label="Alla"
                      onChange={() => {
                        const customRoleIds: any[] = []
                        const companyIds: any[] = []
                        const departmentIds: any[] = []
                        setFieldValue("customRoleIds", customRoleIds)
                        setFieldValue("companyIds", companyIds)
                        setFieldValue("departmentIds", departmentIds)
                        setSharedToAll(true)
                        // setExpanded(false)
                      }}
                    />
                    <StyledFormControlLabelBold
                      value={false}
                      control={<Radio />}
                      label="Tilldela åtkomst"
                      onChange={() => {
                        setSharedToAll(false)
                      }}
                    />
                  </RadioGroup>
                </FormControl>
                <Box marginTop={2}>
                  <Divider />
                </Box>
              </List>
            </ListItem>
            <ListItem>
              {roleResult.status === 2 && (
                <List>
                  <Typography variant="h5">Roller</Typography>
                  {roleResult.data.map((role: any) => {
                    return (
                      <ListItem disablePadding>
                        <StyledFormControlLabelBold
                          control={
                            <Checkbox
                              disabled={sharedByAll}
                              onChange={(e) => {
                                const value = Number(e.target.value)
                                const customRoleIds = values.customRoleIds ? values.customRoleIds : []

                                if (!customRoleIds.includes(value)) {
                                  customRoleIds.push(value)
                                } else {
                                  const index = customRoleIds.findIndex((x) => x === value)
                                  if (index > -1) {
                                    customRoleIds.splice(index, 1)
                                  }
                                }

                                setFieldValue && setFieldValue("customRoleIds", customRoleIds)
                              }}
                              id="customRoleIds"
                              value={role.id}
                              checked={
                                sharedByAll ||
                                (values?.customRoleIds &&
                                values?.customRoleIds?.findIndex((x) => x === role.id) !== -1
                                  ? true
                                  : false)
                              }
                            />
                          }
                          label={role.name}
                        />
                      </ListItem>
                    )
                  })}
                  <Box marginTop={2}>
                    <Divider />
                  </Box>
                </List>
              )}
            </ListItem>
            <ListItem>
              <List>
                <Grid item xs={12}>
                  <Typography variant="h5">
                    {t(`company_and_departments`, `Bolag och avdelningar`)}
                  </Typography>

                  <PermissionAndRolesSelect
                    sharedByAll={sharedByAll}
                    companies={appContext.appUser.customer.companies}
                    initCompanies={values.companyIds}
                    initDepartments={values.departmentIds}
                    disabled={values.companyIds?.length < 1 && sharedByAll}
                    onChange={(newState: any) => {
                      const companyIds: any[] = []
                      const departmentIds: any[] = []

                      newState.forEach((c: any) => {
                        if (c.selected) companyIds.push(c.id)
                        c.departments.forEach((d: any) => {
                          if (d.selected) departmentIds.push(d.id)
                        })
                      })

                      setFieldValue("companyIds", companyIds)
                      setFieldValue("departmentIds", departmentIds)
                    }}
                  />
                </Grid>
                <Box marginTop={2}>
                  <Divider />
                </Box>
              </List>
            </ListItem>
            <ListItem>
              <Button result={results} type="submit">
                {id > 0 ? t(`documents.folder_form.save_button`) : t(`documents.folder_form.create_button`)}
              </Button>
            </ListItem>
          </StyledList>
        </form>
      )}
    </Formik>
  )
}
export default FolderForm
