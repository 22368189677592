import { styled } from "@mui/material/styles"
import { ListItemText, Typography } from "@mui/material"

export const StyledListItemText = styled(ListItemText)({
  marginTop: "18px",
  "& .MuiListItemText-primary": {
    marginTop: "18px",
    fontSize: "16px"
  },

  "& .MuiListItemText-secondary": {
    color: "#000000",
    fontWeight: 600
  }
})

export const StyledTypography = styled(Typography)(({ theme }) => ({
  paddingLeft: "8px",
  fontSize: "14px"
}))