import { faFlag, faCircleNotch } from "@fortawesome/pro-solid-svg-icons"
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
  Stack,
  TextField,
  Typography
} from "@mui/material"
import { useApiDelete, useApiFile } from "auth/useApi2"
import { useApi } from "auth/useApi3"
import ContinueDialog from "components/Dialogs/ContinueDialog"
import SuccessDialog from "components/Dialogs/SuccessDialog"
import { GlobalLoaderContext } from "components/GlobalLoadingProvider"
import { RouterPrompt } from "components/RouterPrompt"
import { CircleIcon } from "components/icons/CircleIcon"
import CloseSidebarButton from "components/navigation/CloseSidebarButton"
import { Formik } from "formik"
import { apiEndpoints } from "generated/apiEndpoints"
import { Sidebar } from "pages/vehicleregistry/pages/vehicles/pages/customfields/components/Sidebar"
import { useContext, useEffect, useRef, useState } from "react"
import * as Yup from "yup"
import ReadMoreReadLess from "./ReadMoreReadLess"
import { ReadMoreReadLessTitle } from "./ReadMoreReadLessTitle"
import { useStyles } from "./useStyles"
import FormUploadFile from "components/Form/FormComponents/FormUploadFile/FormUploadFile"
import FormUploadPreviewList from "components/Form/FormComponents/FormUploadFile/FormUploadPreviewList"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import theme from "css/theme/light"

// validation
const currentValidationSchema = Yup.object().shape({
  vehicleId: Yup.string().required("Fordon är obligatoriskt,"),
  issueReportCategoryId: Yup.string().required("Kategori är obligatoriskt"),
  description: Yup.string()
    .required("Beskrivning är obligatoriskt")
    .max(300, "Beskrivning får max vara 300 tecken långt")
})

const ImportIssueReportForm = ({
  showSidebarForm,
  setShowSidebarForm,
  updateExistingData,
  chosenVehicle,
  setChosenVehicle
}) => {
  // styles
  const classes = useStyles()

  // formik initialvalues
  const initialValues = {
    vehicleId: "",
    issueReportCategoryId: "",
    description: "",
    attachments: [],
    meterSetting: null
  }

  // datafetch states and handlers
  const [deleteResult, deleteFile, resetDeleteState] = useApiDelete()
  const [vehiclesResult, vehiclesApi] = useApi()
  const [categorysResult, categoryApi] = useApi()
  const [vehicleCategoriesResult, vehicleCategoriesApi] = useApi()
  const [addResult, addApi] = useApi()
  const [fileUploadResult, uploadFile] = useApiFile()
  const [files, setFiles] = useState([])
  const [filesPreview, setFilesPreview] = useState(initialValues.attachments)
  const [chosenCategory, setChosenCategory] = useState("")
  const [issueReports, setIssueReports] = useState([])
  const [showContinueModal, setShowContinueModal] = useState(false)
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [chosenRegNumber, setChosenRegNumber] = useState("")
  const formRef = useRef()

  useEffect(() => {
    if (chosenVehicle) {
      formRef.current.setFieldValue("vehicleId", chosenVehicle?.id)
      setChosenRegNumber(chosenVehicle?.id)
    }
  }, [chosenVehicle])

  // GET vehicles for regNumber
  useEffect(() => {
    vehiclesApi.get(apiEndpoints.issuereports.getvehiclelist)
    return () => {
      vehiclesApi.leave()
    }
  }, [vehiclesApi])

  /* GET Categories */
  useEffect(() => {
    categoryApi.get(apiEndpoints.issuereports.getissuereportcategories)
    return () => {
      categoryApi.leave()
    }
  }, [categoryApi])

  /* File management */
  useEffect(() => {
    if (files) {
      files.map((file) => {
        return uploadFile(apiEndpoints.issuereports.uploadfile, file)
      })
    }
  }, [files, uploadFile])

  useEffect(() => {
    if (fileUploadResult.data && fileUploadResult.data.name) {
      setFilesPreview((p) => {
        return [...p, fileUploadResult.data]
      })
    }
  }, [fileUploadResult])

  /* Show images in a list */
  useEffect(() => {
    if (addResult.status === 2) {
      categoryApi.get(apiEndpoints.issuereports.getissuereportcategories)
      if (updateExistingData !== null) {
        updateExistingData()
      }
    }
  }, [addResult])

  useEffect(() => {
    if (addResult.status === 2) {
      setShowContinueModal(false)
      setShowSuccessModal(true)
      setShowSidebarForm(false)
    }
  }, [addResult])

  // loading
  const loadingContext = useContext(GlobalLoaderContext)
  useEffect(() => {
    loadingContext.setLoading(() => vehiclesResult.status === 1)
    loadingContext.setLoading(() => categorysResult.status === 1)
    loadingContext.setLoading(() => addResult.status === 1)
    loadingContext.setLoading(() => fileUploadResult.status === 1)
  })

  // handle submit formik form & show continue modal
  const handleSubmit = () => {
    setShowContinueModal(true)
  }

  // post data & set states for modals/sidebar
  const onAddSubmit = (values) => {
    addApi.post(apiEndpoints.issuereports.createissuereport, values)
  }

  // clear form when sidebar is closed
  useEffect(() => {
    if (!showSidebarForm) {
      formRef.current.resetForm()
      setChosenRegNumber("")
      setChosenCategory("")
      setFilesPreview([])
    }
  }, [showSidebarForm])

  useEffect(() => {
    if (chosenRegNumber && chosenCategory) {
      vehicleCategoriesApi.get(
        apiEndpoints.issuereports.getissuereportsbyvehicleidandcategoryid
          .replace("{vehicleid}", chosenRegNumber)
          .replace("{issuereportcategoryid}", chosenCategory)
      )
    }
  }, [chosenCategory, chosenRegNumber, vehicleCategoriesApi])

  useEffect(() => {
    if (vehicleCategoriesResult.status === 2) {
      setIssueReports(vehicleCategoriesResult.data)
    } else {
      setIssueReports([])
    }
  }, [vehicleCategoriesResult])

  useEffect(() => {
    if (filesPreview) {
      formRef.current.setFieldValue("attachments", filesPreview)
    }
  }, [filesPreview])

  const handleRemove = (attachments, index) => {
    if (attachments) {
      deleteFile(apiEndpoints.issuereports.deletefile.replace("{id}", attachments.id))

      const newPreviewFiles = [...filesPreview]
      newPreviewFiles.splice(index, 1)
      setFilesPreview(newPreviewFiles)

      const newFiles = [...files]
      newFiles.splice(index, 1)
      setFiles(newFiles)
      formRef.current.setFieldValue("attachments", newFiles)
    }
  }

  return (
    <Sidebar showSidebar={showSidebarForm}>
      <Formik
        initialValues={initialValues}
        validationSchema={currentValidationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
        innerRef={formRef}>
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          isSubmitting,
          handleSubmit,
          setFieldValue
        }) => (
          <form id="CustomFieldForm" onSubmit={handleSubmit} noValidate>
            <RouterPrompt />
            <List className={classes.vehicleList}>
              <CloseSidebarButton
                toggleMenu={() => {
                  setShowSidebarForm(!showSidebarForm)
                }}
              />
              <Box pt={2} pb={3} className={classes.fullWidth}>
                <CircleIcon icon={faFlag} color="primary" size="lg" sx={{ marginRight: "15px" }} />

                <Box row="column" justifyContent="center">
                  <ListItemText
                    primary={
                      <Typography color="textPrimary" variant="h2">
                        Skapa felrapport
                      </Typography>
                    }
                    secondary={
                      <Typography color="textSecondary" variant="bodySmall">
                        Fordon
                      </Typography>
                    }
                  />
                </Box>
              </Box>
              <Box className={classes.greyBorder}>
                <ListItem className={classes.listItem}>
                  <Box mt={1}>
                    <ListItemText
                      classes={{
                        secondary: classes.listItemText,
                        primary: classes.listItemTextPrimary
                      }}
                      style={{ paddingBottom: "8px" }}
                      primary="Välj fordon"
                    />
                  </Box>

                  {vehiclesResult.status === 2 && (
                    <Autocomplete
                      className={classes.fullWidth}
                      id="vehicleId"
                      options={vehiclesResult.data}
                      getOptionLabel={(option) =>
                        chosenVehicle
                          ? chosenVehicle.internnumber
                          : option.internnumber
                          ? option.internnumber
                          : ""
                      }
                      value={vehiclesResult.data.find((i) => i.id === values.vehicleId) || ""}
                      getOptionSelected={(option, value) => {
                        return option.id === value.id
                      }}
                      onChange={(e, value) => {
                        if (value === null) {
                          setFieldValue("vehicleId", "")
                          setChosenRegNumber("")
                          setChosenVehicle(null)
                        } else {
                          setFieldValue("vehicleId", value?.id)
                          setChosenRegNumber(value?.id)
                        }
                      }}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Stack direction="row" spacing={2} justifyContent="space-between">
                            <Box sx={{ fontWeight: 600, minWidth: "100px" }}>{option.internnumber}</Box>
                            <Box sx={{ opacity: 0.6 }}>{option.regNumber}</Box>
                          </Stack>
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          name="vehicleId"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.vehicleId && errors.vehicleId ? true : false}
                          helperText={touched.vehicleId && errors.vehicleId ? errors.vehicleId : null}
                        />
                      )}
                    />
                  )}
                </ListItem>
              </Box>

              <ListItem className={classes.listItem}>
                <ListItemText
                  classes={{
                    secondary: classes.listItemText,
                    primary: classes.listItemTextPrimary
                  }}
                  style={{ paddingBottom: "8px", display: "flex", gap: "4px", alignItems: "center" }}
                  primary="Mätarställning"
                  secondary="(Ej obligatorisk)"
                />
                <TextField
                  type="number"
                  rows={1}
                  autoComplete="off"
                  className={classes.textField}
                  fullWidth
                  id="meterSetting"
                  value={values.meterSetting}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  variant="outlined"
                  InputProps={{
                    classes: { input: classes.formAreaInput },
                    disableUnderline: true,
                    inputProps: { min: 0 }
                  }}
                />
              </ListItem>

              <ListItem className={classes.listItem}>
                <ListItemText
                  classes={{
                    secondary: classes.listItemText,
                    primary: classes.listItemTextPrimary
                  }}
                  style={{ paddingBottom: "8px" }}
                  primary="Kategori"
                />

                {categorysResult.status === 2 && (
                  <Autocomplete
                    className={classes.fullWidth}
                    id="issueReportCategoryId"
                    options={categorysResult.data}
                    getOptionLabel={(option) => (option.name ? option.name : "")}
                    value={categorysResult.data.find((i) => i.id === values.issueReportCategoryId) || ""}
                    getOptionSelected={(option, value) => {
                      return option.id === value.id
                    }}
                    onChange={(e, value) => {
                      setFieldValue("issueReportCategoryId", value?.id)
                      setChosenCategory(value?.id)
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        name="issueReportCategoryId"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.issueReportCategoryId && errors.issueReportCategoryId ? true : false}
                        helperText={
                          touched.issueReportCategoryId && errors.issueReportCategoryId
                            ? errors.issueReportCategoryId
                            : null
                        }
                      />
                    )}
                  />
                )}
              </ListItem>

              <Box>
                {chosenRegNumber && chosenCategory && (
                  <>
                    {vehicleCategoriesResult.status === 2 ? (
                      issueReports.length > 0 ? (
                        <>
                          <ReadMoreReadLessTitle />
                          {issueReports.map((data) => (
                            <ReadMoreReadLess
                              key={data.id}
                              date={data.createdDate}
                              description={data.description}
                            />
                          ))}
                        </>
                      ) : (
                        <Typography mt={0} mb={2} fontSize="13px" color="success.main">
                          Det finns inga befintliga felrapporter på fordonet
                        </Typography>
                      )
                    ) : (
                      <FontAwesomeIcon
                        spin
                        icon={faCircleNotch}
                        size="1x"
                        variant="contained"
                        color={theme.palette.primary["main"]}
                      />
                    )}
                  </>
                )}
              </Box>

              <>
                <ListItem className={classes.listItem}>
                  <ListItemText
                    classes={{
                      secondary: classes.listItemText,
                      primary: classes.listItemTextPrimary
                    }}
                    style={{ paddingBottom: "8px" }}
                    primary="Beskrivning"
                  />
                  <TextField
                    placeholder="Beskriv felet så utförligt som möjligt"
                    rows={4}
                    multiline
                    autoComplete="off"
                    className={classes.textField}
                    fullWidth
                    id="description"
                    value={values.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.description && errors.description ? true : false}
                    helperText={touched.description && errors.description ? errors.description : null}
                    variant="outlined"
                    InputProps={{
                      classes: { input: classes.formAreaInput },
                      disableUnderline: true
                    }}
                  />
                </ListItem>

                <ListItem className={classes.listItem}>
                  <ListItemText
                    classes={{
                      secondary: classes.listItemText,
                      primary: classes.listItemTextPrimary
                    }}
                    style={{ paddingBottom: "8px" }}
                    primary="Bilder"
                  />
                  <Box width="100%" textAlign="center">
                    <FormUploadFile
                      acceptedFileFormat={{
                        "image/jpeg": [".jpg", ".jpeg"],
                        "image/svg+xml": [".svg"],
                        "image/png": [".png"]
                      }}
                      handleChange={(files) => {
                        files.forEach((file) => {
                          uploadFile(apiEndpoints.issuereports.uploadfile, file)
                        })
                      }}
                    />
                    <FormUploadPreviewList
                      fileUploadResult={fileUploadResult}
                      handleRemove={(index, file) => handleRemove(index, file)}
                      files={filesPreview}
                    />
                  </Box>
                </ListItem>
              </>

              <Box pt={3} pb={5} display="flex" justifyContent="center">
                <Box display="flex" justifyContent="center" direction="row">
                  <Box>
                    <Button color="primary" variant="contained" type="submit">
                      Skicka felrapport
                    </Button>
                  </Box>
                </Box>
              </Box>
            </List>

            {showContinueModal && (
              <ContinueDialog
                result={addResult}
                setShowContinueModal={setShowContinueModal}
                onAddSubmit={onAddSubmit}
                values={values}
                message={`Du kommer skapa och skicka in en felrapport, vill du fortsätta?`}
                continueBtnText="Skicka felrapport"
                cancelBtnText="Avbryt"
              />
            )}

            {showSuccessModal && (
              <SuccessDialog
                resetModalState={setShowSuccessModal}
                title="Din felrapport är sparad och ett ärende kommer upprättas. Du får en notis om ärendets status när det uppdateras."
              />
            )}
          </form>
        )}
      </Formik>
    </Sidebar>
  )
}
export default ImportIssueReportForm
