import { styled } from "@mui/material/styles"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconButton, ListItem, Switch } from "@mui/material"

export const StyledFontAwesomeIconEdit = styled(FontAwesomeIcon)<{ editMode: boolean }>(({ theme, editMode }) => {
  const color = editMode ? theme.palette.primary.main : theme.palette.grey[500]
  return {
    color
  }
})

export const StyledSwitchDisabled = styled(Switch)({
  marginLeft: "-10px"
})

export const StyledListItem = styled(ListItem)({
  borderRadius: "0 !important",
  padding: "14px 0",
  alignItems: "baseline",
  display: "flex",
  flexDirection: "row",
  "& .MuiFormControl-root": {
    width: "280px"
  },
  "& .MuiFormControlLabel-root": {
    width: "280px"
  }
})

export const StyledIconButtonEdit = styled(IconButton)<{ top: number, right: number}>(({ top, right }) => ({
  position: "absolute",
  top: top,
  right: right,
  height: "42px",
  width: "42px"
}))