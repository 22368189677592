import { InputLabel, ListItem, ListItemText, TextField, Typography } from "@mui/material"

import globalUseStyles from "css/theme/globalUseStyles"
import theme from "css/theme/light"
import React from "react"
import { useTranslation } from "react-i18next"
import { IFormTextFieldProps } from "./formtextfield.interface"

const FormTextField: React.FC<IFormTextFieldProps> = (props) => {
  const globalClasses = globalUseStyles()
  const { t } = useTranslation()
  const { id, value, labelDefault, labelKey, optional, rows, handleBlur, handleChange, dataType } = props
  return (
    <ListItem sx={{ padding: 0 }}>
      <ListItemText
        primary={
          <InputLabel className={globalClasses.marginBottom5}>
            <Typography variant="labelLarge" fontWeight={500}>
              {labelKey ? t(labelKey, labelDefault) : labelDefault} {!optional && "*"}
            </Typography>
          </InputLabel>
        }
        secondary={
          <TextField
            sx={{ background: theme.palette.background.paper }}
            fullWidth
            defaultValue={value}
            onChange={handleChange}
            onBlur={handleBlur}
            type={dataType === 1 ? "number" : "text"}
            {...props}
            variant="outlined"
            multiline={rows !== undefined}
            rows={rows}
          />
        }
      />
    </ListItem>
  )
}

export default FormTextField
