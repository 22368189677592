import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { faCheck, faTimes } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, Grid, InputLabel, Switch, Typography } from "@mui/material"
import globalUseStyles from "css/theme/globalUseStyles"
import theme from "css/theme/light"
import { t } from "i18next"
import React from "react"
import Moment from "react-moment"
import { IFormFieldProps } from "../FormField/formfield.interface"
import FormLocationPicker from "./FormLocationPicker/FormLocationPicker"
import { IFormLocationPickerProps } from "./FormLocationPicker/formlocationpicker.interface"
import FormPreviewFileThumbnail from "./FormUploadFile/FormPreviewFileThumbnail"
import { IFormUploadAttachmentProps } from "./FormUploadFile/formuploadfile.interface"
import { FormFieldComponentEnum } from "pages/customer/pages/settings/pages/users/pages/edit/UserCustomField.interfaces"

function isJsonString(str: string) {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

export interface IFormComponentView {
  field: IFormFieldProps
}

const FormComponentsView: React.FC<IFormComponentView> = (props) => {
  const { field } = props
  const { labelKey, labelDefault, value, component, options } = field

  const globalClasses = globalUseStyles()
  switch (component) {
    case FormFieldComponentEnum.CardButton:
      return (
        <>
          <Typography variant="labelLarge" fontWeight={500} color="textSecondary">
            {labelKey ? t(labelKey, labelDefault) : labelDefault}
          </Typography>
          <Typography variant="bodyMedium">
            {options?.find((x) => x.id.toString() === value)?.labelDefault}
          </Typography>
        </>
      )
    case FormFieldComponentEnum.BigRadioButtons: {
      return (
        <>
          <Typography variant="labelLarge" fontWeight={500} color="textSecondary">
            {labelKey ? t(labelKey, labelDefault) : labelDefault}
          </Typography>
          <Typography variant="bodyMedium">
            {options?.find((x) => x.value.toString() === value)?.labelDefault}
          </Typography>
        </>
      )
    }

    case FormFieldComponentEnum.Checkbox: {
      const label = labelKey ? t(labelKey, labelDefault) : labelDefault

      return (
        <>
          <FontAwesomeIcon
            icon={value === "true" ? (faCheck as IconProp) : (faTimes as IconProp)}
            color={value === "true" ? theme.palette.primary["main"] : theme.palette.error["main"]}
          />
          <Typography variant="labelLarge" fontWeight={500} marginLeft={1}>
            {label}
          </Typography>
        </>
      )
    }

    case FormFieldComponentEnum.Switch: {
      const label = labelKey ? t(labelKey, labelDefault) : labelDefault

      return (
        <>
          <Typography variant="inputLabel" fontWeight={500} color="textSecondary">
            {label}
          </Typography>
          <Switch checked={value === "true"} disabled />
        </>
      )
    }

    case FormFieldComponentEnum.LocationPicker: {
      const convertedValue = value && isJsonString(value) ? JSON.parse(value) : null

      const componentProps: IFormLocationPickerProps = {
        labelKey: labelKey,
        labelDefault: labelDefault,
        latLng: convertedValue?.value,
        value: "",
        disableSelect: true
      }
      return (
        <>
          <Typography variant="inputLabel" color="textSecondary">
            {labelKey ? t(labelKey, labelDefault) : labelDefault}
          </Typography>
          <Typography variant="bodyMedium">{convertedValue?.interpretedValue}</Typography>
          <FormLocationPicker {...componentProps} />
        </>
      )
    }
    case FormFieldComponentEnum.DateTimePicker: {
      return (
        <>
          <Typography variant="inputLabel" color="textSecondary">
            {labelKey ? t(labelKey, labelDefault) : labelDefault}
          </Typography>
          <Typography variant="bodyMedium">
            {value ? <Moment format="YYYY-MM-DD HH:mm">{value}</Moment> : <Box>-</Box>}
          </Typography>
        </>
      )
    }
    case FormFieldComponentEnum.DatePicker: {
      return (
        <>
          <Typography variant="inputLabel" color="textSecondary">
            {labelKey ? t(labelKey, labelDefault) : labelDefault}
          </Typography>
          <Typography variant="bodyMedium">
            {value ? <Moment format="YYYY-MM-DD">{value}</Moment> : <Box>-</Box>}
          </Typography>
        </>
      )
    }
    case FormFieldComponentEnum.VehiclePicker: {
      const convertedValue = value && isJsonString(value) ? JSON.parse(value) : null

      return (
        <>
          <Typography variant="inputLabel" color="textSecondary">
            {labelKey ? t(labelKey, labelDefault) : labelDefault}
          </Typography>
          <Typography variant="bodyMedium">{convertedValue?.RegNumber}</Typography>
        </>
      )
    }
    case FormFieldComponentEnum.Select: {
      return (
        <>
          <Typography variant="labelLarge" fontWeight={500} color="textSecondary">
            {labelKey ? t(labelKey, labelDefault) : labelDefault}
          </Typography>
          <Typography variant="bodyMedium">
            {options?.find((x) => x.value.toString() === value)?.labelDefault}
          </Typography>
        </>
      )
    }

    case FormFieldComponentEnum.DepartmentPicker: {
        const convertedValue = value && isJsonString(value) ? JSON.parse(value) : null

        return (
          <>
            <Typography variant="inputLabel" color="textSecondary">
              {labelKey ? t(labelKey, labelDefault) : labelDefault}
            </Typography>
            <Typography variant="bodyMedium">{convertedValue?.Name}</Typography>
          </>
        )
      }
    case FormFieldComponentEnum.CompanyPicker: {
        const convertedValue = value && isJsonString(value) ? JSON.parse(value) : null

        return (
          <>
            <Typography variant="inputLabel" color="textSecondary">
              {labelKey ? t(labelKey, labelDefault) : labelDefault}
            </Typography>
            <Typography variant="bodyMedium">{convertedValue?.Name}</Typography>
          </>
        )
      }

    case FormFieldComponentEnum.Upload:
      return (
        <Grid md={12} xs={12}>
          <InputLabel>
            <Typography variant="inputLabel">
              {" "}
              {labelKey ? t(labelKey, labelDefault) : labelDefault}
            </Typography>
          </InputLabel>
            {value !== "" && value !== null ? (
              <Grid container direction="row" spacing={2}>
                {JSON.parse(value)
                ?.filter((x: IFormUploadAttachmentProps) => x?.Data !== null)
                .map((file: IFormUploadAttachmentProps) => (
                  <Grid item xs={12} sm={6} md={4}>
                    <FormPreviewFileThumbnail {...file} />
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Typography variant="bodyMedium">Ingen fil</Typography>
            )}
        </Grid>
      )

    default:
      return (
        <>
          <Typography variant="inputLabel" fontWeight={500} color="textSecondary">
            {labelKey ? t(labelKey, labelDefault) : labelDefault}
          </Typography>
          <Typography variant="bodyMedium">{value !== null ? value : "-"}</Typography>
        </>
      )
  }
}
export default FormComponentsView
