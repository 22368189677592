import { Box, Container, Grid, Typography } from "@mui/material"
import { hasRole } from "auth/hasRole"
import { useApiPut } from "auth/useApi2"
import { useApi } from "auth/useApi3"
import { AppContext } from "components/AppContextProvider"
import PaperBox from "components/PaperBox"
import SubTabs from "components/SubTabs"
import { PageTabs } from "components/navigation/PageTabs"
import { TabPanel } from "components/navigation/TabPanel"
import { useQuery } from "components/useQuery"
import { apiEndpoints } from "generated/apiEndpoints"
import { allRoles } from "generated/roles"
import { allRoutes } from "generated/routes"
import moment from "moment"
import { useContext, useEffect, useState } from "react"
import Moment from "react-moment"
import BasicInfo from "shared/vehicle/BasicInfo"
import CustomFields from "shared/vehicle/CustomFields/CustomFields"
import Header from "shared/vehicle/Header"
import InspectionService from "shared/vehicle/InspectionService"
import IssueReportsCard from "shared/vehicle/IssueReportsCard"
import TechnicalData from "shared/vehicle/TechnicalData"
import LoadingHeader from "shared/vehicle/components/LoadingHeader"
import LoadingTabs from "shared/vehicle/components/LoadingTabs"
import { VehicleDocuments } from "shared/vehicle/components/VehicleDocuments"
import { VehicleExpenses } from "shared/vehicle/components/VehicleExpenses"
import { Sidebar } from "../../components/Sidebar"

const backButtonData = {
  "/vehicleregistry/vehicles": { text: " till Fordonsregistret", path: "/vehicleregistry/vehicles" },
  "/vehicleregistry/checks": { text: " till Kontroller", path: "/vehicleregistry/checks" },
  "/vehicleregistry/calendar": { text: " till Kalender", path: "/vehicleregistry/calendar" },
  "/vehicleregistry/reports": { text: " till Rapporter", path: "/vehicleregistry/reports" }
}

const getNavigationHistoryData = (navigationHistory) => {
  if (navigationHistory.length < 2) {
    return null
  }

  const historyIndex = navigationHistory.length - 2
  const previousPath = navigationHistory[historyIndex].path

  if (backButtonData[previousPath]) {
    return backButtonData[previousPath]
  }

  return null
}

const VehicleDetails = ({ match }) => {
  const [detailsResult, detailsFuncs] = useApi()
  const appContext = useContext(AppContext)
  const urlQuery = useQuery()
  const [tabValue, setTabValue] = useState(0)
  const [tabs, setTabs] = useState([
    { index: 0, label: "Grundinfo", queryParam: "basics" },
    { index: 1, label: "Besiktning & kontroller", queryParam: "checks" },
    { index: 2, label: "Teknisk data", queryParam: "technicaldata" },
    { index: 3, label: "Egna fält", queryParam: "customfields" },
    { index: 4, label: "Dokument", queryParam: "documents" },
    { index: 5, label: "Kostnader", queryParam: "expenses" }
  ])

  const isIssueReports = hasRole(appContext.appUser, allRoles.IssueReports)

  const [showSidebar, setShowSidebar] = useState(false)
  const [addOrUpdateResultState, setAddOrUpdateResultState] = useState(null)
  const [mainPropellantResult, mainPropellantApi] = useApi()
  const [customFieldsResult, customFieldsApi] = useApi()
  const [lastUpdatedVtrResult, lastUpdatedVtrApi] = useApi()
  const [issueReportsResult, issueReportApi] = useApi()
  const [assemblyResult, assemblyApi] = useApi()

  useEffect(() => {
    detailsFuncs.get(apiEndpoints.vehicleregistry.getvehicledetailsbyid.replace("{id}", match.params.id))
    customFieldsApi.get(
      apiEndpoints.vehicleregistry.getvehicleformvaluesfilteredbygroups.replace(
        "{vehicleId}",
        match.params.id
      )
    )
    return () => {
      customFieldsApi.leave()
      detailsFuncs.leave()
    }
  }, [])
  useEffect(() => {
    mainPropellantApi.get(apiEndpoints.vehicleregistryadmin.getavailablemainpropellant)

    lastUpdatedVtrApi.get(apiEndpoints.vehicleregistry.getlastupdatefromtransportagency)

    return () => {
      mainPropellantApi.leave()
      lastUpdatedVtrApi.leave()
    }
  }, [mainPropellantApi, lastUpdatedVtrApi])

  useEffect(() => {
    if (isIssueReports) {
      issueReportApi.get(
        apiEndpoints.issuereports.getissuereportsbyvehicleid.replace("{vehicleid}", match.params.id)
      )
    }
  }, [isIssueReports, issueReportApi])

  useEffect(() => {
    if (isIssueReports && detailsResult.status === 2) {
      const assemblyUrl = apiEndpoints.assembly.getassemblydetails
        .replace("{value}", detailsResult?.data?.regNumber)
        .replace("{type}", "regNr")
      assemblyApi.get(assemblyUrl)
    }
  }, [isIssueReports, detailsResult, assemblyApi])

  useEffect(() => {
    const tabParam = urlQuery.get("tab")
    if (!tabParam) return
    tabs.find((tab) => {
      if (tab.queryParam === tabParam.toLowerCase()) {
        setTabValue(tab.index)
        return true
      }
    })
  }, [urlQuery, issueReportsResult])

  const [updateVehicleResult, updateVehicle] = useApiPut()

  const onSubmit = (values) => {
    updateVehicle(apiEndpoints.vehicleregistryadmin.updatevehicle, values)
  }

  useEffect(() => {
    if (isIssueReports) {
      setTabs([...tabs, { index: 6, label: "Felrapporter", queryParam: "issuereports" }])
    }
  }, [isIssueReports])

  useEffect(() => {
    if (updateVehicleResult.status === 2) {
      detailsFuncs.get(apiEndpoints.vehicleregistry.getvehicledetailsbyid.replace("{id}", match.params.id))
      customFieldsApi.get(
        apiEndpoints.vehicleregistry.getvehicleformvalues.replace("{vehicleId}", match.params.id)
      )
      setShowSidebar(false)
    }
    setAddOrUpdateResultState(updateVehicleResult)
  }, [detailsFuncs, match.params.id, updateVehicleResult, customFieldsApi])

  const openForm = (row) => {
    setAddOrUpdateResultState(null)
    setShowSidebar(true)
  }

  const backButton = getNavigationHistoryData(appContext?.navigationHistory)

  return (
    <Box marginBottom={8}>
      <PageTabs
        routeBack
        tabs={[
          {
            path: backButton?.path || allRoutes.VehicleregistryVehicles(),
            title: "Tillbaka" + (backButton?.text || "")
          }
        ]}
      />
      {hasRole(appContext.appUser, allRoles.VehicleRegistryAdmin) && (
        <Sidebar
          showSidebar={showSidebar}
          onSubmit={onSubmit}
          vehicleResult={detailsResult} // inte en bra idé
          customFieldsResult={customFieldsResult}
          mainPropellantResult={mainPropellantResult}
          editMode={true}
          addOrUpdateResultState={addOrUpdateResultState}
          setShowSidebar={setShowSidebar}
        />
      )}
      <Container>
        {detailsResult.status === 2 ? (
          <>
            <Header status={detailsResult.status} headerData={detailsResult.data} openForm={openForm} />
            <Box mt={3} />
            <SubTabs tabs={tabs} value={tabValue} setValue={setTabValue} />
            <Grid container direction="row">
              <Grid item xs={12} md={12} lg={12}>
                <PaperBox mt={0}>
                  <TabPanel value={tabValue} index={0}>
                    <BasicInfo data={detailsResult.data} vehicleId={match.params.id} />
                  </TabPanel>
                  <TabPanel value={tabValue} index={1}>
                    <InspectionService data={detailsResult.data} />
                  </TabPanel>
                  <TabPanel value={tabValue} index={2}>
                    <TechnicalData data={detailsResult.data} assemblyData={assemblyResult} />
                  </TabPanel>
                  <TabPanel value={tabValue} index={3}>
                    {customFieldsResult.status === 2 && (
                      <CustomFields vehicleData={detailsResult.data} data={customFieldsResult.data} />
                    )}
                  </TabPanel>
                  <TabPanel value={tabValue} index={4}>
                    <VehicleDocuments data={detailsResult.data} />
                  </TabPanel>
                  <TabPanel value={tabValue} index={5}>
                    <VehicleExpenses vehicleData={detailsResult.data} />
                  </TabPanel>
                  {isIssueReports && (
                    <TabPanel value={tabValue} index={6}>
                      <IssueReportsCard data={issueReportsResult} vehicleId={detailsResult?.data?.id} />
                    </TabPanel>
                  )}
                </PaperBox>
              </Grid>
            </Grid>
            {lastUpdatedVtrResult.status === 2 && (
              <Typography variant="caption">
                Transportstyrelsedata från:{" "}
                {moment(lastUpdatedVtrResult.data).isValid() && (
                  <Moment format="YYYY-MM-DD" globallocale="sv">
                    {lastUpdatedVtrResult.data}
                  </Moment>
                )}
              </Typography>
            )}
          </>
        ) : (
          <>
            <Grid container direction="row">
              <Grid item xs={12} md={12} lg={12}>
                <LoadingHeader />
                <LoadingTabs />
              </Grid>
            </Grid>
          </>
        )}
      </Container>
    </Box>
  )
}
export default VehicleDetails
