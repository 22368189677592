import { Autocomplete, FormControl, InputLabel, TextField, Typography } from "@mui/material"
import { AppContext } from "components/AppContextProvider"
import globalUseStyles from "css/theme/globalUseStyles"
import theme from "css/theme/light"
import { t } from "i18next"
import { FC, useContext, useEffect, useState } from "react"
import { IFormDepartmentProps } from "./formdepartmentpicker.interface"

const FormDepartmentPicker: FC<IFormDepartmentProps> = (props) => {
  const globalClasses = globalUseStyles()
  const { id, labelKey, labelDefault, handleChange, value, optional } = props
  const [options, setOptions] = useState<Array<{ label: string; value: string }>>()
  const appContext = useContext(AppContext)
  useEffect(() => {
    const companies = appContext.appUser.customer.companies
    const allDepartments = companies
      .flatMap((company: any) => company.departments)
      .map((department: any) => ({
        label: department.name,
        value: department.id
      }))
    setOptions(allDepartments)
  }, [])

  return (
    <>
      <InputLabel className={globalClasses.marginBottom5}>
        <Typography variant="labelLarge" fontWeight={500}>
          {labelKey ? t(labelKey, labelDefault) : labelDefault} {!optional && "*"}
        </Typography>
      </InputLabel>
      <FormControl fullWidth>
        <Autocomplete
          loading
          loadingText="Laddar..."
          sx={{ width: "100%", background: theme.palette.background.paper }}
          id={id?.toString()}
          options={options ? options : []}
          getOptionLabel={(option) => option?.label}
          onChange={(e, option) => handleChange && handleChange(option?.value)}
          includeInputInList
          value={
            options?.find(
              (i) =>
                i.value?.toString() === value?.toString() ||
                i.value?.toString() === JSON.parse(value)?.Id?.toString()
            ) || null
          }
          renderInput={(params) => <TextField {...params} name={id?.toString()} variant="outlined" />}
        />
      </FormControl>
    </>
  )
}

export default FormDepartmentPicker
