import * as Yup from "yup"

export const userCustomFieldCategoryValidationSchema = Yup.object().shape({
  name: Yup.string().required("Kategorinamn är obligatoriskt"),
  active: Yup.boolean().required("Fältet är obligatoriskt")
})

export const userCustomFieldValidationSchema = Yup.object().shape({
  name: Yup.string().required("Fältnamn är obligatoriskt"),
  valueFields: Yup.array()
    .of(Yup.object().shape({
      name: Yup.string().required("Fältnamn kan inte lämnas tomt"),
      type: Yup.string().required("Typ är obligatoriskt"),
      isRequired: Yup.boolean().required("Obligatoriskt fält är obligatoriskt")
    }))
    .min(1, "Minst ett fält måste läggas till")
    .test(
      "all-fields-required",
      "Alla fält måste ha en typ",
      (value) => value?.every((val) => val.type !== "")
    ),
  userCustomFieldCategoryId: Yup.number()
    .required("Kategori är obligatorisk")
    .integer("Kategori är obligatorisk")
    .positive("Kategori måste vara aktiv"),
  active: Yup.boolean().required("Fältet är obligatoriskt")
})