import { IconProp } from "@fortawesome/fontawesome-svg-core"
import {
  faCarCrash,
  faHand,
  faMemoPad,
  faScreenUsers,
  faThoughtBubble,
  faTrafficLight
} from "@fortawesome/pro-solid-svg-icons"
import { TemplateSettings } from "pages/deviation/deviation.interface"

export const getTemplateSettings = (key: string) => {
  switch (key) {
    case "vehicle_damage":
      return {
        icon: faCarCrash as IconProp,
        description: "Om du råkat ut för en trafikolycka eller fått en skada på ditt fordon"
      } as TemplateSettings
    case "threats_and_violence":
      return {
        icon: faHand as IconProp,
        description: "Du har blivit hotad eller skadad på din arbetsplats."
      } as TemplateSettings
    case "traffic_and_customerinfo":
      return {
        icon: faTrafficLight as IconProp,
        description: "Olycka eller stopp i trafiken"
      } as TemplateSettings
    case "working_environment":
      return {
        icon: faScreenUsers as IconProp,
        description: "Du har råkat ut för något på din arbetsplats"
      } as TemplateSettings
    case "traffic_log":
      return {
        icon: faMemoPad as IconProp,
        description: ""
      } as TemplateSettings
    case "suggestion_box":
      return {
        icon: faThoughtBubble as IconProp,
        description: ""
      } as TemplateSettings
    default:
      return
  }
}
