import styled from "@emotion/styled"
import { Accordion, AccordionDetails, AccordionSummary, FormControlLabel, ListItem } from "@mui/material"

export const StyleAccordion = styled(Accordion)({
  borderBottom: "none",
  width: "100%"
})
export const StyleAccordionSummary = styled(AccordionSummary)({
  padding: 0,
  margin: 0,
  "& > .MuiAccordionSummary-content": {
    margin: "0!important",
    minHeight: "0!important"
  },
  "&.Mui-expanded": {
    margin: "0!important",
    minHeight: "0!important"
  }
})

export const StyleSubListItem = styled(ListItem)({
  padding: "0 8px 0 30px"
})

export const StyleAccordionDetails = styled(AccordionDetails)({
  padding: 0,
  margin: 0
})

export const StyledFormControlLabelBold = styled(FormControlLabel)({
  "& .MuiFormControlLabel-label": {
    fontWeight: "600"
  }
})
