import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ButtonPropsColorOverrides } from "@mui/material"
import { OverridableStringUnion } from "@mui/types"
import theme from "css/theme/light"
import { FC } from "react"

interface IconForButtonProps {
  icon: IconProp
  size?: string
  color: OverridableStringUnion<
    "primary" | "secondary" | "error" | "info" | "success" | "warning",
    ButtonPropsColorOverrides
  >
  noPadding?: boolean
  dimension?: string
  disabled?: boolean
}

const IconForButton: FC<IconForButtonProps> = ({ icon, size, color, noPadding, dimension, disabled }) => {
  return (
    <>
      <FontAwesomeIcon
        color={color}
        style={{
          backgroundColor: disabled ? theme.palette.grey[200] : !noPadding && color ? theme.palette[color][500] : "",
          borderRadius: "100%",
          padding: noPadding ? "0" : "8px",
          fontSize: size ? size : "14px",
          width: dimension ? dimension : "14px",
          height: dimension ? dimension : "14px"
        }}
        icon={icon}
      />
    </>
  )
}

export default IconForButton
