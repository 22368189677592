import { IconDefinition } from "@fortawesome/fontawesome-svg-core"
import { Grid, InputLabel, Typography } from "@mui/material"
import { IApiFunc, IApiResult } from "auth/interface/api.interface"
import { useApi } from "auth/useApi3"
import globalUseStyles from "css/theme/globalUseStyles"
import { apiEndpoints } from "generated/apiEndpoints"
import { t } from "i18next"
import React, { useEffect } from "react"
import { IOptionProps } from "../FormOption/formoption.interface"
import FormBigRadioButtons from "./FormBigRadioButtons/FormBigRadioButtons"
import { IRadioCardGroupProps } from "./FormBigRadioButtons/formbigradiobuttons.interface"
import FormCardButton from "./FormCardButton/FormCardButton"
import { IFormCardButtonProps } from "./FormCardButton/formcardbutton.interface"
import FormCheckbox from "./FormCheckbox/FormCheckbox"
import { IFormCheckboxProps } from "./FormCheckbox/formchecklist.interface"
import FormDateTimePicker from "./FormDateTimePicker/FormDateTimePicker"
import { IFormDateFieldProps } from "./FormDateTimePicker/formdatetimepicker.interface"
import FormDepartmentPicker from "./FormDepartmentPicker/FormDepartmentPicker"
import FormLocationPicker from "./FormLocationPicker/FormLocationPicker"
import { IFormLocationPickerProps } from "./FormLocationPicker/formlocationpicker.interface"
import FormSelect from "./FormSelect/FormSelect"
import { IFormSelectProps } from "./FormSelect/formselect.interface"
import FormTextField from "./FormTextField/FormTextField"
import { IFormTextFieldProps } from "./FormTextField/formtextfield.interface"
import FormUploadFile from "./FormUploadFile/FormUploadFile"
import FormUploadPreviewList from "./FormUploadFile/FormUploadPreviewList"
import { IFormUploadAttachmentProps, IFormUploadFileProps } from "./FormUploadFile/formuploadfile.interface"
import FormVehiclePicker from "./FormVehiclePicker/FormVehiclePicker"
import { IFormComponentsProps } from "./formcomponents.interface"
import {
  FormFieldComponentEnum,
  UploadData
} from "pages/customer/pages/settings/pages/users/pages/edit/UserCustomField.interfaces"
import { FormSwitch } from "./FormSwitch/FormSwitch"
import { IFormSwitchProps } from "./FormSwitch/FormSwitch.interfaces"

function isJsonString(str: string) {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

const parseAndWrapJson = (value: string): Omit<UploadData, "Data">[] => {
  let parsedData: Omit<UploadData, "Data">[] = []
  try {
    parsedData = JSON.parse(value)
  } catch (e) {
    console.error("Error parsing JSON", e)
  }

  if (!Array.isArray(parsedData)) {
    parsedData = [parsedData]
  }

  return parsedData
}

interface IAttachmentFile {
  fileId: string
  fileName: string
}

const FormComponents: React.FC<IFormComponentsProps> = (props) => {
  const { field, handleNext, formikProps, children, feature } = props

  const globalClasses = globalUseStyles()

  const [deleteFileResult, deleteFile] = useApi() as [IApiResult, IApiFunc]
  const [fileUploadResult, uploadFile] = useApi() as [IApiResult, IApiFunc]


  useEffect(() => {
    if (field.component === FormFieldComponentEnum.Upload) {
      const dataExists = formikProps.values[field.id] !== "" && formikProps.values[field.id] !== null
      if (!dataExists) return
      const jsonData = parseAndWrapJson(formikProps.values[field.id])
      if (jsonData.length === 0) {
        formikProps.setFieldValue(field.id.toString(), "")
      } else {
        const filesWithoutData = jsonData.map(({ fileId, fileName }: IAttachmentFile) => ({
          fileId,
          fileName
        }))
        formikProps.setFieldValue(field.id.toString(), JSON.stringify(filesWithoutData))
      }
    }
  }, [])

  useEffect(() => {
    if (fileUploadResult.status === 2) {
      const newFiles =
        formikProps.values[field.id] !== null && formikProps.values[field.id] !== ""
          ? [...JSON.parse(formikProps.values[field.id]), ...fileUploadResult.data]
          : fileUploadResult.data

      const filesWithoutData = newFiles.map(({ fileId, fileName }: IAttachmentFile) => ({
        fileId,
        fileName
      }))
      formikProps.setFieldValue(field.id.toString(), JSON.stringify(filesWithoutData))
    }
  }, [fileUploadResult])

  switch (field?.component) {
    case FormFieldComponentEnum.CardButton:
      return (
        <>
          {field?.options?.map((option, index) => {
            const componentProps: IFormCardButtonProps = {
              description: option.properties.descriptionDefault,
              title: option.labelDefault,
              icon: option.properties.icon as IconDefinition,
              size: "2xl",
              option: option
            }
            return (
              <FormCardButton
                {...componentProps}
                handleClick={(e: string) => {
                  formikProps.setFieldValue(field.id.toString(), e)
                  handleNext()
                }}
              />
            )
          })}
        </>
      )
    case FormFieldComponentEnum.BigRadioButtons: {
      const componentProps: IRadioCardGroupProps = {
        ...field,
        options: field.options,
        value: formikProps.values[field.id]
      }
      return (
        <FormBigRadioButtons
          {...componentProps}
          handleClick={(e: string) => formikProps.setFieldValue(field.id.toString(), e)}
        />
      )
    }

    case FormFieldComponentEnum.Checkbox: {
      const componentProps: IFormCheckboxProps = {
        ...field,
        labelDefault: field.labelDefault,
        value: formikProps.values[field.id] === "true" || formikProps.values[field.id] === true ? true : false
      }

      return (
        <FormCheckbox
          {...componentProps}
          handleChange={(e: boolean) => formikProps.setFieldValue(field.id.toString(), e)}
        />
      )
    }

    case FormFieldComponentEnum.Switch: {
      const componentProps: IFormSwitchProps = {
        ...field,
        value: formikProps.values[field.id] === "true" || formikProps.values[field.id] === true,
        handleChange: (val: boolean) => formikProps.setFieldValue(field.id.toString(), val.toString())
      }

      return (
        <FormSwitch
          {...componentProps}
        />
      )
    }

    case FormFieldComponentEnum.LocationPicker: {
      const componentProps: IFormLocationPickerProps = {
        labelKey: field.labelKey,
        labelDefault: field.labelDefault,
        latLng:
          formikProps.values[field.id] && isJsonString(formikProps.values[field.id])
            ? JSON.parse(formikProps.values[field.id]).value
            : null,
        value: "",
        optional: field.optional,
        handleBlur: formikProps.handleBlur,
        error: formikProps.touched[field.id] && formikProps.errors[field.id] ? true : false,
        helperText:
          formikProps.touched[field.id] && formikProps.errors[field.id] ? formikProps.errors[field.id] : null
      }
      return (
        <FormLocationPicker
          {...componentProps}
          handleChange={(e: string) => formikProps.setFieldValue(field.id.toString(), e)}
        />
      )
    }
    case FormFieldComponentEnum.DateTimePicker: {
      const componentProps: IFormDateFieldProps = {
        id: field.id,
        labelDefault: field.labelDefault,
        time: true,
        value: formikProps.values[field.id],
        optional: field.optional,
        handleBlur: formikProps.handleBlur,
        error: formikProps.touched[field.id] && formikProps.errors[field.id] ? true : false,
        helperText:
          formikProps.touched[field.id] && formikProps.errors[field.id] ? formikProps.errors[field.id] : null
      }
      return (
        <FormDateTimePicker
          {...componentProps}
          handleChange={(e: string) => formikProps.setFieldValue(field.id.toString(), e)}
        />
      )
    }
    case FormFieldComponentEnum.DatePicker: {
      const componentProps: IFormDateFieldProps = {
        id: field.id,
        time: false,
        labelDefault: field.labelDefault,
        value: formikProps.values[field.id],
        optional: field.optional,
        handleBlur: formikProps.handleBlur,
        error: formikProps.touched[field.id] && formikProps.errors[field.id] ? true : false,
        helperText:
          formikProps.touched[field.id] && formikProps.errors[field.id] ? formikProps.errors[field.id] : null
      }
      return (
        <FormDateTimePicker
          {...componentProps}
          handleChange={(e: string) => formikProps.setFieldValue(field.id.toString(), e)}
        />
      )
    }
    case FormFieldComponentEnum.VehiclePicker: {
      const componentProps: any = {
        field: field,
        placeholder: t("search_vehicle", "Search an vehicle"),
        value: formikProps.values[field.id.toString()],
        optional: field.optional,
        labelKey: field.labelKey,
        labelDefault: field.labelDefault,
        handleBlur: formikProps.handleBlur,
        error: formikProps.touched[field.id] && formikProps.errors[field.id] ? true : false,
        helperText:
          formikProps.touched[field.id] && formikProps.errors[field.id] ? formikProps.errors[field.id] : null
      }
      return (
        <Grid md={12} xs={12}>
          <FormVehiclePicker
            {...componentProps}
            handleChange={(e: string) => formikProps.setFieldValue(field.id.toString(), e)}
          />
        </Grid>
      )
    }
    case FormFieldComponentEnum.DepartmentPicker: {
      const componentProps: any = {
        field: field,
        placeholder: t("search_department", "Search an department"),
        value: formikProps.values[field.id.toString()],
        optional: field.optional,
        labelKey: field.labelKey,
        labelDefault: field.labelDefault,
        handleBlur: formikProps.handleBlur,
        error: formikProps.touched[field.id] && formikProps.errors[field.id] ? true : false,
        helperText:
          formikProps.touched[field.id] && formikProps.errors[field.id] ? formikProps.errors[field.id] : null
      }
      return (
        <Grid md={12} xs={12}>
          <FormDepartmentPicker
            {...componentProps}
            handleChange={(e: string) => formikProps.setFieldValue(field.id.toString(), e)}
          />
        </Grid>
      )
    }
    case FormFieldComponentEnum.CompanyPicker: {
      const componentProps: any = {
        field: field,
        placeholder: t("search_company", "Search an company"),
        value: formikProps.values[field.id.toString()],
        optional: field.optional,
        labelKey: field.labelKey,
        labelDefault: field.labelDefault,
        handleBlur: formikProps.handleBlur,
        error: formikProps.touched[field.id] && formikProps.errors[field.id] ? true : false,
        helperText:
          formikProps.touched[field.id] && formikProps.errors[field.id] ? formikProps.errors[field.id] : null
      }
      return (
        <Grid md={12} xs={12}>
          <FormDepartmentPicker
            {...componentProps}
            handleChange={(e: string) => formikProps.setFieldValue(field.id.toString(), e)}
          />
        </Grid>
      )
    }
    case FormFieldComponentEnum.MultiTextField: {
      const componentProps: IFormTextFieldProps = {
        ...field,
        id: field.id.toString(),
        value: formikProps.values[field.id],
        rows: 4,
        optional: field.optional,
        dataType: field.dataType,
        handleBlur: formikProps.handleBlur,
        error: formikProps.touched[field.id] && formikProps.errors[field.id] ? true : false,
        helperText:
          formikProps.touched[field.id] && formikProps.errors[field.id] ? formikProps.errors[field.id] : null
      }
      return (
        <Grid md={12} xs={12}>
          <FormTextField
            {...componentProps}
            handleChange={formikProps.handleChange}
            handleBlur={formikProps.handleBlur}
          />
        </Grid>
      )
    }

    case FormFieldComponentEnum.TextField: {
      const componentProps: IFormTextFieldProps = {
        ...field,
        id: field.id.toString(),
        value: formikProps.values[field.id],
        optional: field.optional,
        dataType: field.dataType,
        handleBlur: formikProps.handleBlur,
        error: formikProps.touched[field.id] && formikProps.errors[field.id] ? true : false,
        helperText:
          formikProps.touched[field.id] && formikProps.errors[field.id] ? formikProps.errors[field.id] : null
      }
      return (
        <Grid md={12} xs={12}>
          <FormTextField
            {...componentProps}
            handleChange={formikProps.handleChange}
            handleBlur={formikProps.handleBlur}
          />
        </Grid>
      )
    }

    case FormFieldComponentEnum.Select: {
      const selectOptions: Array<{ label: string; value: string }> = field.options
        ? field.options?.map((option: IOptionProps) => ({ label: option.labelDefault, value: option.value }))
        : []
      const componentProps: IFormSelectProps = {
        id: field.id.toString(),
        value: selectOptions?.find((i) => i.value.toString() === formikProps.values[field.id]) || null,
        labelKey: field.labelKey,
        labelDefault: field.labelDefault,
        options: selectOptions ? selectOptions : [],
        optional: field.optional,
        handleBlur: formikProps.handleBlur,
        error: formikProps.touched[field.id] && formikProps.errors[field.id] ? true : false,
        helperText:
          formikProps.touched[field.id] && formikProps.errors[field.id] ? formikProps.errors[field.id] : null
      }
      return (
        <Grid md={12} xs={12}>
          <FormSelect
            {...componentProps}
            handleChange={(e: string) => formikProps.setFieldValue(field.id.toString(), e)}
          />
        </Grid>
      )
    }

    case FormFieldComponentEnum.Upload:
      const componentProps: IFormUploadFileProps | any = {
        ...field,
        featureName: feature || "deviation",
        files:
          formikProps.values[field.id] !== null && formikProps.values[field.id] !== ""
            ? formikProps.values[field.id]
            : [],
        label: "Ladda",
        loading: false
      }
      return (
        <Grid md={12} xs={12}>
          <InputLabel className={globalClasses.marginBottom5}>
            <Typography variant="labelLarge" fontWeight={500}>
              {t(field.labelKey, field.labelDefault)} {!field.optional && "*"}
            </Typography>
          </InputLabel>
          {feature === "usercustomfield" && formikProps.values[field.id] !== null && formikProps.values[field.id] !== "" ? (
            <></>
          ) : (
            <FormUploadFile
              acceptedFileFormat={{
                "image/jpeg": [".jpg", ".jpeg"],
                "image/png": [".png"]
              }}
              {...componentProps}
              handleChange={(files: File[]) => {
                if (feature === "usercustomfield") {
                  uploadFile.file(apiEndpoints.attachment.addattachment.replace("{feature}", componentProps.featureName), files[0])
                } else {
                  files.forEach((file) => {
                    uploadFile.file(apiEndpoints.attachment.addattachment.replace("{feature}", componentProps.featureName), file)
                  })
                }
              }}
            />
          )}
          <FormUploadPreviewList
            handleRemove={(attachment: IFormUploadAttachmentProps) => {
              if (feature === "usercustomfield") {
                const feature = attachment.fileId.split("/")[0]
                const fileId = attachment.fileId.split("/")[1]
                const url = apiEndpoints.attachment.deleteattachment.replace("{fileid}", fileId) + `?feature=${feature}`
                formikProps.setFieldValue(field.id.toString(), "")
                deleteFile.del(url)
                formikProps.handleSubmit()
              } else {
                const url = apiEndpoints.attachment.deleteattachment.replace(
                  "{fileid}",
                  attachment.fileId.replace("/", "%2F")
                )
                deleteFile.del(url)
                const newFiles = JSON.parse(formikProps.values[field.id])?.filter(
                  (x: IFormUploadAttachmentProps) => x.fileId !== attachment.fileId
                )
                formikProps.setFieldValue(field.id.toString(), JSON.stringify(newFiles))
              }
            }}
            files={
              formikProps.values[field.id] !== null && formikProps.values[field.id] !== ""
                ? JSON.parse(formikProps.values[field.id])
                : []
            }
            feature={feature}
          />
        </Grid>
      )

    default:
      return <>{children}</>
  }
}
export default FormComponents
