import { FormControl, FormControlLabel, Switch, TextField } from "@mui/material"
import { makeStyles } from "@mui/styles"
import { DatePicker } from "@mui/x-date-pickers"
import moment from "moment"
import { useEffect } from "react"

export const useStyles = makeStyles((theme) => ({
  formAreaInput: {
    padding: "16px 12px"
  }
}))

function getDefaultValue(valueAsString, type, dateInfo) {
  switch (type) {
    case "Boolean":
      return valueAsString === "True" ? true : false
    case "Date":
      return valueAsString !== "" ? moment(valueAsString).toDate() : null
    default:
      return valueAsString
  }
}
const CustomFieldInput = (props) => {
  const classes = useStyles()
  const {
    values,
    setFieldValue,
    customField,
    handleChange,
    handleBlur,
    touched,
    errors,
    initialValues,
    setFieldTouched
  } = props
  const fieldType = customField.type // Boolean, Text, Numeric, Date

  useEffect(() => {
    setFieldValue("customField" + customField.customFieldId, customField.value)
  }, [customField])

  const updateFieldArray = async (customField, val) => {
    setFieldValue("customField" + customField.customFieldId, val)

    const newArray = [...values.customFieldValues]
    for (var i = 0; i < newArray.length; i++) {
      if (newArray[i].customFieldId === customField.customFieldId) {
        newArray[i].value = val
      }
    }
    setFieldValue("customFieldValues", newArray)
  }

  return fieldType === "Text" ? (
    <TextField
      autoComplete="off"
      fullWidth
      id={"customField" + customField.customFieldId}
      name={"customField" + customField.customFieldId}
      value={values["customField" + customField.customFieldId]}
      onChange={async (e) => {
        handleChange(e)
        await updateFieldArray(customField, e.target.value)
        setFieldTouched("customField" + customField.customFieldId, true)
      }}
      onBlur={handleBlur}
      error={
        touched["customField" + customField.customFieldId] &&
        errors["customField" + customField.customFieldId]
          ? true
          : false
      }
      helperText={
        touched["customField" + customField.customFieldId] &&
        errors["customField" + customField.customFieldId]
          ? errors["customField" + customField.customFieldId]
          : null
      }
      variant="outlined"
      InputProps={{
        classes: { input: classes.formAreaInput },
        disableUnderline: true
      }}
    />
  ) : fieldType === "Boolean" ? (
    <FormControlLabel
      control={
        <Switch
          onChange={async (item) => {
            const checked = item.target.checked
            const initiallyEmpty = initialValues["customField" + customField.customFieldId] === ""
            await updateFieldArray(customField, checked ? "True" : initiallyEmpty ? "" : "False")
            setFieldTouched("customField" + customField.customFieldId, true)
          }}
          onBlur={handleBlur}
          helperText={
            touched["customField" + customField.customFieldId] &&
            errors["customField" + customField.customFieldId]
              ? errors["customField" + customField.customFieldId]
              : null
          }
          checked={getDefaultValue(customField.value, fieldType)}
        />
      }
    />
  ) : fieldType === "Numeric" ? (
    <TextField
      autoComplete="off"
      type="number"
      fullWidth
      id={"customField" + customField.customFieldId}
      value={values["customField" + customField.customFieldId]}
      onChange={async (e) => {
        handleChange(e)
        await updateFieldArray(customField, e.target.value)
        setFieldTouched("customField" + customField.customFieldId, true)
      }}
      onBlur={handleBlur}
      error={
        touched["customField" + customField.customFieldId] &&
        errors["customField" + customField.customFieldId]
          ? true
          : false
      }
      helperText={
        touched["customField" + customField.customFieldId] &&
        errors["customField" + customField.customFieldId]
          ? errors["customField" + customField.customFieldId]
          : null
      }
      variant="outlined"
      InputProps={{ classes: { input: classes.formAreaInput }, disableUnderline: true }}
    />
  ) : fieldType === "Date" ? (
    <FormControl fullWidth>
      <DatePicker
        id={"customField" + customField.customFieldId}
        value={
          values["customField" + customField.customFieldId]
            ? moment(values["customField" + customField.customFieldId])
            : null
        }
        onChange={async (value) => {
          const formattedDate = moment(value).isValid() ? moment(value).format("YYYY-MM-DD") : null
          await setFieldValue("customField" + customField.customFieldId, formattedDate)
          await updateFieldArray(customField, formattedDate)
          setFieldTouched("customField" + customField.customFieldId, true)
        }}
        format="YYYY-MM-DD"
        slotProps={{
          textField: {
            helperText:
              touched["customField" + customField.customFieldId] &&
              errors["customField" + customField.customFieldId]
                ? errors["customField" + customField.customFieldId]
                : null,
            error:
              touched["customField" + customField.customFieldId] &&
              errors["customField" + customField.customFieldId]
                ? true
                : false
          }
        }}
      />
    </FormControl>
  ) : null
}

export default CustomFieldInput
