import { useMsal } from "@azure/msal-react"
import styled from "@emotion/styled"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { Avatar, Button, Menu, MenuItem } from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import theme from "css/theme/light"
import { allRoutes } from "generated/routes"
import React, { useContext } from "react"
import { useHistory } from "react-router"
import { AppContext } from "../components/AppContextProvider"
import { customerHasFeature } from "./customerHasFeature"
const useStyles = makeStyles((theme) => ({
  avatar: {
    background: "rgba(0, 0, 0, 0.08)",
    color: theme.palette.primary["main"],
    fontSize: "1rem",
    marginRight: 24,
    [theme.breakpoints.down("xl")]: {
      marginRight: 14
    }
  },
  iconButton: {
    padding: 0
  }
}))

function stringAvatar(name) {
  if (name !== undefined) {
    var names = name.split(" "),
      initials = names[0].substring(0, 1).toUpperCase()

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase()
    }
    return {
      children: `${initials}`
    }
  }
}

export const StyledButtonAvatarGroup = styled(Button)({
  background: "rgba(109, 199, 220, 0.16)",
  color: theme.palette.primary["main"],
  marginRight: 15,
  height: "34px"
})
export const StyledAvatar = styled(Avatar)({
  background: theme.palette.primary["main"],
  color: "rgb(57 76 95)",
  padding: "16px",
  fontSize: "1rem"
})
export const StyledAvatarArrow = styled(ExpandMoreIcon)({
  // background: theme.palette.primary["dark"],
  margin: "0 4px 0 0",
  width: "1em",
  height: "1em",
  color: theme.palette.primary["light"]
})
export const LoginLogout = () => {
  const { instance } = useMsal()
  const routerHistory = useHistory()
  const classes = useStyles()
  const appContext = useContext(AppContext)
  const userName =
    appContext.isAuth && appContext.appUser.profile ? appContext.appUser.profile.name : "Inte inloggad"
  const [anchorEl, setAnchorEl] = React.useState(null)

  const openDropdown = Boolean(anchorEl)

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      {!appContext.isAuth ? (
        <Button
          color="inherit"
          onClick={() => {
            instance.loginRedirect()
          }}>
          Logga In
        </Button>
      ) : (
        <>
          <StyledButtonAvatarGroup
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
            size="small"
            className={classes.iconButton}>
            <StyledAvatar sx={{ width: 20, height: 20, padding: "20px" }} {...stringAvatar(userName)} />
            <StyledAvatarArrow />
          </StyledButtonAvatarGroup>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
            open={openDropdown}
            onClose={handleClose}>
            {customerHasFeature(appContext.appUser.customer, "CustomerAdmin") && (
              <MenuItem
                onClick={() => {
                  routerHistory.push(allRoutes.UserSettings())
                  handleClose()
                }}>
                Inställningar
              </MenuItem>
            )}
            <MenuItem
              onClick={() => {
                appContext.signOutUser()
              }}>
              Logga ut
            </MenuItem>
          </Menu>
        </>
      )}
    </>
  )
}
