import { Grid } from "@mui/material"
import React from "react"
import { StyledButtonGroupSwitch, StyledButtonSwitch } from "../ButtonSwitch/ButtonSwitch"

interface SwitchOption {
  view: string
  setView: (data: string) => void
  viewList: ViewData[]
  noGrid?: boolean
}

export interface ViewData {
  value: string
  label: string
}

export const ViewToggler = ({ view, setView, viewList, noGrid }: SwitchOption) => {

  const content = (
    <StyledButtonGroupSwitch variant="outlined" color="inherit">
      {viewList.map((viewSwitch: ViewData) => (
        <StyledButtonSwitch
          key={viewSwitch.value}
          onClick={() => setView(viewSwitch.value)}
          active={view === viewSwitch.value}
        >
          {viewSwitch.label}
        </StyledButtonSwitch>
      ))}
    </StyledButtonGroupSwitch>
  )

  return noGrid ? (
    <div>{content}</div>
  ) : (
    <Grid container flexDirection={"row-reverse"}>
      {content}
    </Grid>
  )
}