import { useApi } from "auth/useApi3"
import { IApiFunc, IApiResult } from "auth/interface/api.interface"
import React, { useEffect, useState } from "react"
import { apiEndpoints } from "generated/apiEndpoints"
import { Sidebar } from "pages/vehicleregistry/pages/vehicles/pages/customfields/components/Sidebar"
import {
  Box,
  Button,
  FormControlLabel,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  Switch,
  Typography
} from "@mui/material"
import { Formik } from "formik"
import { RouterPrompt } from "components/RouterPrompt"
import CloseSidebarButton from "components/navigation/CloseSidebarButton"
import { CircleIcon } from "components/icons/CircleIcon"
import { faPenField } from "@fortawesome/pro-solid-svg-icons"
import { StyledListItemText } from "shared/vehicle/InspectionService.styled"
import TextField from "@mui/material/TextField"
import SubmitButton from "components/Button"
import { t } from "i18next"
import { userCustomFieldCategoryValidationSchema } from "./userCustomFieldValidationSchema"
import { CategorySidebarProps, CustomFieldCategory } from "./userCustomField.interfaces"

const initialFormikValues: CustomFieldCategory = {
  name: "",
  active: true,
  customerId: 0
}

export const UserCustomFieldCategorySidebar = ({ setShowSidebar, showSidebar, clickedCategory, handleSubmit }: CategorySidebarProps) => {
  const [customerResult, customerApi] = useApi() as [IApiResult, IApiFunc]
  const [categoryResult, categoryApi] = useApi() as [IApiResult, IApiFunc]
  const [formikValues, setFormikValues] = useState<CustomFieldCategory>(initialFormikValues)
  const formRef = React.useRef<any>()

  useEffect(() => {
    customerApi.get(apiEndpoints.customeradmin.getcustomer)
  }, [])

  useEffect(() => {
    if (categoryResult.status !== 2) return
    setShowSidebar(false)
    handleSubmit()
  }, [categoryResult])

  useEffect(() => {
    if (showSidebar) return
    setFormikValues(initialFormikValues)
    formRef.current.resetForm()
  }, [showSidebar])

  useEffect(() => {
    if (!clickedCategory) return
    setFormikValues(clickedCategory)
  }, [clickedCategory])

  const handleFormikSubmit = (formikValues: CustomFieldCategory) => {
    const customFieldCategoryData: CustomFieldCategory = {
      name: formikValues.name,
      active: formikValues.active,
      customerId: customerResult.data.id
    }
    if (clickedCategory) {
      const url = apiEndpoints.usercustomfieldsadmin.updateusercustomfieldcategory
      customFieldCategoryData.id = clickedCategory.id
      categoryApi.put(url, customFieldCategoryData)
    } else {
      const url = apiEndpoints.usercustomfieldsadmin.createusercustomfieldcategory
      categoryApi.post(url, customFieldCategoryData)
    }
  }

  return (
    <Sidebar showSidebar={showSidebar}>
      <Box sx={{ height: "100%", margin: "20px" }}>
        <Formik
          innerRef={formRef}
          initialValues={formikValues}
          validationSchema={userCustomFieldCategoryValidationSchema}
          onSubmit={(values) => {
            handleFormikSubmit(values)
          }}
          enableReinitialize={true}>
          {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              setFieldValue,
              handleBlur,
              isValid,
              dirty,
              setFieldTouched
            }) => (
            <form style={{ height: "100%" }} id="CustomFieldForm" onSubmit={handleSubmit} noValidate>
              <RouterPrompt />
              <List sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
                <Box style={{ position: "absolute", left: "-20px" }}>
                  <CloseSidebarButton
                    toggleMenu={() => {
                      setShowSidebar(!showSidebar)
                    }}
                  />
                </Box>
                <ListItem sx={{ borderBottom: "1px solid #e0e0e0" }}>
                  <Box pt={5} pb={3} display="flex" alignItems="center" justifyContent="space-between">
                    <Box display="flex" alignItems="center">
                      <CircleIcon
                        icon={faPenField}
                        color="primary"
                        size="lg"
                        sx={{ marginRight: "15px" }}
                      />
                      <Box justifyContent="center">
                        <ListItemText
                          primary={
                            <Typography color="textPrimary" variant="h2">
                              {clickedCategory ? `Redigera ${clickedCategory.name}` : "Skapa kategori"}
                            </Typography>
                          }
                          secondary={
                            <Typography color="textSecondary" variant="bodyMedium">
                              {clickedCategory ? `Ändra kategori ${clickedCategory.name} värden` : "Skapa en ny kategori för egna fält"}
                            </Typography>
                          }
                        />
                      </Box>
                    </Box>
                  </Box>
                </ListItem>
                <ListItem>
                  <StyledListItemText
                    primary={
                      <InputLabel>
                        <Typography variant="h6" color={"black"}>
                          Namn
                        </Typography>
                      </InputLabel>
                    }
                    secondary={
                      <TextField
                        placeholder="Ange namn på kategori"
                        autoComplete="off"
                        fullWidth
                        type="text"
                        id="name"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.name && errors.name ? true : false}
                        helperText={touched.name && errors.name ? errors.name : null}
                        variant="outlined"
                      />
                    }
                  />
                </ListItem>
                <ListItem>
                  <FormControlLabel
                    control={
                      <Switch
                        onChange={(event) => {
                          setFieldValue("active", event.target.checked)
                        }}
                        checked={values.active}
                      />
                    }
                    label={"Aktiv"}
                  />
                </ListItem>
                <Box sx={{ marginTop: "Auto", marginBottom: "26px", width: "100%" }}>
                  <ListItem sx={{ flexDirection: "column", gap: "14px" }}>
                    <SubmitButton
                      result={categoryResult}
                      sx={{ width: "100%", margin: "0 10px" }}
                      type="button"
                      disabled={!isValid || !dirty}
                      variant="contained"
                      onClick={
                        () => {
                          if (!dirty) {
                            Object.keys(values).forEach(valueName => {
                              setFieldTouched(valueName, true)
                            })
                            return
                          }
                          if (!isValid) {
                            Object.keys(values).forEach(valueName => {
                              setFieldTouched(valueName, true)
                            })
                            return
                          }
                          handleSubmit()
                        }}
                    >
                      Spara
                    </SubmitButton>
                    <Button
                      sx={{ width: "100%", margin: "0 10px" }}
                      type="button"
                      variant={"outlined"}
                      onClick={() => {
                        setShowSidebar(false)
                      }}>
                      {t("global.buttons.cancel")}
                    </Button>
                  </ListItem>
                </Box>
              </List>
            </form>
          )}
        </Formik>
      </Box>
    </Sidebar>
  )
}