import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Autocomplete, Box, Grid, TextField, Typography } from "@mui/material"

import { useApi } from "auth/useApi3"
import theme from "css/theme/light"
import { apiEndpoints } from "generated/apiEndpoints"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"

export const BplUserInfo = ({
  values,
  setFieldValue,
  handleBlur,
  handleChange,
  touched,
  errors,
  parentIsSysAdmin,
  customer
}) => {
  const { t } = useTranslation("translation", { keyPrefix: "settings.user" })
  const [bplResult, bplApi] = useApi()
  const [bplCompaniesResult, bplCompaniesApi] = useApi()

  useEffect(() => {
    async function fetchData() {
      if (parentIsSysAdmin) {
        await bplCompaniesApi.get(
          apiEndpoints.systemadmincustomers.getcompanieswithbplinstance.replace("{customerId}", customer.id)
        )
      } else {
        await bplCompaniesApi.get(apiEndpoints.customeradmin.getcompanieswithbplinstance)
      }
    }
    fetchData()
  }, [])

  useEffect(() => {
    async function fetchData() {
      if (parentIsSysAdmin) {
        await bplApi.get(
          apiEndpoints.systemadmincustomers.getbpltypes.replace("{companyId}", values.mainCompanyId)
        )
      } else {
        await bplApi.get(apiEndpoints.customeradmin.getbpltypes.replace("{companyId}", values.mainCompanyId))
      }
    }
    fetchData()
  }, [values.mainCompanyId])

  const reconcilationPeriods = [1, 2, 3, 4, 6, 12]
  const reconcilationPeriods2 = [1, 2]
  const workingtime = [38.25, 40]
  return (
    <>
      {values?.bplUserId === null && (
        <Grid item xs={12} marginBottom={2}>
          <Typography variant="bodySmall" color="textSecondary">
            Om användare redan finns i BPL måste användarnamn i Transportportalen matcha med användarnamn i
            BPL. Om användarnamnet inte matchar med någon användare i BPL skapas en ny användare upp.
          </Typography>
        </Grid>
      )}

      {values?.bplUserId !== null && (
        <Grid item xs={12} md={4}>
          <TextField
            autoComplete="off"
            style={{
              width: "100%"
            }}
            disabled
            id="bplUserId"
            label={t(`bplUserId`, ``)}
            value={values?.bplUserId}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.bplUserId && errors.bplUserId ? true : false}
            helperText={touched.bplUserId && errors.bplUserId ? errors.bplUserId : null}
            variant="outlined"
          />
        </Grid>
      )}
      <Grid item xs={12} md={8}>
        <Autocomplete
          multiple
          style={{
            width: "100%"
          }}
          disabled={bplResult.status !== 2}
          componentsProps={{ popper: { style: { width: "240px" } } }}
          id="bplInfo.roles"
          options={bplResult.status === 2 ? bplResult?.data?.roles : []}
          getOptionLabel={(option) => (option ? option : "")}
          value={values?.bplInfo?.roles}
          getOptionSelected={(option, value) => {
            return option === value
          }}
          onChange={(e, value) => {
            setFieldValue("bplInfo.roles", value)
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label={t(`bpl.roles`, ``)}
              name="bplInfo.roles"
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.bplInfo?.roles && errors.bplInfo?.roles ? true : false}
              helperText={touched.bplInfo?.roles && errors.bplInfo?.roles ? errors.bplInfo?.roles : null}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Autocomplete
          style={{
            width: "100%"
          }}
          disabled={bplResult.status !== 2}
          componentsProps={{ popper: { style: { width: "240px" } } }}
          id="bplInfo.stationLocationId"
          options={bplResult.status === 2 && bplResult?.data?.stations}
          getOptionLabel={(option) => (option.namn ? option.namn : "")}
          value={
            (bplResult.status === 2 &&
              bplResult.data.stations.find((i) => i.id === values?.bplInfo?.stationLocationId)) ||
            ""
          }
          getOptionSelected={(option, value) => {
            return option.id === value.id
          }}
          onChange={(e, value) => {
            setFieldValue("bplInfo.stationLocationId", value?.id)
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label={t(`bpl.stations`, ``)}
              name="bplInfo.stationLocationId"
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.bplInfo?.stationLocationId && errors.bplInfo?.stationLocationId ? true : false}
              helperText={
                touched.bplInfo?.stationLocationId && errors.bplInfo?.stationLocationId
                  ? errors.bplInfo?.stationLocationId
                  : null
              }
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Autocomplete
          style={{
            width: "100%"
          }}
          disabled={bplResult.status !== 2}
          componentsProps={{ popper: { style: { width: "240px" } } }}
          id="bplInfo.employeeCode"
          options={bplResult.status === 2 && bplResult?.data?.employeeTypes}
          getOptionLabel={(option) => (option.namn ? option.namn : "")}
          value={
            (bplResult.status === 2 &&
              bplResult.data.employeeTypes.find((i) => i.kod === values?.bplInfo?.employeeCode)) ||
            ""
          }
          getOptionSelected={(option, value) => {
            return option.id === value.kod
          }}
          onChange={(e, value) => {
            setFieldValue("bplInfo.employeeCode", value?.kod)
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label={t(`bpl.employee_types`, ``)}
              name="bplinfo.employeeCode"
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.bplInfo?.employeeCode && errors.bplInfo?.employeeCode ? true : false}
              helperText={
                touched.bplInfo?.employeeCode && errors.bplInfo?.employeeCode
                  ? errors.bplInfo?.employeeCode
                  : null
              }
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Autocomplete
          style={{
            width: "100%"
          }}
          disabled={bplResult.status !== 2}
          componentsProps={{ popper: { style: { width: "240px" } } }}
          id="bplInfo.agreementCode"
          options={bplResult.status === 2 && bplResult?.data?.workingAgreements}
          getOptionLabel={(option) => (option.namn ? option.namn : "")}
          value={
            (bplResult.status === 2 &&
              bplResult.data.workingAgreements.find((i) => i.kod === values?.bplInfo?.agreementCode)) ||
            ""
          }
          getOptionSelected={(option, value) => {
            return option.id === value.kod
          }}
          onChange={(e, value) => {
            handleChange(e)
            setFieldValue("bplInfo.agreementCode", value?.kod)
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label={t(`bpl.working_agreement_types`, ``)}
              name="bplInfo.agreementCode"
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.bplInfo?.agreementCode && errors.bplInfo?.agreementCode ? true : false}
              helperText={
                touched.bplInfo?.agreementCode && errors.bplInfo?.agreementCode
                  ? errors.bplInfo?.agreementCode
                  : null
              }
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Autocomplete
          style={{
            width: "100%"
          }}
          disabled={bplResult.status !== 2}
          componentsProps={{ popper: { style: { width: "240px" } } }}
          id="bplInfo.workingScheduleCode"
          options={bplResult.status === 2 && bplResult.data.workingSchedule}
          getOptionLabel={(option) => (option.namn ? option.namn : "")}
          value={
            (bplResult.status === 2 &&
              bplResult.data.workingSchedule.find((i) => i.kod === values?.bplInfo?.workingScheduleCode)) ||
            ""
          }
          getOptionSelected={(option, value) => {
            return option.id === value.kod
          }}
          onChange={(e, value) => {
            setFieldValue("bplInfo.workingScheduleCode", value?.kod)
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label={t(`bpl.working_schedule_types`, ``)}
              name="bplInfo.workingScheduleCode"
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                touched.bplInfo?.workingScheduleCode && errors.bplInfo?.workingScheduleCode ? true : false
              }
              helperText={
                touched.bplInfo?.workingScheduleCode && errors.bplInfo?.workingScheduleCode
                  ? errors.bplInfo?.workingScheduleCode
                  : null
              }
            />
          )}
        />
      </Grid>
      {values?.bplInfo?.workingScheduleCode === "TIDBANK" && (
        <>
          <Grid item xs={12} md={6}>
            <TextField
              autoComplete="off"
              style={{
                width: "100%"
              }}
              id={"bplInfo.timebankMaxHours"}
              label={t(`bpl.timebankMaxHours`, ``)}
              value={values.bplInfo.timebankMaxHours}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.timebankMaxHours && errors.timebankMaxHours ? true : false}
              helperText={
                touched.timebankMaxHours && errors.timebankMaxHours ? errors.timebankMaxHours : null
              }
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              autoComplete="off"
              style={{
                width: "100%"
              }}
              id={"bplInfo.timebankMinHours"}
              label={t(`bpl.timebankMinHours`, ``)}
              value={values.bplInfo.timebankMinHours}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.timebankMinHours && errors.timebankMinHours ? true : false}
              helperText={
                touched.timebankMaxHours && errors.timebankMaxHours ? errors.timebankMaxHours : null
              }
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Autocomplete
              style={{
                width: "100%"
              }}
              disabled={bplResult.status !== 2}
              componentsProps={{ popper: { style: { width: "240px" } } }}
              id="bplInfo.reconciliationPeriodMonth"
              options={reconcilationPeriods}
              getOptionLabel={(option) => option}
              value={reconcilationPeriods.find((i) => i === values?.bplInfo?.reconciliationPeriodMonth) || ""}
              getOptionSelected={(option, value) => {
                return option === value
              }}
              onChange={(e, value) => {
                setFieldValue("bplInfo.reconciliationPeriodMonth", value)
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label={t(`bpl.reconcilation_period_in_months`, ``)}
                  name="reconciliationPeriodMonth"
                />
              )}
            />
          </Grid>
        </>
      )}
      {values?.bplInfo?.workingScheduleCode === "DAG" && (
        <Grid item xs={12} md={6}>
          <Autocomplete
            style={{
              width: "100%"
            }}
            disabled={bplResult.status !== 2}
            componentsProps={{ popper: { style: { width: "240px" } } }}
            id="bplInfo.reconciliationPeriodMonth"
            options={reconcilationPeriods2}
            getOptionLabel={(option) => option}
            value={reconcilationPeriods2.find((i) => i === values?.bplInfo?.reconciliationPeriodMonth) || ""}
            getOptionSelected={(option, value) => {
              return option === value
            }}
            onChange={(e, value) => {
              setFieldValue("bplInfo.reconciliationPeriodMonth", value)
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label={t(`bpl.reconcilation_period_in_months`, ``)}
                name="reconciliationPeriodMonth"
              />
            )}
          />
        </Grid>
      )}
      <Grid item xs={12} md={6}>
        <Autocomplete
          style={{
            width: "100%"
          }}
          disabled={bplResult.status !== 2}
          componentsProps={{ popper: { style: { width: "240px" } } }}
          id="bplInfo.workingWeeklyTime"
          options={workingtime}
          getOptionLabel={(option) => option}
          value={workingtime.find((i) => i === values?.bplInfo?.workingWeeklyTime) || ""}
          getOptionSelected={(option, value) => {
            return option === value
          }}
          onChange={(e, value) => {
            setFieldValue("bplInfo.workingWeeklyTime", value)
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label={t(`bpl.workingWeeklyTime`, ``)}
              name="workingWeeklyTime"
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          autoComplete="off"
          style={{
            width: "100%"
          }}
          type="number"
          id={"bplInfo.workingPercentTime"}
          label={t(`bpl.workingPercentTime`, ``)}
          value={values?.bplInfo?.workingPercentTime}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.bplInfo?.workingPercentTime && errors.bplInfo?.workingPercentTime ? true : false}
          helperText={
            touched.bplInfo?.workingPercentTime && errors.bplInfo?.workingPercentTime
              ? errors.bplInfo?.workingPercentTime
              : null
          }
          variant="outlined"
        />
      </Grid>
      {(values?.manager === null ||
        (values?.manager !== null &&
          bplResult.status === 2 &&
          bplResult?.data?.managers.find((i) => i.id === values?.manager?.bplUserId) === undefined)) && (
        <>
          <Grid item xs={12}>
            <Box
              marginY={2}
              display="flex"
              justifyContent="flex-start"
              flexDirection="column"
              bgcolor={theme.palette.primary.light}
              borderRadius="8px"
              color={theme.palette.primary.dark}
              p={2}
              gap={1}>
              <Box fontWeight="600">
                <FontAwesomeIcon icon={faInfoCircle} size="1x" /> {t(`bpl.missing_match_manager`, ``)}
              </Box>
            </Box>

            <Autocomplete
              style={{
                width: "100%"
              }}
              disabled={bplResult.status !== 2}
              componentsProps={{ popper: { style: { width: "240px" } } }}
              id="bplinfo.managerCode"
              options={bplResult.status === 2 && bplResult.data.managers.sort((a, b) => a.namn.localeCompare(b.namn))}
              getOptionLabel={(option) => (option.namn ? option.namn : "")}
              value={bplResult?.data?.managers.find((i) => i.id === values?.bplInfo?.managerId) || ""}
              getOptionSelected={(option, value) => {
                return option.id === value.id
              }}
              onChange={(e, value) => {
                setFieldValue("bplInfo.managerId", value?.id)
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label={t(`manager`, ``)}
                  name="managerCode"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.bplInfo?.managerId && errors.bplInfo?.managerId ? true : false}
                  helperText={
                    touched.bplInfo?.managerId && errors.bplInfo?.managerId ? errors.bplInfo?.managerId : null
                  }
                />
              )}
            />
          </Grid>
        </>
      )}
    </>
  )
}
