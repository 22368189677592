import { faObjectIntersect } from "@fortawesome/pro-solid-svg-icons"
import { Autocomplete, Box, List, ListItem, ListItemText, TextField, Typography, Popper, styled } from "@mui/material"
import { hasRole } from "auth/hasRole"
import { useApi } from "auth/useApi3"
import { AppContext } from "components/AppContextProvider"
import Button from "components/Button"
import { CircleIcon } from "components/icons/CircleIcon"
import { RouterPrompt } from "components/RouterPrompt"
import CloseSidebarButton from "components/navigation/CloseSidebarButton"
import { Formik, useFormikContext } from "formik"
import { apiEndpoints } from "generated/apiEndpoints"
import { useContext, useEffect, useRef } from "react"
import * as Yup from "yup"
import { Sidebar } from "./Sidebar"
import { useStyles } from "./useStyles"

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Namn är obligatoriskt"),
  customFieldNames: Yup.array().min(1, "Minst ett fält måste väljas").required("Minst ett fält måste väljas"),
  vehiclesInfo: Yup.array().min(1, "Minst ett fordon måste väljas").required("Minst ett fordon måste väljas"),
})

const NameTextField = () => {
  const { errors, handleBlur, handleChange, touched, values } = useFormikContext()
  const classes = useStyles()

  return (
    <ListItem className={classes.listItem}>
      <Box className={classes.fullWidth}>
        <ListItemText
          classes={{
            secondary: classes.listItemText,
            primary: classes.listItemTextPrimary
          }}
          primary="Namn"
          style={{ paddingBottom: "8px" }}
        />
      </Box>
      <TextField
        autoComplete="off"
        className={classes.textField}
        fullWidth
        id="name"
        value={values.name}
        onChange={handleChange}
        onBlur={handleBlur}
        error={touched.name && errors.name ? true : false}
        helperText={touched.name && errors.name ? errors.name : null}
        variant="outlined"
        InputProps={{
          classes: { input: classes.formAreaInput },
          disableUnderline: true
        }}
      />
    </ListItem>
  )
}

const CustomFieldMultiselect = () => {
  const { errors, handleBlur, handleChange, setFieldValue, touched, values } = useFormikContext()
  const [customFieldsResult, getCustomFields] = useApi()
  const classes = useStyles()
  const customFieldNames = customFieldsResult.data

  useEffect(() => {
    getCustomFields.get(apiEndpoints.vehicleregistryadmin.listactivecustomfieldnames)
  }, [])

  return (
    <ListItem className={classes.listItem}>
      <Box className={classes.fullWidth}>
        <ListItemText
          classes={{
            secondary: classes.listItemText,
            primary: classes.listItemTextPrimary
          }}
          primary="Fält"
          style={{ paddingBottom: "8px" }}
        />
      </Box>
      <Autocomplete
        sx={{ width: "100%" }}
        multiple
        id="customFieldNames"
        options={customFieldNames || []}
        getOptionLabel={(option) => option.name}
        value={values.customFieldNames || []}
        getOptionSelected={(option, value) => {
          return option.name === value.name
        }}
        onChange={(e, value) => {
          setFieldValue("customFieldNames", value)
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label={("customFieldNames", "")}
            name="customFields"
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.customFieldNames && errors.customFieldNames ? true : false}
            helperText={touched.customFieldNames && errors.customFieldNames ? errors.customFieldNames : null}
          />
        )}
      />
    </ListItem>
  )
}

const StyledPopper = styled(Popper)({
  "& .MuiAutocomplete-paper": {
    maxHeight: "300px",
    overflowY: "auto"
  }
});

const VehicleMultiSelect = () => {
  const { errors, handleBlur, handleChange, setFieldValue, touched, values } = useFormikContext()
  const [vehiclesResult, vehiclesApi] = useApi()
  const classes = useStyles()

  useEffect(() => {
    vehiclesApi.get(apiEndpoints.vehicleregistry.getvehiclelist)
  }, [])

  return (
    <ListItem className={classes.listItem}>
      <Box className={classes.fullWidth}>
        <ListItemText
          classes={{
            secondary: classes.listItemText,
            primary: classes.listItemTextPrimary
          }}
          primary="Fordon"
          style={{ paddingBottom: "8px" }}
        />
      </Box>
      <Autocomplete
        sx={{
          width: "100%",
          maxHeight: '160px',
          overflowY: 'auto'
        }}
        multiple
        id="vehiclesInfo"
        filterSelectedOptions
        options={vehiclesResult.data || []}
        getOptionLabel={(option) => option.regNumber + " / " + option.internnumber}
        value={values.vehiclesInfo || []}
        isOptionEqualToValue={(option, value) => {
          return option.regNumber === value.regNumber
        }}
        noOptionsText="Inga fordon tillgängliga"
        onChange={(e, value) => {
          setFieldValue("vehiclesInfo", value)
        }}
        PopperComponent={StyledPopper}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label={("vehiclesInfo", "")}
            name="vehicleInfo"
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.vehiclesInfo && errors.vehiclesInfo ? true : false}
            helperText={touched.vehiclesInfo && errors.vehiclesInfo ? errors.vehiclesInfo : null}
          />
        )}
      />
      <Button
        color={values.vehiclesInfo.length > 0 ? "error" : "primary"}
        sx={{ marginTop: "20px", marginX: "auto" }}
        onClick={() => {
          if (values.vehiclesInfo.length > 0) {
            setFieldValue("vehiclesInfo", [])
            return
          }
          const allVehicles = vehiclesResult.data.map(vehicle => ({
            id: vehicle.id,
            regNumber: vehicle.regNumber,
            internnumber: vehicle.internnumber
          }))
          setFieldValue("vehiclesInfo", allVehicles)
        }}
      >
        {values.vehiclesInfo.length > 0 ? "Ta bort alla fordon" : "Lägg till alla fordon"}
      </Button>
    </ListItem>
  )
}

const CustomFieldGroupForm = (props) => {
  const appContext = useContext(AppContext)
  const isAdmin = hasRole(appContext.appUser)

  const {
    showSidebar,
    setShowSidebarForm,
    editItemResult,
    addResult,
    updateResult,
    editMode,
    onAddSubmit,
    onUpdateSubmit
  } = props
  const [usersResult, getUsers] = useApi()
  useEffect(() => {
    getUsers.get(apiEndpoints.vehicleregistryadmin.getuserstoassign)
  }, [])
  const classes = useStyles()
  const formRef = useRef()

  const initialValues =
    editMode && editItemResult.status === 2
      ? {
          ...editItemResult.data
        }
      : {
          id: 0,
          name: "",
          customFieldNames: [],
          vehiclesInfo: []
        }

  const handleSubmit = (formikValues) => {
    let filteredValues = { ...formikValues }

    if (editMode) {
      onUpdateSubmit(filteredValues)
    } else {
      onAddSubmit(filteredValues)
    }
  }

  useEffect(() => {
    if (!showSidebar) {
      formRef.current.resetForm()
    }
  }, [showSidebar])

  const buttonFeedbackResult = editMode ? updateResult : addResult

  return (
    <Sidebar showSidebar={showSidebar}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
        innerRef={formRef}>
        {({ values, handleSubmit, }) => (
          <form style={{ height: "97%" }} id="CustomFieldForm" onSubmit={handleSubmit} noValidate>
            <RouterPrompt />
            <List sx={{ display: "flex", flexDirection: "column", height: "100%", margin: "0 26px" }}>
              <CloseSidebarButton
                position={{ top: "8px", left: "-23px" }}
                toggleMenu={() => {
                  setShowSidebarForm(!showSidebar)
                }}
              />
              <ListItem sx={{ borderBottom: "1px solid #e0e0e0" }}>
                <Box pt={5} pb={3} display="flex" alignItems="center" justifyContent="space-between">
                  <Box display="flex" alignItems="center">
                    <CircleIcon
                      icon={faObjectIntersect}
                      color="primary"
                      size="lg"
                      sx={{ marginRight: "15px" }}
                    />
                    <Box justifyContent="center">
                      <ListItemText
                        primary={
                          <Typography color="textPrimary" variant="h2">
                            {values.id < 1 ? "Lägg till ny grupp" : "Redigera grupp"}
                          </Typography>
                        }
                        secondary={
                          <Typography color="textSecondary" variant="bodySmall">
                            {values.id < 1 ? "Skapa grupp" : "Redigera grupp"}
                          </Typography>
                        }
                      />
                    </Box>
                  </Box>
                </Box>
              </ListItem>
              <NameTextField />
              <CustomFieldMultiselect />
              <VehicleMultiSelect />
              <Box sx={{ marginTop: "Auto", marginBottom: "34px", width: "100%" }}>
                <ListItem sx={{ flexDirection: "column", gap: "14px" }}>
                  <Button style={{ width: "100%" }} color="primary" variant="contained" type="submit" result={buttonFeedbackResult}>
                    {editMode ? "Spara grupp" : "Lägg till grupp"}
                  </Button>
                  <Button
                    sx={{ width: "100%", margin: "0 10px" }}
                    variant="outlined"
                    onClick={() => {
                      setShowSidebarForm(false)
                    }}>
                    Avbryt
                  </Button>
                </ListItem>
              </Box>
            </List>
          </form>
        )}
      </Formik>
    </Sidebar>
  )
}

export default CustomFieldGroupForm
