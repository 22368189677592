import styled from "@emotion/styled"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { faFolder, faGlobe, faUserLock } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, Grid, IconButton, Stack, Tooltip, Typography } from "@mui/material"
import { IApiFunc, IApiResult } from "auth/interface/api.interface"
import { useApi } from "auth/useApi"
import { AppContext } from "components/AppContextProvider"
import theme from "css/theme/light"
import { apiEndpoints } from "generated/apiEndpoints"
import { allRoutes } from "generated/routes"
import { IDocumentFolder } from "pages/documents/interface/folder.interface"
import { useContext, useEffect } from "react"
import { useTranslation } from "react-i18next"
import Moment from "react-moment"
import { NavLink } from "react-router-dom"
import LoadingDocuments from "./LoadingDocuments"

export const StyledNavLink = styled(NavLink)({
  display: "block",
  borderRadius: 4,
  padding: "8px 15px",
  background: theme.palette.background.paper,
  "&.active": {
    background: theme.palette.primary["light"],
    borderColor: theme.palette.primary["dark"]
  }
})

const Folder = () => {
  const { t } = useTranslation("translation", { keyPrefix: "documents" })
  const appContext = useContext(AppContext)
  const [foldersResult, listFoldersApi] = useApi() as [IApiResult, IApiFunc]

  useEffect(() => {
    listFoldersApi.get(
      apiEndpoints.documents.listfoldersforstartpage.replace("{companyId}", appContext.currentCompanyId)
    )
  }, [listFoldersApi, appContext.currentCompanyId])

  return (
    <>
      {foldersResult.status === 2 ? (
        <>
          <Box marginTop={3} marginBottom={2}>
            <Typography variant="h5"> {t(`title`)}</Typography>
          </Box>

          {foldersResult.status === 2 &&
            foldersResult.data.length > 0 &&
            foldersResult.data.map((folder: IDocumentFolder, index: number) => (
              <Box gap={2} mb={1}>
                <StyledNavLink
                  style={{ color: "inherit", textDecoration: "inherit" }}
                  to={allRoutes.DocumentsDetails(folder.id)}
                  isActive={(match, location): any => {
                    if (!match) {
                      return false
                    }
                    return true
                  }}>
                  <Grid container direction="row" spacing={1} justifyContent="space-between">
                    <Grid item xs="auto">
                      <FontAwesomeIcon
                        size="xs"
                        color={theme.palette.warning["main"]}
                        icon={faFolder as IconProp}
                      />
                    </Grid>
                    <Grid item xs>
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <Typography display="inline-block" variant="bodyMedium">
                          {folder.name}
                        </Typography>
                      </Stack>
                      <Grid container direction="row" alignItems="center" spacing={1}>
                        <Grid item>
                          {folder.permissions !== undefined && folder.permissions?.length > 0 ? (
                            <Tooltip title={folder?.permissions?.map((x: string) => x).join(", ")}>
                              <IconButton color="success" size="small" sx={{ padding: 0 }}>
                                <FontAwesomeIcon
                                  size="xs"
                                  color={theme.palette.grey[500]}
                                  icon={faUserLock as IconProp}
                                />
                              </IconButton>
                            </Tooltip>
                          ) : (
                            <Tooltip title="Delat med alla">
                              <IconButton color="success" size="small" sx={{ padding: 0 }}>
                                <FontAwesomeIcon
                                  size="xs"
                                  color={theme.palette.grey[500]}
                                  icon={faGlobe as IconProp}
                                />
                              </IconButton>
                            </Tooltip>
                          )}
                        </Grid>
                        <Grid item>
                          <Typography variant="bodyXsmall" color="textSecondary">
                            |
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="bodyXsmall" color="textSecondary">
                            {folder.totalAttachments}{" "}
                            {folder.totalAttachments === 1 ? t(`list.file`) : t(`list.files`)}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="bodyXsmall" color="textSecondary">
                            |
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="bodyXsmall" color="textSecondary">
                            Uppdaterad{" "}
                            <Moment utc local format="YYYY-MM-DD">
                              {folder.updatedDate}
                            </Moment>
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="bodyXsmall" color="textSecondary">
                            |
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="bodyXsmall" color="textSecondary">
                            {folder.createdByUser?.name}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </StyledNavLink>
              </Box>
            ))}
        </>
      ) : (
        <LoadingDocuments />
      )}
    </>
  )
}
export default Folder
