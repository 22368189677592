import { faCalendarCheck, faCalendarClock, faCalendarRange } from "@fortawesome/pro-solid-svg-icons"
import { Alert } from "@mui/lab"
import {
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Hidden,
  Typography
} from "@mui/material"
import { hasRole } from "auth/hasRole"
import { useApiGet, useApiPost } from "auth/useApi2"
import BplTokenLink from "components/BplTokenLink"
import Button from "components/Button"
import { GlobalLoaderContext } from "components/GlobalLoadingProvider"
import globalUseStyles from "css/theme/globalUseStyles.js"
import { apiEndpoints } from "generated/apiEndpoints"
import { allRoles } from "generated/roles"
import moment from "moment"

import CrisisButton from "components/navigation/CrisisButton"
import ActionCardDetailText from "pages/planning/components/ActionCardDetailText"
import ActionCardDetails from "pages/planning/components/ActionCardDetails"
import { ActionCardLoading } from "pages/planning/components/ActionCardLoading"
import { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { AppContext } from "../../components/AppContextProvider"
import Folder from "./components/Documents/Folder"
import LearningAdminSummary from "./components/Learning/AdminSummary"
import LearningAvailableExams from "./components/Learning/AvailableExams"
import News from "./components/News"
import Welcome from "./components/Welcome"
import AnonymousUserMessage from "./components/Welcome/AnonymousUserMessage"
const DisabledUserMessage = () => {
  return (
    <Grid item xs={12}>
      <Alert severity="error">
        Ditt konto är inaktiverat i Transportportalen. Kontaka administratör för åtkomst.
      </Alert>
    </Grid>
  )
}

const AssignmentButton = ({ currentAssignmentResult, toggleDialogAssignment }) => {
  if (currentAssignmentResult && currentAssignmentResult.data) {
    if (currentAssignmentResult.data?.canBekrafta && !currentAssignmentResult.data?.bekraftad) {
      return (
        <Button onClick={toggleDialogAssignment} color="primary" variant="contained">
          Bekräfta
        </Button>
      )
    } else if (currentAssignmentResult.data?.canBekrafta && currentAssignmentResult.data?.bekraftad) {
      return (
        <Button onClick={toggleDialogAssignment} color="error" variant="contained">
          Ångra bekräftad
        </Button>
      )
    } else {
      return (
        <Typography fontWeight={600}>
          {currentAssignmentResult.data.bekraftad ? "Bekräftad" : null}
        </Typography>
      )
    }
  }
  return null
}

const Dashboard = () => {
  const { t } = useTranslation()

  const appContext = useContext(AppContext)

  const globalClasses = globalUseStyles()
  const loadingContext = useContext(GlobalLoaderContext)
  const [currentAssignmentResult, getCurrentAssignment] = useApiGet()
  const [timereportResult, getTimereport] = useApiGet()
  const [assignmentResult, getAssignment] = useApiGet()
  const [updateAssignmentResult, acceptAssignmentFunc] = useApiPost()
  const [updateTimeReportResult, acceptTimeReportFunc] = useApiPost()

  const [currentDate, setDate] = useState(moment().format("YYYY-MM-DD"))

  const [updatedAssignment, setUpdatedAssignment] = useState()

  const [updatedTimeReport, setUpdatedTimeReport] = useState()

  useEffect(() => {
    hasRole(appContext.appUser, allRoles.Planning) &&
      getCurrentAssignment(apiEndpoints.planning.getcurrentassignment)
  }, [appContext.appUser])

  useEffect(() => {
    hasRole(appContext.appUser, allRoles.Planning) &&
      getTimereport(apiEndpoints.planning.getcurrenttimereport)
  }, [appContext.appUser])

  useEffect(() => {
    loadingContext.setLoading(() => assignmentResult.status === 1)
    loadingContext.setLoading(() => currentAssignmentResult.status === 1)
  })

  useEffect(() => {
    if (updateTimeReportResult.status === 2) {
      hasRole(appContext.appUser, allRoles.Planning) &&
        getTimereport(apiEndpoints.planning.getcurrenttimereport)
      toggleDialogTimeReport()
    }
  }, [updateTimeReportResult.status])

  useEffect(() => {
    if (updateAssignmentResult.status === 2) {
      hasRole(appContext.appUser, allRoles.Planning) &&
        getCurrentAssignment(apiEndpoints.planning.getcurrentassignment)
      toggleDialogAssignment()
    }
  }, [updateAssignmentResult.status])

  useEffect(() => {
    setUpdatedAssignment(moment().format("YYYY-MM-DD HH:mm"))
  }, [currentAssignmentResult])

  useEffect(() => {
    setUpdatedTimeReport(moment().format("YYYY-MM-DD HH:mm"))
  }, [timereportResult])

  const acceptAssignment = () => {
    const values = {
      assignmentId: currentAssignmentResult?.data?.passStartSchemapostId,
      undo: currentAssignmentResult?.data?.bekraftad
    }
    acceptAssignmentFunc(apiEndpoints.planning.acceptassignment, values)
  }

  const [dialogAssignmentOpen, setDialogAssignmentOpen] = useState(false)

  const toggleDialogAssignment = () => {
    setDialogAssignmentOpen(!dialogAssignmentOpen)
  }

  const acceptTimeReport = () => {
    const values = {
      timeReportId: timereportResult?.data?.id,
      undo: timereportResult?.data?.isSubmitted
    }
    acceptTimeReportFunc(apiEndpoints.planning.accepttidrapport, values)
  }

  const [dialogTimeReportOpen, setDialogTimeReportOpen] = useState(false)

  const toggleDialogTimeReport = () => {
    setDialogTimeReportOpen(!dialogTimeReportOpen)
  }

  return (
    <>
      <Container>
        {appContext.appUser.profile.disabled ? (
          <DisabledUserMessage />
        ) : (
          <>
            <Grid container direction="row" justifyContent="flex-start" spacing={2}>
              <Grid item xs={12}>
                <Hidden mdUp>
                  <CrisisButton />
                </Hidden>
              </Grid>
              <Grid item sm={9}>
                {appContext.appUser.isAnonymous ? <AnonymousUserMessage /> : <Welcome />}
              </Grid>
            </Grid>
            {hasRole(appContext.appUser, allRoles.Planning) && (
              <Grid container direction="row" justifyContent="flex-start" spacing={2}>
                <Grid item mt={4}>
                  <Typography variant="h5" mb={0}>
                    {t("global.titles.planning")}
                  </Typography>
                </Grid>
                <Grid item md={12}>
                  <Grid container direction="row" justifyContent="flex-start" spacing={2}>
                    <Grid item md={6}>
                      {currentAssignmentResult.status === 2 ? (
                        <ActionCardDetails
                          bgClassName={
                            currentAssignmentResult.data.bekraftad === true
                              ? globalClasses.backgroundSuccess
                              : globalClasses.backgroundPrimary
                          }
                          approved={currentAssignmentResult.data?.bekraftad}
                          sx={{ marginTop: "0" }}
                          title={
                            currentAssignmentResult.data?.bekraftad === true
                              ? "Bekräftat uppdrag"
                              : "Aktuellt uppdrag"
                          }
                          icon={
                            currentAssignmentResult.data?.bekraftad === true
                              ? faCalendarCheck
                              : faCalendarRange
                          }
                          subText={
                            !currentAssignmentResult.data.stationsort
                              ? "Du har inget aktuellt uppdrag just nu"
                              : null
                          }
                          result={updateAssignmentResult}
                          details={
                            currentAssignmentResult.data.stationsort ? (
                              <Grid container direction="row">
                                <ActionCardDetailText
                                  title="Datum"
                                  data={moment(currentAssignmentResult.data.passStarttid).format(
                                    "YYYY-MM-DD"
                                  )}
                                />
                                <ActionCardDetailText
                                  title="Starttid"
                                  data={
                                    currentAssignmentResult.data.starttidsträng
                                      ? currentAssignmentResult.data.starttidsträng
                                      : null
                                  }
                                />
                                <ActionCardDetailText
                                  title="Tjänst"
                                  data={currentAssignmentResult.data.beskrivning}
                                />
                                <ActionCardDetailText
                                  title="Fordon"
                                  data={currentAssignmentResult.data.fordonStr}
                                />
                              </Grid>
                            ) : null
                          }
                          footer={
                            <>
                              <Grid
                                container
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                spacing={3}>
                                <Grid item>
                                  <BplTokenLink
                                    label="Förartavla"
                                    to="client/forartavla"
                                    variant="outlined"
                                    color="primary"
                                    sx={{ backgroundColor: "#fff" }}
                                  />
                                </Grid>
                                <Grid item>
                                  <AssignmentButton
                                    currentAssignmentResult={currentAssignmentResult}
                                    toggleDialogAssignment={toggleDialogAssignment}
                                  />
                                </Grid>
                              </Grid>
                            </>
                          }></ActionCardDetails>
                      ) : (
                        <ActionCardLoading />
                      )}
                    </Grid>
                    <Grid item md={6}>
                      {timereportResult.status === 2 ? (
                        <ActionCardDetails
                          sx={{ marginTop: "0" }}
                          title="Tidrapport"
                          icon={faCalendarClock}
                          subText={
                            timereportResult.data.passCount < 1 ? "Du har inget aktuell pass just nu" : null
                          }
                          result={updateTimeReportResult}
                          chipText={timereportResult.data?.status}
                          details={
                            timereportResult.data.passCount > 0 ? (
                              <Grid container direction="row">
                                <ActionCardDetailText
                                  title="Antal pass"
                                  data={
                                    timereportResult.data.passCount
                                      ? timereportResult.data.passCount + " st "
                                      : "0"
                                  }
                                />
                                <ActionCardDetailText
                                  title="Period"
                                  data={
                                    timereportResult.data.period
                                      ? moment(timereportResult.data.period).format("MMMM YYYY")
                                      : "-"
                                  }
                                />
                              </Grid>
                            ) : null
                          }
                          footer={
                            <>
                              <Grid
                                container
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                spacing={3}>
                                <Grid item>
                                  <BplTokenLink
                                    label="Visa tidrapport"
                                    to="Client/Tidrapport/"
                                    variant="outlined"
                                    color="primary"
                                  />
                                </Grid>
                                <Grid item>
                                  {timereportResult &&
                                  timereportResult.data &&
                                  (!timereportResult.data?.isApproved ||
                                    timereportResult.data?.isRevertable) ? (
                                    <Button
                                      onClick={toggleDialogTimeReport}
                                      color={
                                        !timereportResult.data?.isApproved &&
                                        !timereportResult.data?.isRevertable
                                          ? "primary"
                                          : "error"
                                      }>
                                      {!timereportResult.data?.isApproved &&
                                      !timereportResult.data?.isRevertable
                                        ? "Lämna in " + timereportResult.data.passCount + " pass"
                                        : "Ångra inlämning"}
                                    </Button>
                                  ) : (
                                    <Typography fontWeight={600}>Inlämnad</Typography>
                                  )}
                                </Grid>
                              </Grid>
                            </>
                          }
                        />
                      ) : (
                        <ActionCardLoading />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
            <Grid container direction="row" spacing={2}>
              <Grid item xs={12} md={8}>
                <News />
              </Grid>
              <Grid item xs={12} md={4}>
                <Folder />
              </Grid>
            </Grid>
          </>
        )}

        <Grid container direction="row" spacing={2}>
          {hasRole(appContext.appUser, allRoles.Learning) && (
            <Grid item xs={12} md={6}>
              <Typography variant="h5"> {t("global.titles.myCourses")}</Typography>
              <LearningAvailableExams />
            </Grid>
          )}
          {hasRole(appContext.appUser, allRoles.LearningAdmin) && (
            <Grid item xs={12} md={6}>
              <LearningAdminSummary />
            </Grid>
          )}
        </Grid>
        <Dialog
          open={dialogTimeReportOpen}
          onClose={toggleDialogTimeReport}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">
            {!timereportResult.data?.isApproved && !timereportResult.data?.isRevertable
              ? "Vill du lämna in tidrapport?"
              : "Är du säker att du vill återställa tidrapport?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {!timereportResult.data?.isApproved && !timereportResult.data?.isRevertable
                ? "Vill du lämna in tidrapporten för godkännande?"
                : "Vill du återställa tidrapporten till aktiv status?"}{" "}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={toggleDialogTimeReport} variant="outlined">
              Avbryt
            </Button>
            <Button result={updateTimeReportResult} onClick={acceptTimeReport} color="primary" autoFocus>
              {!timereportResult.data?.isApproved && !timereportResult.data?.isRevertable
                ? "Lämna in tidrapport"
                : "Ångra tidrapport"}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={dialogAssignmentOpen}
          onClose={toggleDialogAssignment}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">
            {currentAssignmentResult.data?.canBekrafta && !currentAssignmentResult.data?.bekraftad
              ? "Bekräfta uppdrag"
              : "Återställ uppdrag"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {currentAssignmentResult.data?.canBekrafta && !currentAssignmentResult.data?.bekraftad
                ? "Vill du bekräfta uppdrag?"
                : "Vill du återställa uppdrag?"}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={toggleDialogAssignment} variant="outlined">
              Avbryt
            </Button>
            <Button result={updateAssignmentResult} onClick={acceptAssignment} color="primary" autoFocus>
              {currentAssignmentResult.data?.canBekrafta && !currentAssignmentResult.data?.bekraftad
                ? "Bekräfta"
                : "Ångra bekräfta"}
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </>
  )
}
export default Dashboard
