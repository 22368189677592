import { faSquarePlus } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Grid, IconButton, Typography } from "@mui/material"
import moment from "moment"
import { useState } from "react"
import { useStyles } from "./useStyles"

const ReadMoreReadLess = ({ description, date }) => {
  const classes = useStyles()
  const [isReadMoreShown, setReadMoreShown] = useState(false)
  const isDescriptionLong = description.length > 33

  const ToggleBtn = () => {
    setReadMoreShown((prevState) => !prevState)
  }

  return (
    <Grid container mt={1} justifyContent="space-between" spacing={1} alignItems="start" mb={2}>
      {isDescriptionLong && (
        <Grid item md={1} xs={1} style={{ paddingTop: "0px" }}>
          <IconButton
            disabled={!isDescriptionLong}
            className={classes.readMoreIcon}
            color="primary"
            onClick={ToggleBtn}>
            <FontAwesomeIcon
              icon={faSquarePlus}
              size="small"
              color={isDescriptionLong ? "primary" : "lightblue"}
            />
          </IconButton>
        </Grid>
      )}
      <Grid item md={7} xs={7} style={{ paddingTop: "0px" }}>
        <Typography
          sx={{ overflowWrap: "break-word" }}
          color={!isDescriptionLong || isReadMoreShown ? "textPrimary" : "textSecondary"}
          variant="bodySmall">
          {!isDescriptionLong || isReadMoreShown ? description : `${description.substr(0, 33)}...`}
        </Typography>
      </Grid>
      <Grid item md={4} xs={4} style={{ paddingTop: "0px" }} justifyContent="flex-end" display="flex">
        <Typography fontWeight={600} variant="bodySmall">
          {date && moment(date).format("YYYY-MM-DD")}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default ReadMoreReadLess
