import React, { useEffect, useState } from "react"
import { apiEndpoints } from "generated/apiEndpoints"
import { useApi } from "auth/useApi3"
import { IApiFunc, IApiResult } from "auth/interface/api.interface"
import { Sidebar } from "pages/vehicleregistry/pages/vehicles/pages/customfields/components/Sidebar"
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
  useTheme
} from "@mui/material"
import { Formik, FormikErrors } from "formik"
import { RouterPrompt } from "components/RouterPrompt"
import CloseSidebarButton from "components/navigation/CloseSidebarButton"
import { CircleIcon } from "components/icons/CircleIcon"
import { faPenField, faTrashCan } from "@fortawesome/pro-solid-svg-icons"
import { StyledListItemText } from "shared/vehicle/InspectionService.styled"
import { CustomFieldCategory, UserCustomFieldSidebarProps } from "./userCustomField.interfaces"
import { userCustomFieldValidationSchema } from "./userCustomFieldValidationSchema"
import { StyledDivError } from "./userCustomFields.styled"
import SubmitButton from "components/Button"
import { t } from "i18next"
import { UserCustomFieldFormik, UserCustomFieldValueFieldFormik } from "../edit/UserCustomField.interfaces"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const initialFormikValues: UserCustomFieldFormik = {
  id: 0,
  name: "",
  userCustomFieldCategoryId: 0,
  valueFields: [{ name: "", type: "", isRequired: false }],
  active: true
}

interface UserCustomFieldOption {
  name: string,
  id: string
}

const userCustomFieldTypesOption: UserCustomFieldOption[] = [
  { name: "Nummer", id: "Numeric" },
  { name: "Datum", id: "Date" },
  { name: "Textfält", id: "Text" },
  { name: "Ja/Nej", id: "Boolean" },
  { name: "Fil", id: "File" },
]

export const UserCustomFieldSidebar = ({ showSidebar, setShowSidebar, clickedUserCustomField, handleSubmit }: UserCustomFieldSidebarProps) => {
  const [customerResult, customerApi] = useApi() as [IApiResult, IApiFunc]
  const [customFieldResult, customFieldApi] = useApi() as [IApiResult, IApiFunc]
  const [categoryResult, categoryApi] = useApi() as [IApiResult, IApiFunc]
  const [formikValues, setFormikValues] = useState<UserCustomFieldFormik>(initialFormikValues)
  const formRef = React.useRef<any>()
  const theme = useTheme()

  useEffect(() => {
    customerApi.get(apiEndpoints.customeradmin.getcustomer)
  }, [])

  useEffect(() => {
    if (!showSidebar) return
    const url = apiEndpoints.usercustomfieldsadmin.getactivecustomfieldcategoriesbycustomerid
    categoryApi.get(url)
  }, [showSidebar])

  useEffect(() => {
    if (customFieldResult.status !== 2) return
    setShowSidebar(false)
    handleSubmit()
  }, [customFieldResult])

  useEffect(() => {
    if (showSidebar) return
    setFormikValues(initialFormikValues)
    formRef.current.resetForm()
  }, [showSidebar])

  useEffect(() => {
    if (!clickedUserCustomField) return
    const userCustomFieldCategoryId = clickedUserCustomField.category.active
      ? clickedUserCustomField.category.id
      : -1
    setFormikValues({
      id: clickedUserCustomField.id,
      name: clickedUserCustomField.name,
      active: clickedUserCustomField.active,
      valueFields: clickedUserCustomField.valueFields.map(valueField => ({
        id: valueField.id,
        name: valueField.name,
        type: valueField.type,
        isRequired: valueField.isRequired
      })),
      userCustomFieldCategoryId: userCustomFieldCategoryId
    })
  }, [clickedUserCustomField])

  const handleFormikSubmit = (formikValues: UserCustomFieldFormik) => {
    const userCustomFieldData: Omit<UserCustomFieldFormik, "id"> = {
      name: formikValues.name,
      valueFields: formikValues.valueFields,
      active: formikValues.active,
      userCustomFieldCategoryId: formikValues.userCustomFieldCategoryId,
    }
    if (clickedUserCustomField) {
      const url = apiEndpoints.usercustomfieldsadmin.updateusercustomfield
      customFieldApi.put(url, { id: clickedUserCustomField.id, ...userCustomFieldData })
    } else {
      const url = apiEndpoints.usercustomfieldsadmin.createusercustomfield
      customFieldApi.post(url, userCustomFieldData)
    }
  }

  const activeCategories: CustomFieldCategory[] = categoryResult.status === 2 && categoryResult.data ? categoryResult.data : [];
  const categoriesWithInactive: CustomFieldCategory[] = clickedUserCustomField && !activeCategories.find((category: CustomFieldCategory) => category.id === clickedUserCustomField.category.id)
    ? [...activeCategories, { id: -1, name: `${clickedUserCustomField.category.name} (Inaktiv)`, customerId: customerResult.data.id, active: false }]
    : activeCategories;

  return (
    <Sidebar showSidebar={showSidebar}>
      <Box sx={{ height: "calc(100% - 48px)", margin: "0 20px 0 20px" }}>
        <Formik
          innerRef={formRef}
          initialValues={formikValues}
          validationSchema={userCustomFieldValidationSchema}
          onSubmit={(values) => {
            handleFormikSubmit(values)
          }}
          enableReinitialize={true}>
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            setFieldValue,
            handleBlur,
            isValid,
            dirty,
            setFieldTouched
          }) => (
            <form style={{ height: "100%", position: "relative" }} id="UserCustomFieldForm" onSubmit={handleSubmit} noValidate>
              <RouterPrompt />
              <CloseSidebarButton
                position={{ top: "8px", left: "-23px" }}
                toggleMenu={() => {
                  setShowSidebar(!showSidebar)
                }}
              />
              <List sx={{ height: "100%", paddingBottom: "200px", overflowY: "auto" }}>
                <Box style={{ position: "absolute", left: "-20px" }}>
                </Box>
                <ListItem sx={{ borderBottom: "1px solid #e0e0e0" }}>
                  <Box pt={5} pb={3} display="flex" alignItems="center" justifyContent="space-between">
                    <Box display="flex" alignItems="center">
                      <CircleIcon
                        icon={faPenField}
                        color="primary"
                        size="lg"
                        sx={{ marginRight: "15px" }}
                      />
                      <Box justifyContent="center">
                        <ListItemText
                          primary={
                            <Typography color="textPrimary" variant="h2">
                              {clickedUserCustomField ? "Redigera " + clickedUserCustomField.name : "Skapa eget fält"}
                            </Typography>
                          }
                          secondary={
                            <Typography color="textSecondary" variant="bodyMedium">
                              {clickedUserCustomField ? "Redigera " + clickedUserCustomField.name : "Skapa ett nytt eget fält"}
                            </Typography>
                          }
                        />
                      </Box>
                    </Box>
                  </Box>
                </ListItem>
                <ListItem>
                  <StyledListItemText
                    primary={
                      <InputLabel>
                        <Typography variant="h6" color="black">
                          Namn
                        </Typography>
                      </InputLabel>
                    }
                    secondary={
                      <TextField
                        placeholder="Ange namn"
                        autoComplete="off"
                        fullWidth
                        type="text"
                        id="name"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.name && errors.name ? true : false}
                        helperText={touched.name && errors.name ? errors.name : null}
                        variant="outlined"
                      />
                    }
                  />
                </ListItem>
                <ListItem sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
                  {values.valueFields && values.valueFields.length > 0 && values.valueFields.map((_, index) => (
                    <>
                      <ListItemText sx={{ width: "100%" }}
                        primary={
                          <InputLabel>
                            <Typography variant="h6" color="black">
                              Fälttyp {values.valueFields.length > 1 ? index + 1 : ""}
                            </Typography>
                          </InputLabel>
                        }
                        secondary={
                          <TextField
                            placeholder="Ange namn på fält"
                            autoComplete="off"
                            fullWidth
                            type="text"
                            id={`valueFields[${index}].name`}
                            value={values.valueFields[index]?.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={Array.isArray(touched.valueFields)
                              && touched.valueFields[index]
                              && touched.valueFields[index].name
                              && Array.isArray(errors.valueFields)
                              && errors.valueFields[index]
                              && (errors.valueFields[index] as FormikErrors<UserCustomFieldValueFieldFormik>).name ? true : false}
                            helperText={Array.isArray(touched.valueFields)
                              && touched.valueFields[index]
                              && touched.valueFields[index].name
                              && Array.isArray(errors.valueFields)
                              && errors.valueFields[index] ? (errors.valueFields[index] as FormikErrors<UserCustomFieldValueFieldFormik>).name : null}
                            variant="outlined"
                          />
                        }
                      />
                      <ListItemText sx={{ width: "100%" }}
                        secondary={
                          <Grid container alignItems="flex-start">
                            <Select
                              fullWidth
                              displayEmpty
                              disabled={values.valueFields[index]?.id !== undefined}
                              name={values.valueFields[index]?.type}
                              value={values.valueFields[index]?.type || ""}
                              onChange={(event) => {
                                setFieldValue(`valueFields[${index}].type`, event.target.value)
                              }}
                              onBlur={handleBlur}
                              error={Array.isArray(touched.valueFields)
                                && touched.valueFields[index]
                                && touched.valueFields[index].type
                                && Array.isArray(errors.valueFields)
                                && errors.valueFields[index]
                                && (errors.valueFields[index] as FormikErrors<UserCustomFieldValueFieldFormik>).type ? true : false}
                              sx={{
                                color: values.valueFields[index]?.type === "" ? theme.palette.grey["500"] : "inherit"
                              }}
                            >
                              {values.valueFields[index]?.type === "" &&
                                <MenuItem value="" disabled>
                                  Välj typ
                                </MenuItem>
                              }
                              {userCustomFieldTypesOption.map((type) => (
                                <MenuItem key={type.id} value={type.id}>
                                  {type.name}
                                </MenuItem>
                              ))}
                            </Select>
                            {Array.isArray(touched.valueFields)
                              && touched.valueFields[index]
                              && touched.valueFields[index].type
                              && Array.isArray(errors.valueFields)
                              && errors.valueFields[index]
                              && (errors.valueFields[index] as FormikErrors<UserCustomFieldValueFieldFormik>).type
                              && (
                              <StyledDivError>
                                {(errors.valueFields[index] as FormikErrors<UserCustomFieldValueFieldFormik>).type}
                              </StyledDivError>
                            )}
                            <Grid container justifyContent="space-between" alignItems="center">
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={values.valueFields[index]?.isRequired}
                                    onChange={(event) => setFieldValue(`valueFields[${index}].isRequired`, event.target.checked)}
                                  />
                                }
                                label="Visa som obligatorisk"
                              />
                              {index > 0 && !clickedUserCustomField && (
                                <FontAwesomeIcon
                                  size="lg"
                                  icon={faTrashCan}
                                  cursor="pointer"
                                  onClick={() => {
                                    const updatedValueFields = values.valueFields.filter((_, i) => i !== index)
                                    setFieldValue(`valueFields`, updatedValueFields)
                                  }}
                                />
                              )}
                            </Grid>
                            {touched.valueFields && errors.valueFields && (
                              <StyledDivError>
                                {errors.valueFields[index] && typeof errors.valueFields[index] === "string" ? errors.valueFields[index] : null}
                              </StyledDivError>
                            )}
                          </Grid>
                        }
                      />
                    </>
                  ))}
                </ListItem>
                {!clickedUserCustomField && values.valueFields.every((valueField) => valueField.type !== "" && valueField.name !== "") && (
                  <ListItem>
                    <Typography
                      onClick={() => {
                        setFieldValue(`valueFields`, [...values.valueFields, { name: "", type: "", isRequired: false }])
                      }}
                      color="primary"
                      sx={{ cursor: "pointer" }}
                    >
                      + Skapa en till fälttyp
                    </Typography>
                  </ListItem>
                )}
                {errors.valueFields && typeof errors.valueFields === "string" && (
                  <ListItem>
                    <StyledDivError>
                      {errors.valueFields}
                    </StyledDivError>
                  </ListItem>
                )}
                <ListItem>
                  <ListItemText
                    primary={
                      <InputLabel>
                        <Typography variant="h6" color="black">
                          Kategori
                        </Typography>
                      </InputLabel>
                    }
                    secondary={
                      <>
                        <Select
                          fullWidth
                          name="userCustomFieldCategoryId"
                          value={values.userCustomFieldCategoryId}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.userCustomFieldCategoryId && errors.userCustomFieldCategoryId ? true : false}
                          sx={{
                            color: values.userCustomFieldCategoryId === -1 ? theme.palette.grey["500"] : "inherit"
                          }}
                        >
                          {categoriesWithInactive.map((category: CustomFieldCategory) => (
                            <MenuItem key={category.id} value={category.id} disabled={category.id === -1}>
                              {category.name}
                            </MenuItem>
                          ))}
                        </Select>
                        {touched.userCustomFieldCategoryId && errors.userCustomFieldCategoryId && (
                          <StyledDivError>
                            {errors.userCustomFieldCategoryId}
                          </StyledDivError>
                        )}
                      </>
                    }
                  />
                </ListItem>
              </List>
              <Box
                sx={{
                  position: "absolute",
                  bottom: 0,
                  marginBottom: "14px",
                  width: "100%",
                  borderTop: "1px solid #e0e0e0",
                  backgroundColor: theme.palette.primary.contrastText
                }}
              >
                <ListItem>
                  <FormControlLabel
                    control={
                      <Switch
                        onChange={(event) => {
                          setFieldValue("active", event.target.checked)
                        }}
                        checked={values.active}
                      />
                    }
                    label="Aktiv"
                  />
                </ListItem>
                <ListItem sx={{ flexDirection: "column", gap: "14px" }}>
                  <SubmitButton
                    result={customFieldResult}
                    sx={{ width: "100%", margin: "0 10px" }}
                    type="button"
                    disabled={!isValid || !dirty}
                    variant="contained"
                    onClick={() => {
                      if (!dirty) {
                        Object.keys(values).forEach(valueName => {
                          setFieldTouched(valueName, true)
                        })
                        return
                      }
                      if (!isValid) {
                        Object.keys(values).forEach(valueName => {
                          setFieldTouched(valueName, true)
                        })
                        return
                      }
                      handleSubmit()
                    }}
                  >
                    Spara
                  </SubmitButton>
                  <Button
                    sx={{ width: "100%", margin: "0 10px" }}
                    type="button"
                    variant="outlined"
                    onClick={() => {
                      setShowSidebar(false)
                    }}>
                    {t("global.buttons.cancel")}
                  </Button>
                </ListItem>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </Sidebar>
  )
}