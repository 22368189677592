import { Container, Grid, Typography } from "@mui/material"
import { TabPanel } from "components/navigation/TabPanel"
import PaperBox from "components/PaperBox"
import SubTabs from "components/SubTabs"
import { useState } from "react"
import CustomFieldGroups from "./components/customFieldGroup"
import CustomFields from "./components/customFields"

const tabData = [
  { index: 0, label: "Egna fält", queryParam: "customerSettings" },
  { index: 1, label: "Grupper", queryParam: "roles" }
]

const CustomFieldsTabs = () => {
  const [tabValue, setTabValue] = useState(0)

  return (
    <div>
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h1" gutterBottom>
            Egna fält
          </Typography>
        </Grid>
      </Grid>
    </Container>
      <SubTabs tabs={tabData} value={tabValue} setValue={setTabValue} />
      <Grid container>
        <Grid item xs={12}>
          <PaperBox mt={0} p={0}>
            <TabPanel value={tabValue} index={0}>
              <CustomFields />
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <CustomFieldGroups />
            </TabPanel>
          </PaperBox>
        </Grid>
      </Grid>
    </div>
  )
}

export default CustomFieldsTabs
