import { faPenToSquare } from "@fortawesome/pro-solid-svg-icons"
import { Box, Button, Grid, Tabs, Typography } from "@mui/material"
import { useApi } from "auth/useApi3"
import DefaultButton from "components/Button"
import CustomFieldInput from "components/CustomFieldInput"
import { Formik, useFormikContext } from "formik"
import { apiEndpoints } from "generated/apiEndpoints"
import moment from "moment"
import {
  StyledGridBorderGrey,
  StyledTabVertical
} from "pages/customer/pages/settings/pages/users/pages/edit/UserCustomField.styled"
import React, { useEffect, useState } from "react"
import {
  StyledFontAwesomeIconEdit,
  StyledIconButtonEdit,
  StyledListItem,
  StyledSwitchDisabled
} from "shared/vehicle/CustomFields/CustomFields.styled"
import * as Yup from "yup"
import { customFieldValidation } from "./customFieldValidation"

const CustomFieldValue = ({ customField, value }) => {
  switch (customField.type) {
    case "Date":
      return (
        <Typography variant="bodyMedium" fontWeight={600}>
          {value ? moment.utc(value).local().format("YYYY-MM-DD") : "-"}
        </Typography>
      )
    case "Text":
    case "Numeric":
      return (
        <Typography variant="bodyMedium" fontWeight={600}>
          {value ? value : "-"}
        </Typography>
      )
    case "Boolean":
      return value === "True" ? (
        <StyledSwitchDisabled disabled defaultChecked />
      ) : (
        <StyledSwitchDisabled disabled />
      )
    case "Notification":
    case "RepeatedNotification":
      return (
        <Box display="flex" flexDirection="column">
          {customField?.dateInfo?.date && (
            <Grid container justifyContent="flex-start">
              <Typography variant="bodyMedium" fontWeight={600}>
                {moment(customField.dateInfo.date).utc().format("YYYY-MM-DD")}
              </Typography>
              <Typography>&nbsp;{`(${customField.dateInfo.name})`}</Typography>
            </Grid>
          )}
          {customField?.numberInfo?.number && (
            <Grid container justifyContent="flex-start">
              <Typography variant="bodyMedium" fontWeight={600}>
                {customField.numberInfo.number}
              </Typography>
              <Typography>&nbsp;{`(${customField.numberInfo.name})`}</Typography>
            </Grid>
          )}
        </Box>
      )
  }
  return <Typography>{customField.value}</Typography>
}

const CategoryTabs = ({ categories, categoryTabValue, setCategoryTabValue, cachedCustomFields }) => {
  const { errors, touched } = useFormikContext()

  const countCategoryErrors = (category) => {
    return Object.keys(errors).filter(key => cachedCustomFields[key]?.category === category && touched[key]).length
  }

  return (
    <StyledGridBorderGrey>
      <Tabs
        indicatorColor="primary"
        value={categoryTabValue}
        onChange={(_, newValue) => {
          setCategoryTabValue(newValue)
        }}
        variant="scrollable"
        orientation="vertical"
        TabIndicatorProps={{ style: { left: 0 } }}
      >
        {categories.map((category, index) => {
          const errorCount = countCategoryErrors(category)

          return (
            <StyledTabVertical
              id={`vertical-tab-${index}`}
              aria-controls={`vertical-tabpanel-${index}`}
              label={
                <Box>
                  {category}
                  {errorCount > 0 && (
                    <Box
                      ml={1}
                      width={24}
                      height={24}
                    >
                      {errorCount}
                    </Box>
                  )}
                </Box>
              }
              key={index}
              tabIndex={index}
              tabValue={categoryTabValue}
            />
          )
        })}
      </Tabs>
    </StyledGridBorderGrey>
  )
}

const CustomFieldColumn = ({ vehicleData, data, editMode, setEditMode }) => {
  const [vehicleResult, vehicleApi] = useApi()
  const [initialFormikValues, setInitialFormikValues] = useState({})
  const [yupValidation, setYupValidation] = useState()
  const [categories, setCategories] = useState([])
  const [categoryTabValue, setCategoryTabValue] = useState(0)
  const [cachedCustomFields, setCachedCustomFields] = useState({})

  useEffect(() => {
    if (!data) return
    const cachedVehicleCustomFields = {}
    const categoriesSet = new Set()
    data.forEach((customField, parentIndex) => {
      categoriesSet.add(customField.category)
      const key = "customField" + customField.customFieldId
      cachedVehicleCustomFields[key] = {
        ...customField,
        category: customField.category,
        formikKey: key
      }
    })
    setCategories(Array.from(categoriesSet))
    setCachedCustomFields(cachedVehicleCustomFields)
  }, [data])

  useEffect(() => {
    const customFieldObject = {}
    data.forEach((customValue) => {
      customFieldObject["customField" + customValue.customFieldId] = customValue.value
    })
    setInitialFormikValues({ ...customFieldObject, customFieldValues: data })
  }, [])

  useEffect(() => {
    const shape = {}
    data.map((customField) => {
      shape["customField" + customField.customFieldId] = customFieldValidation(customField)
    })
    setYupValidation(Yup.object().shape(shape))
  }, [data])

  useEffect(() => {
    if (vehicleResult.status !== 2) return
    setTimeout(() => {
      setEditMode(!editMode)
    }, 600)
  }, [vehicleResult])

  function handleSubmit(values, submitProps) {
    const submitData = { ...vehicleData, ...values }
    if (editMode) {
      vehicleApi.put(apiEndpoints.vehicleregistryadmin.updatevehicle, submitData)
    } else {
      vehicleApi.post(apiEndpoints.vehicleregistryadmin.addvehicle, submitData)
    }
    submitProps.resetForm({ values })
  }

  return (
    <Formik
      validationSchema={yupValidation}
      onSubmit={handleSubmit}
      initialValues={initialFormikValues}
      enableReinitialize={true}>
      {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          dirty,
          initialValues,
          setFieldTouched
        }) => (
        <form id="CustomFields" onSubmit={handleSubmit} noValidate style={{ width: "100%" }}>
          <Grid container>
            <Grid md={2} sx={{ height: "100%" }}>
              <CategoryTabs
                categories={categories}
                categoryTabValue={categoryTabValue}
                setCategoryTabValue={setCategoryTabValue}
                cachedCustomFields={cachedCustomFields}
              />
            </Grid>
          <Grid md={8} pl={1}>
            {data && data.filter(customField => customField.category === categories[categoryTabValue]).map((customField, index) => {
                return (
                  <StyledListItem>
                    <Typography
                      sx={{
                        width: "240px",
                        marginRight: "18px"
                      }}>
                      {customField.name}
                    </Typography>
                    {editMode ? (
                      <CustomFieldInput
                        setFieldTouched={setFieldTouched}
                        values={values}
                        customField={customField}
                        setFieldValue={setFieldValue}
                        errors={errors}
                        touched={touched}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        index={index}
                        initialValues={initialValues}
                      />
                    ) : (
                      <CustomFieldValue customField={customField} value={customField.value} />
                    )}
                  </StyledListItem>
                )
              })}
            </Grid>
          <Grid container justifyContent={"flex-end"} pt={3} pb={5} spacing={5}>
            {editMode ? (
              <Box display="flex" gap={5}>
                <Button
                  color="primary"
                  variant="outlined"
                  sx={{ width: "150px" }}
                  onClick={() => {
                    setEditMode(false)
                  }}>
                  Avbryt
                </Button>
                <DefaultButton
                  color="primary"
                  variant="contained"
                  type="submit"
                  disabled={!dirty}
                  sx={{ width: "150px" }}
                  result={vehicleResult}>
                  Spara
                </DefaultButton>
              </Box>
            ) : (
              <></>
            )}
          </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  )
}

const CustomFields = ({ vehicleData, data }) => {
  const [editMode, setEditMode] = useState(false)

  return (
    <div>
      <Grid container md={12} sx={{ position: "relative" }}>
        <CustomFieldColumn
          vehicleData={vehicleData}
          data={data}
          editMode={editMode}
          setEditMode={setEditMode}
        />
        <StyledIconButtonEdit
          top={10}
          right={0}
          editMode={editMode}
          size="medium"
          color="primary"
          onClick={() => {
            setEditMode(!editMode)
          }}
        >
          <StyledFontAwesomeIconEdit editMode={editMode} icon={faPenToSquare} size="lg" />
        </StyledIconButtonEdit>
      </Grid>
    </div>
  )
}

export default CustomFields
