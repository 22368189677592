import { Box, Grid, Typography } from "@mui/material"
import { useApi } from "auth/useApi3"
import { AppContext } from "components/AppContextProvider"
import { apiEndpoints } from "generated/apiEndpoints"
import moment from "moment"
import { useContext, useEffect } from "react"
import { TextCell } from "./components/TextCell"

const IoTInfo = ({ vehicleId }) => {
  const [iotResult, iotApi] = useApi()
  const appContext = useContext(AppContext)
  const hasDevices = appContext.appUser.customer.hasDevices
  const todayDate = new Date()
  const yesterdayDate = new Date()
  yesterdayDate.setDate(todayDate.getDate() - 1)

  useEffect(async () => {
    if (hasDevices)
      await iotApi.get(apiEndpoints.vehicleregistry.getiotdetailsbyvehicleid.replace("{id}", vehicleId))
  }, [])

  return (
    hasDevices &&
    iotResult.status === 2 &&
    iotResult.data.ioTLatestUpdated && (
      <Box bgcolor="background.light" padding={3} borderRadius="10px" marginBottom={3}>
        <Typography variant="h4" color="primary" gutterBottom>
          IoT Data
        </Typography>
        <Grid container direction="column">
          {moment(iotResult.data.ioTLatestUpdated) < moment(yesterdayDate) ? (
            <Grid item>
              <Typography variant="h5" gutterBottom>
                Det saknas data som är nyare än från de senaste 24 timmarna
              </Typography>
            </Grid>
          ) : (
            <>
              <Grid container direction="column">
                <Grid item>
                  <TextCell
                    label="Senast uppdaterad"
                    text={`${moment(iotResult.data.ioTLatestUpdated).format("YYYY-MM-DD HH:mm")}`}
                  />
                </Grid>
                <Grid item>
                  <TextCell
                    label="Mätarställning"
                    text={iotResult.data.ioTOdometer && `${Math.trunc(iotResult.data.ioTOdometer)} km`}
                  />
                </Grid>
                <Grid item>
                  <TextCell
                    label="Bränslenivå"
                    text={iotResult.data.ioTFuelLevel && `${iotResult.data.ioTFuelLevel} %`}
                  />
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </Box>
    )
  )
}

export default IoTInfo
