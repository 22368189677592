import { faBus } from "@fortawesome/pro-solid-svg-icons"
import { Container, Grid } from "@mui/material"
import { IApiFunc, IApiResult } from "auth/interface/api.interface"
import { useApi } from "auth/useApi3"
import PaperBox from "components/PaperBox"
import { apiEndpoints } from "generated/apiEndpoints"
import moment from "moment"
import React, { useEffect, useState } from "react"
import { InspectionBookingSidebar } from "shared/vehicle/components/InspectionBookingSidebar"
import { ChecksStyled } from "./Checks.styled"
import { InspectionCard } from "./inspectionCard"
import { ChecksTable } from "./ChecksTable"
import { ChecksHistoryTable } from "./ChecksHistoryTable"
import { ViewData, ViewToggler } from "components/ViewToggler/ViewToggler"
import { ServiceApproveSidebar } from "shared/vehicle/components/ServiceApproveSidebar"
import { VehicleStatus } from "./Checks.interfaces"

const viewList: ViewData[] = [
  { value: "checks", label: "Kontroller" },
  { value: "history", label: "Historik" }
]

const Checks = () => {
  const [checksResult, checkApi] = useApi() as [IApiResult, IApiFunc]
  const [clickedCheck, setClickedCheck] = useState()
  const [showInspectionSidebar, setShowInspectionSidebar] = useState(false)
  const [showServiceSidebar, setShowServiceSidebar] = useState(false)
  const [within14DaysCount, setWithin14DaysCount] = useState(0)
  const [drivingBanCount, setDrivingBanCount] = useState(0)
  const [view, setView] = useState(viewList[0].value)

  useEffect(() => {
    checkApi.get(apiEndpoints.vehicleregistrychecks.getvehiclechecksbycustomer)
  }, [])

  useEffect(() => {
    if (checksResult.status !== 2) return
    updateCards(checksResult.data)
  }, [checksResult])

  useEffect(() => {
    if (showInspectionSidebar) return
    setClickedCheck(undefined)
  }, [showInspectionSidebar])

  function updateCards(inspection: any[]) {
    inspection.forEach((inspection: any) => {
      const attendanceDate = moment(inspection.attendanceDate)
      const today = moment()
      const isWithin14Days = attendanceDate.isBetween(today, moment(today).add(14, "days"), null, "[]")
      if (isWithin14Days) {
        setWithin14DaysCount((prevCount) => prevCount + 1)
      }
      if (inspection.status === VehicleStatus.DrivingBan) {
        setDrivingBanCount((prevCount) => prevCount + 1)
      }
    })
  }

  const openInspectionSidebar = (inspectionRow: any) => {
    setClickedCheck(inspectionRow)
    setShowInspectionSidebar(!showInspectionSidebar)
  }

  const openServiceSidebar = (checkRow: any) => {
    setClickedCheck(checkRow)
    setShowServiceSidebar(!showServiceSidebar)
  }

  return (
    <>
      <Container>
        <InspectionBookingSidebar
          setShowSidebar={setShowInspectionSidebar}
          showSidebar={showInspectionSidebar}
          clickedInspection={clickedCheck}
          loadInspections={() => {
            checkApi.get(apiEndpoints.vehicleregistrychecks.getvehiclechecksbycustomer)
          }}
        />
        <ServiceApproveSidebar
          setShowSidebar={setShowServiceSidebar}
          showSidebar={showServiceSidebar}
          clickedService={clickedCheck}
          loadChecks={() => {
            checkApi.get(apiEndpoints.vehicleregistrychecks.getvehiclechecksbycustomer)
          }}
        />
        <Grid container direction="row" spacing={2}>
          <InspectionCard
            status={checksResult.status || 1}
            icon={faBus}
            title={"Besiktningar inom 14 dagar"}
            count={within14DaysCount.toString()}
            quantityType={"st"}
          />
          <InspectionCard
            status={checksResult.status || 1}
            icon={faBus}
            title={"Körförbud"}
            count={drivingBanCount.toString()}
            quantityType={"st"}
          />
        </Grid>
        <ChecksStyled>
          <PaperBox>
            <ViewToggler view={view} setView={setView} viewList={viewList} />
            {view === "checks" ? (
              <ChecksTable
                checksResult={checksResult}
                openInspectionSidebar={openInspectionSidebar}
                openServiceSidebar={openServiceSidebar}
              />
            ) : (
              <ChecksHistoryTable />
            )}
          </PaperBox>
        </ChecksStyled>
      </Container>
    </>
  )
}

export default Checks
