import { faCalendarCheck, faCircleCheck } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Grid, TextField, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { DateTimePicker } from "@mui/x-date-pickers"
import ClpButton from "components/Button"
import moment from "moment"
import EmailBoxTypography from "pages/issuereports/pages/workorders/components/EmailBox/EmailBoxTypography"
import { Fragment } from "react"
import ExternalOrderBox from "./ExternalOrderBox"
import { statusButtonColor, statusButtonText, statusSubText } from "./helpers"
import { useStyles } from "./useStyles"

const ExternalStatusBox = ({
  values,
  handleChange,
  handleBlur,
  touched,
  buttonFeedbackResult,
  errors,
  uploadedFiles,
  status,
  setFieldValue
}) => {
  const classes = useStyles()

  const statusIcon = (value) => {
    switch (value) {
      case 2:
        return null
      case 3:
        return <FontAwesomeIcon className={classes.strongBlueText} icon={faCalendarCheck} size="xl" />
      case 4:
        return <FontAwesomeIcon className={classes.strongGreenText} icon={faCircleCheck} size="xl" />
      case 5:
        return <FontAwesomeIcon className={classes.strongGreenText} icon={faCircleCheck} size="xl" />
      case 6:
        return <FontAwesomeIcon className={classes.strongRedText} icon={faCircleCheck} size="xl" />
      default:
        return null
    }
  }

  const statusText = (value) => {
    switch (value) {
      case 2:
        return (
          <Typography className={classes.strongBlueText} variant="h2">
            Boka tid för arbetet
          </Typography>
        )
      case 3:
        return (
          <Typography className={classes.strongBlueText} variant="h2">
            Bokad
          </Typography>
        )
      case 4:
        return (
          <Typography className={classes.strongGreenText} variant="h2">
            Åtgärdad
          </Typography>
        )
      case 5:
        return (
          <Typography className={classes.strongGreenText} variant="h2">
            Avslutad
          </Typography>
        )
      case 6:
        return (
          <Typography className={classes.strongRedText} variant="h2">
            Avbokad
          </Typography>
        )
      default:
        return (
          <Typography className={classes.strongBlueText} variant="h2">
            Boka tid för arbetet
          </Typography>
        )
    }
  }

  const statusContent = (value) => {
    switch (value) {
      case 2:
        return (
          <Box>
            <EmailBoxTypography
              text="Välj det datum ni vill att vi lämnar in fordonet hos er."
              variant="bodyMedium"
              pb={2}
            />
            <Box className={classes.whiteLabel} sx={{ minWidth: 200 }} mt={1}>
              <DateTimePicker
                value={values.plannedDate}
                onChange={(value) => {
                  setFieldValue("plannedDate", value)
                }}
                slotProps={{ textField: { fullWidth: true } }}
                inputFormat="YYYY-MM-DD HH:mm"
                renderInput={(params) => (
                  <TextField
                    variant="outlined"
                    error={touched.plannedDate && errors.plannedDate ? true : false}
                    fullWidth
                    {...params}
                  />
                )}
              />
            </Box>
            {touched.plannedDate && errors.plannedDate ? (
              <Typography className={classes.dateText}>{errors.plannedDate}</Typography>
            ) : null}
          </Box>
        )
      case 3:
        return (
          <Box>
            <EmailBoxTypography text="Planerad åtgärd" variant="bodySmall" />
            <EmailBoxTypography
              text={
                moment(values.plannedDate).isValid()
                  ? moment.utc(values.plannedDate).local().format("YYYY-MM-DD HH:mm")
                  : "-"
              }
              variant="h6"
            />
          </Box>
        )
      case 4:
        return (
          <Box>
            <EmailBoxTypography text="Åtgärdad" variant="bodySmall" />
            <EmailBoxTypography
              text={
                moment(values.resolvedDate).isValid()
                  ? moment.utc(values.resolvedDate).local().format("YYYY-MM-DD HH:mm")
                  : "-"
              }
              variant="h6"
            />
          </Box>
        )
      case 5:
        return (
          <Box>
            <EmailBoxTypography text="Åtgärdad" variant="bodySmall" />
            <EmailBoxTypography
              text={
                moment(values.resolvedDate).isValid()
                  ? moment.utc(values.resolvedDate).local().format("YYYY-MM-DD HH:mm")
                  : "-"
              }
              variant="h6"
            />
          </Box>
        )
      case 6:
        return null
      default:
        return <Box></Box>
    }
  }

  return (
    <Grid item xs={12} md={12} lg={4} direction="row" justifyContent="space-between">
      <Box
        className={
          status < 4
            ? classes.externalBlueEmailBox
            : status === 6
            ? classes.externalRedEmailBox
            : classes.externalGreenEmailBox
        }>
        <Box pt={5} pb={5} pr={2} pl={2}>
          <Box mb={2} display="flex" alignItems="center">
            {statusIcon(status)}
            {statusText(status)}
          </Box>

          {/* <Box className={classes.whiteLabel} sx={{ minWidth: 200 }} mt={0.5} mb={2}>
              <FormControl fullWidth>
                <InputLabel id="status"></InputLabel>
                <Select
                  labelId="status"
                  id="status"
                  onChange={(event) => setFieldValue('status', event.target.value)}
                  defaultValue={status ? status : ''}
                  error={touched.status && errors.status ? true : false}>
                  {WorkOrderStatusList.map((data, index) => (
                    <MenuItem value={data.value} key={index}>
                      {data.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box> */}

          {statusContent(status)}

          {status < 4 && (
            <Fragment>
              <Box mt={3} mb={2}>
                <ClpButton
                  color={statusButtonColor(status)}
                  result={buttonFeedbackResult}
                  variant="contained"
                  type="submit">
                  {statusButtonText(status)}
                </ClpButton>
              </Box>
            </Fragment>
          )}
          <Typography variant="bodySmall">{statusSubText(values)}</Typography>
        </Box>
      </Box>
      <ExternalOrderBox values={values} />
    </Grid>
  )
}

export default ExternalStatusBox
