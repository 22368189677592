import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import { IconButton } from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import React from "react"

const useStyles = makeStyles((theme) => ({
    closeMenuBtn: (props) => ({
      padding: "8px",
      marginBottom: "1rem",
      position: "absolute",
      left: props.left,
      top: "8px",
      zIndex: 2000,
      borderRadius: " 0 0.3rem 0.3rem 0 ",
      backgroundColor: "#2E3547",
      color: "#ffffff"
    })
  }))

const CloseSidebarButton = ({ toggleMenu, position = {left: "0", top: "8px"} }) => {
  const classes = useStyles(position)

  return (
    <IconButton className={classes.closeMenuBtn} aria-label="close menu" onClick={toggleMenu} size="large">
      <ChevronRightIcon />
    </IconButton>
  )
}

export default CloseSidebarButton
