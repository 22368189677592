import React, { useEffect, useState } from "react"
import { ListItemText, Switch, Typography } from "@mui/material"
import { t } from "i18next"
import { IFormSwitchProps } from "./FormSwitch.interfaces"
import ListItem from "@mui/material/ListItem"
import { StyledInputLabel } from "./FormSwitch.styled"

export const FormSwitch = ({ id, handleChange, labelDefault, labelKey, value }: IFormSwitchProps) => {
  const [checkedItem, setCheckedItem] = useState<boolean>(false)

  useEffect(() => {
    setCheckedItem(value)
  }, [value])

  const setCheckbox = () => {
    handleChange && handleChange(!checkedItem)
  }

  return (
    <ListItem sx={{ padding: 0 }}>
      <ListItemText
        key={id}
        primary={
          <StyledInputLabel>
            <Typography variant="labelLarge" fontWeight={500}>
              {labelKey ? t(labelKey, labelDefault) : labelDefault}
            </Typography>
          </StyledInputLabel>
        }
        secondary={
            <Switch
              checked={checkedItem}
              onChange={() => setCheckbox()}
              value={checkedItem}
            />
        }
      />
    </ListItem>
  )
}