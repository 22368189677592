import { FormFieldComponentEnum, FormFieldDataTypeEnum } from "../edit/UserCustomField.interfaces"

export function getFieldTypeDescription(component: string, dataType: number): string {
  switch (component) {
    case FormFieldComponentEnum.TextField:
      switch (dataType) {
        case FormFieldDataTypeEnum.String:
          return "Textfält"
        case FormFieldDataTypeEnum.Number:
          return "Nummer"
        default:
          return component
      }
    case FormFieldComponentEnum.MultiTextField:
      if (dataType === FormFieldDataTypeEnum.String) return "Text"
      return component
    case FormFieldComponentEnum.Upload:
      if (dataType === FormFieldDataTypeEnum.BlobStorage) return "Fil"
      return component
    case FormFieldComponentEnum.RadioButton:
    case FormFieldComponentEnum.Checkbox:
    case FormFieldComponentEnum.BigRadioButtons:
      if (dataType === FormFieldDataTypeEnum.Boolean) return "Ja/Nej"
      return component
    case FormFieldComponentEnum.Select:
      if (dataType === FormFieldDataTypeEnum.String) return "Text"
      return component
    case FormFieldComponentEnum.DatePicker:
      if (dataType === FormFieldDataTypeEnum.Date) return "Datum"
      return component
    case FormFieldComponentEnum.DateTimePicker:
      if (dataType === FormFieldDataTypeEnum.DateTime) return "Datum & tid"
      return component
    case FormFieldComponentEnum.VehiclePicker:
    case FormFieldComponentEnum.Text:
    case FormFieldComponentEnum.CardButton:
      if (dataType === FormFieldDataTypeEnum.String) return "Text"
      return component
    case FormFieldComponentEnum.LocationPicker:
      return "Plats"
    default:
      return component
  }
}

export function getComponentAndDataType(name: string): {
  component: FormFieldComponentEnum,
  dataType: FormFieldDataTypeEnum
} | null {
  switch (name) {
    case "Numeric":
      return { component: FormFieldComponentEnum.TextField, dataType: FormFieldDataTypeEnum.Number }
    case "Date":
      return { component: FormFieldComponentEnum.DatePicker, dataType: FormFieldDataTypeEnum.Date }
    case "Text":
      return { component: FormFieldComponentEnum.TextField, dataType: FormFieldDataTypeEnum.String }
    case "Boolean":
      return { component: FormFieldComponentEnum.Checkbox, dataType: FormFieldDataTypeEnum.Boolean }
    case "File":
      return { component: FormFieldComponentEnum.Upload, dataType: FormFieldDataTypeEnum.BlobStorage }
    default:
      return null
  }
}