import { faEdit, faUpload } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, Button, ButtonGroup, Container, Grid, IconButton, Typography } from "@mui/material"
import { hasRole } from "auth/hasRole"
import { useApi } from "auth/useApi3"
import { AppContext } from "components/AppContextProvider"
import ClpTable from "components/ClpTable"
import { SelectColumnFilter, SwitchColumnFilter } from "components/ClpTable/ClpTableFilterTypes"
import { GlobalLoaderContext } from "components/GlobalLoadingProvider"
import globalUseStyles from "css/theme/globalUseStyles.js"
import { apiEndpoints } from "generated/apiEndpoints"
import { allRoles } from "generated/roles"
import { allRoutes } from "generated/routes"
import moment from "moment"
import { useContext, useEffect, useState } from "react"
import Moment from "react-moment"
import { NavLink, useHistory } from "react-router-dom"
import { DashboardCard } from "../dashboard/DashboardCard"
import { Sidebar } from "./components/Sidebar"
import { yesOrNoStatus } from "./components/YesOrNoStatus"

const ListVehicles = () => {
  const [dashboardResult, dashboardFuncs] = useApi([])
  const globalClasses = globalUseStyles()
  const appContext = useContext(AppContext)
  const loadingContext = useContext(GlobalLoaderContext)
  const [vehiclesResult, vehiclesApi] = useApi()
  const [showSidebar, setShowSidebar] = useState(false)
  const [addVehicleResult, addVehicleApi] = useApi()
  const [editMode, setEditMode] = useState(false)
  const [addOrUpdateResultState, setAddOrUpdateResultState] = useState(null)
  const history = useHistory()
  const [updateVehicleResult, updateVehicleApi] = useApi()
  const [vehicleResult, vehicleApi] = useApi()
  const [mainPropellantResult, mainPropellantApi] = useApi()
  const [customFieldsResult, customFieldsApi] = useApi()
  const isVehicleRegistryAdmin = hasRole(appContext.appUser, allRoles.VehicleRegistryAdmin)
  const [issuereportResult, issuereportFuncs] = useApi()

  useEffect(() => {
    vehiclesApi.get(apiEndpoints.vehicleregistry.getvehiclelist)
    mainPropellantApi.get(apiEndpoints.vehicleregistryadmin.getavailablemainpropellant)
    return () => {
      vehiclesApi.leave()
      mainPropellantApi.leave()
    }
  }, [vehiclesApi, mainPropellantApi])

  // get dashboard data

  useEffect(() => {
    loadingContext.setLoading(() => vehiclesResult.status === 1)
  })

  useEffect(() => {
    dashboardFuncs.get(apiEndpoints.vehicleregistry.getvtrfordashboard)
    return () => {
      dashboardFuncs.leave()
    }
  }, [dashboardFuncs])

  const getFormData = (id) => {
    setAddOrUpdateResultState(null)
    if (id) {
      setEditMode(true)
      customFieldsApi.get(apiEndpoints.vehicleregistry.getvehicleformvalues.replace("{vehicleId}", id))
      vehicleApi.get(apiEndpoints.vehicleregistryadmin.getvehiclebyid.replace("{id}", id))
    } else {
      customFieldsApi.get(apiEndpoints.vehicleregistry.getvehicleformvalues.replace("{vehicleId}", 0))
      setEditMode(false)
    }
    setShowSidebar(true)
  }

  const onSubmit = (values) => {
    if (editMode) {
      updateVehicleApi.put(apiEndpoints.vehicleregistryadmin.updatevehicle, values)
    } else {
      addVehicleApi.post(apiEndpoints.vehicleregistryadmin.addvehicle, values)
    }
  }

  useEffect(() => {
    if (updateVehicleResult.status === 2) {
      const index = vehiclesResult.data.findIndex((item) => item.id === updateVehicleResult.data.id)
      vehiclesResult.data[index] = updateVehicleResult.data
      setShowSidebar(false)
    }
    setAddOrUpdateResultState(updateVehicleResult)
  }, [updateVehicleResult, vehiclesResult.data])

  useEffect(() => {
    if (addVehicleResult.status === 2 && vehiclesResult.data) {
      vehiclesResult.data.push(addVehicleResult.data)
      setShowSidebar(false)
    } else if (addVehicleResult.status === 2 && vehiclesResult.data === null) {
      vehiclesApi.get(apiEndpoints.vehicleregistryadmin.getvehiclelist)
      setShowSidebar(false)
    }
    setAddOrUpdateResultState(addVehicleResult)
  }, [addVehicleResult, vehiclesApi, vehiclesResult.data])

  useEffect(() => {
    issuereportFuncs.get(apiEndpoints.issuereports.getissuereportsoverview)
    return () => {
      issuereportFuncs.leave()
    }
  }, [issuereportFuncs])

  const columns = [
    {
      accessor: "regNumber",
      Header: "Registreringsnummer"
    },
    {
      accessor: "internnumber",
      Header: "Internnr"
    },
    {
      Cell: ({ cell }) => {
        return (
          cell.row.original?.fordonsbenamning !== null && cell.row.original?.fordonsbenamning?.slice(0, 15)
        )
      },
      accessor: "fordonsbenamning",
      Header: "Fabrikat"
    },
    {
      accessor: "vehicleType",
      Header: "Fordonstyp"
    },
    {
      Cell: ({ cell }) => {
        return cell.row.original?.fordonsAr !== 0 ? cell.row.original?.fordonsAr : ""
      },
      accessor: "fordonsAr",
      Header: "Årsmodell"
    },
    {
      accessor: "company",
      Header: "Bolag"
    },
    {
      accessor: "besiktningsDatum",
      Header: "Besiktning senast",
      Cell: ({ cell }) => {
        return (
          moment.utc(cell.row.original.besiktningsDatum).isAfter(moment.utc("0001-01-01")) && (
            <Moment date={cell.row.original.besiktningsDatum} format="YYYY-MM-DD" globallocale="sv" />
          )
        )
      }
    },
    {
      Header: "Besiktning inom 14 dagar",
      accessor: "besiktningInom14Dagar",

      mainFilter: true,
      Filter: SelectColumnFilter
    },
    {
      Header: "Körförbud",
      accessor: "brukandeförbud",
      mainFilter: true,
      Filter: SelectColumnFilter
    },
    {
      Header: "Aktiva felrapporter",
      Cell: ({ cell }) => {
        const activeIssueReport =
          issuereportResult.status === 2 &&
          issuereportResult?.data?.filter((issue) => issue.vehicleId === cell.row.original.id)?.length
        return <>{activeIssueReport || ""}</>
      }
    }
  ]

  columns.push(
    {
      accessor: (row) => {
        return yesOrNoStatus(row.tachographIntegrationActive)
      },
      Header: "Färdskrivare",
      mainFilter: true,
      Filter: SelectColumnFilter
    },
    {
      Header: "Aktiv färdskrivare",
      accessor: (row) => {
        return yesOrNoStatus(!row.archived)
      },
      mainFilter: true,
      defaultValue: "Ja",
      Filter: SwitchColumnFilter,
      FilterOptions: ["Ja", "Nej"]
    }
  )
  if (hasRole(appContext.appUser, allRoles.VehicleRegistryAdmin)) {
    columns.push({
      Cell: ({ cell }) => {
        return (
          <IconButton onClick={() => getFormData(cell.row.original.id)}>
            <FontAwesomeIcon icon={faEdit} size="xs" />
          </IconButton>
        )
      },
      align: "right",
      accessor: "action",
      Header: "",
      hiddenFilter: true
    })
  }
  const filters = [
    {
      id: "Aktiverad",
      value: "Ja"
    }
  ]

  return (
    <div>
      <Container>
        <Sidebar
          showSidebar={showSidebar}
          onSubmit={onSubmit}
          vehicleResult={vehicleResult}
          customFieldsResult={customFieldsResult}
          mainPropellantResult={mainPropellantResult}
          editMode={editMode}
          addOrUpdateResultState={addOrUpdateResultState}
          setShowSidebar={setShowSidebar}
        />
        <Box mb={4}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ gridGap: { xs: "20px" } }}>
            <Typography variant="h1">Fordonsregister</Typography>

            {hasRole(appContext.appUser, allRoles.VehicleRegistryAdmin) && (
              <Grid item sx={{ display: "flex", flexWrap: "wrap", gridGap: { xs: "20px" } }}>
                <ButtonGroup>
                  <Button
                    variant="contained"
                    color="primary"
                    component={NavLink}
                    to={allRoutes.VehicleregistryVehiclesImportvtr()}>
                    Importera från Transportstyrelsen
                  </Button>

                  <Button
                    variant="contained"
                    color="primary"
                    component={NavLink}
                    to={allRoutes.VehicleregistryVehiclesImport()}>
                    Importera fordon
                  </Button>
                </ButtonGroup>

                <Button
                  variant="outlined"
                  sx={{ marginLeft: { sm: "0px", md: "15px" } }}
                  color="primary"
                  component={Button}
                  onClick={() => getFormData()}>
                  Lägg till fordon
                </Button>
              </Grid>
            )}
          </Grid>
        </Box>
        <Box mb={4}>
          <Grid spacing={2} container direction="row" justifyContent="spaceBetween">
            <Grid item md={8} lg={12} direction="row">
              <Grid container direction="row" spacing={2}>
                <DashboardCard
                  icon={faUpload}
                  route={allRoutes.VehicleregistryVehiclesImportvtr}
                  itemLength={vehiclesResult.data?.length}
                  title="Upplagda fordon"
                  quantityType="st"
                  status={vehiclesResult.status}
                />
                {isVehicleRegistryAdmin && (
                  <DashboardCard
                    img="assets/Transportstyrelsen-icon.jpg"
                    route={allRoutes.VehicleregistryVehiclesImportvtr()}
                    itemLength={dashboardResult.data}
                    title="Importera från Transportstyrelsen"
                    quantityType="st"
                    status={dashboardResult.status}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Box className={globalClasses.tableContainer}>
        <ClpTable
          columns={columns}
          cellProps={(row) => ({
            onClick: () => history.push(allRoutes.VehicleregistryVehiclesDetails(row.original.id))
          })}
          filters={filters}
          rows={vehiclesResult.status === 2 && vehiclesResult.data}
          status={vehiclesResult.status}
        />
      </Box>
    </div>
  )
}
export default ListVehicles
