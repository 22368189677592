import { faFolder } from "@fortawesome/pro-solid-svg-icons"
import { Box, InputLabel, List, ListItem, ListItemText, TextField, Typography } from "@mui/material"
import { AppContext } from "components/AppContextProvider"
import Button from "components/Button"
import { RouterPrompt } from "components/RouterPrompt"
import { CircleIcon } from "components/icons/CircleIcon"
import CloseSidebarButton from "components/navigation/CloseSidebarButton"
import { Formik } from "formik"
import { useContext, useEffect, useRef } from "react"

import { ICustomerCustomRoleForm } from "./customercustomrole.interface"
import { roleValidationSchema } from "./roleValidationSchema"

const CustomRoleForm = (props: ICustomerCustomRoleForm) => {
  const { initialValues, results, handleSubmit } = props
  const { id } = initialValues
  const appContext = useContext(AppContext)

  const formRef = useRef<any>(null)

  useEffect(() => {
    if (results.status === 2 && formRef.current) {
      formRef.current.resetForm()
    }
  }, [results])

  return (
    <Formik
      innerRef={formRef}
      initialValues={initialValues}
      validationSchema={roleValidationSchema}
      onSubmit={handleSubmit}
      enableReinitialize={true}>
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
        <form id="CustomRoleForm" onSubmit={handleSubmit} noValidate>
          <RouterPrompt />
          <List>
            <CloseSidebarButton
              toggleMenu={() => {
                appContext.toggleSideBar()
              }}
            />

            <ListItem>
              <Box pt={5} pb={3} display="flex" alignItems="center" justifyContent="space-between">
                <Box display="flex" alignItems="center">
                  <CircleIcon icon={faFolder} color="primary" size="lg" sx={{ marginRight: "15px" }} />
                  <Box justifyContent="center">
                    <ListItemText
                      primary={
                        <Typography color="textPrimary" variant="h2">
                          {id > 0 ? "Uppdatera roll" : "Lägg till roll"}
                        </Typography>
                      }
                    />
                  </Box>
                </Box>
              </Box>
            </ListItem>

            <ListItem>
              <ListItemText
                primary={
                  <InputLabel>
                    <Typography variant="labelMedium" fontWeight={500}>
                      Roll
                    </Typography>
                  </InputLabel>
                }
                secondary={
                  <TextField
                    autoComplete="off"
                    fullWidth
                    id="name"
                    value={values.name}
                    placeholder="Ange namn på roll"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.name && errors.name ? true : false}
                    helperText={touched.name && errors.name ? errors.name : null}
                    variant="outlined"
                  />
                }
              />
            </ListItem>

            <ListItem>
              <Button result={results} type="submit">
                {id > 0 ? "Spara" : "Skapa roll"}
              </Button>
            </ListItem>
          </List>
        </form>
      )}
    </Formik>
  )
}
export default CustomRoleForm
