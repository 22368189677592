import MoreVertIcon from "@mui/icons-material/MoreVert"
import { useApi } from "auth/useApi3"
import React, { useContext, useEffect, useState } from "react"

import Button from "components/Button"
import { GlobalLoaderContext } from "components/GlobalLoadingProvider"
import { apiEndpoints } from "generated/apiEndpoints"
import { UserForm } from "shared/customer/UserForm"

import {
  Box,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography
} from "@mui/material"
import SubTabs from "components/SubTabs"
import { TabPanel } from "components/navigation/TabPanel"
import { allRoutes } from "generated/routes"
import { useHistory } from "react-router-dom"
import { UserCustomFieldValues } from "./UserCustomFieldValues"

const tabData = [
  { index: 0, label: "Användarinformation", queryParam: "info" },
  { index: 1, label: "Egna fält", queryParam: "customFields" }
]

const EditUser = ({ match }) => {
  const [customerResult, getCustomerApi] = useApi()
  const [userResult, getUserApi] = useApi()
  const [updateUserResult, updateUserApi] = useApi()
  const [deleteResult, deleteUserApi] = useApi()
  const loadingContext = useContext(GlobalLoaderContext)
  const routerHistory = useHistory()
  const [tabValue, setTabValue] = useState(0)

  useEffect(() => {
    // redirect back to list
    if (deleteResult.status === 2) routerHistory.push(allRoutes.CustomerSettingsUsers())
  }, [deleteResult.status, routerHistory])

  useEffect(() => {
    const loadStuff = async () => {
      await getCustomerApi.get(apiEndpoints.customeradmin.getcustomer)

      await getUserApi.get(apiEndpoints.customeradmin.getuserbyid.replace("{id}", match.params.id))
    }
    loadStuff()
  }, [match, getCustomerApi, getUserApi])

  useEffect(() => {
    loadingContext.setLoading(() => customerResult.status === 1)
  })

  const handleSubmit = (saveUser) => {
    updateUserApi.put(apiEndpoints.customeradmin.updateuser, saveUser)
  }

  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  const openSettingsDropdown = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const closeSettingsDropdown = () => {
    setAnchorEl(null)
  }

  const [dialogOpen, setDialogOpen] = React.useState(false)

  const openRemoveDialog = () => {
    deleteUserApi.reset()
    setDialogOpen(true)
  }

  const closeRemoveDialog = () => {
    deleteUserApi.reset()
    setDialogOpen(false)
  }
  return (
    <div>
      <Container>
        <Box marginY={3}>
          <Grid item container direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h2">Redigera användare</Typography>
            <IconButton
              aria-label="Inställningar"
              aria-controls="settings-menu"
              aria-haspopup="true"
              onClick={openSettingsDropdown}
              size="large">
              <MoreVertIcon fontSize="large" />
            </IconButton>
            <Menu id="settings-menu" anchorEl={anchorEl} open={open} onClose={closeSettingsDropdown}>
              <MenuItem
                onClick={() => {
                  routerHistory.push(allRoutes.CustomerSettingsUsersEditPasswordreset(match.params.id))
                }}>
                Sätt nytt lösenord
              </MenuItem>
              <MenuItem onClick={openRemoveDialog}>Ta bort användare</MenuItem>
            </Menu>
          </Grid>
        </Box>

        <SubTabs tabs={tabData} value={tabValue} setValue={setTabValue} />
        <TabPanel value={tabValue} index={0}>
          {userResult.status === 2 && customerResult.status === 2 ? (
            <UserForm
              initialValues={userResult.data}
              customer={customerResult.data}
              onSubmit={handleSubmit}
              result={updateUserResult}
            />
          ) : null}
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <UserCustomFieldValues userResult={userResult} />
        </TabPanel>
      </Container>
      <Dialog
        open={dialogOpen}
        onClose={closeSettingsDropdown}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{"Ta bort användare?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Är du säker att du vill ta bort användare? Ditt val går inte att ångra.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeRemoveDialog} variant="outlined">
            Avbryt
          </Button>
          <Button
            result={deleteResult}
            onClick={() => {
              deleteUserApi.del(apiEndpoints.customeradmin.deleteuser.replace("{id}", match.params.id))
            }}
            color="primary"
            autoFocus>
            Ja, jag är säker
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
export default EditUser
