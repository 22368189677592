import React, { useContext, useEffect, useMemo, useState } from "react"
import { AppContext } from "components/AppContextProvider"
import { useApi } from "auth/useApi3"
import { apiEndpoints } from "generated/apiEndpoints"
import moment from "moment/moment"
import { hasRole } from "auth/hasRole"
import { allRoles } from "generated/roles"
import { Button, Grid, IconButton } from "@mui/material"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPenToSquare, faTrashCan } from "@fortawesome/pro-solid-svg-icons"
import ClpTable from "components/ClpTable"
import { IApiFunc, IApiResult } from "auth/interface/api.interface"
import { VehicleDetails, VehicleExpense } from "../vehicle.interfaces"
import { ExpenseSidebar } from "./ExpenseSidebar"
import { ActionType, ModalState } from "./InspectionBookingSidebar.interfaces"
import { initialModalState, ModalDialog } from "./ModalDialog"

function numberToLocaleCurrencyFormat(amount: number): string {
  const languageTag = navigator.languages.find(language => language.match(/sv|sv\-SE/g)) || navigator.language
  return amount.toLocaleString(languageTag, { minimumFractionDigits: 2 })
}

export function VehicleExpenses({ vehicleData }: { vehicleData: VehicleDetails }) {
  const appContext = useContext(AppContext)
  const [expensesResult, expensesApi] = useApi() as [IApiResult, IApiFunc]
  const [expensesDeleteResult, expensesDeleteApi] = useApi() as [IApiResult, IApiFunc]
  const [clickedExpense, setClickedExpense] = useState<VehicleExpense>()
  const [showExpenseSidebar, setShowExpenseSidebar] = useState(false)
  const [modalData, setModalData] = useState<ModalState>(initialModalState)

  useEffect(() => {
    if (showExpenseSidebar) return
    setClickedExpense(undefined)
  }, [showExpenseSidebar])

  function fetchExpenses() {
    expensesApi.get(apiEndpoints.vehicleexpense.getvehicleexpensesbyvehicleid.replace("{vehicleId}", vehicleData.id.toString()))
  }

  useEffect(() => {
    if (expensesDeleteResult.status !== 2) return
    fetchExpenses()
  }, [expensesDeleteResult])

  useEffect(() => {
    fetchExpenses()
  }, [])

  const columns: any = useMemo(
    () => [
      {
        accessor: "name",
        Header: "Kostnad"
      },
      {
        accessor: "description",
        Header: "Beskrivning"
      },
      {
        accessor: (row: VehicleExpense) => row?.date ? moment(row?.date).local().format("YYYY-MM-DD") : "",
        Header: "Datum"
      },
      {
        accessor: (row: VehicleExpense) => row?.amount ? numberToLocaleCurrencyFormat(row?.amount) : "",
        Header: "Belopp (kr)",
        align: "right"
      },
    ],
    []
  )

  useEffect(() => {
    if (hasRole(appContext.appUser, allRoles.VehicleRegistryAdmin)) {
      columns.push({
        Cell: ({ cell }: any) => {
          return (
            <>
              <IconButton
                color="primary"
                size="medium"
                onClick={() => {
                  setClickedExpense(cell.row.original)
                  setShowExpenseSidebar(true)
                }}
              >
                <FontAwesomeIcon icon={faPenToSquare} size="2xs" />
              </IconButton>
              <IconButton
                color="primary"
                size="medium"
                onClick={() => {
                  const expenseName = cell.row.original.name
                  setClickedExpense(cell.row.original)
                  setModalData({
                    actionType: ActionType.Confirm,
                    isOpen: true,
                    title: "Radera kostnad",
                    message: `Är du säker på att du vill radera ${expenseName}?`,
                  })
                }}
              >
                <FontAwesomeIcon icon={faTrashCan} size="2xs" />
              </IconButton>
            </>
          )
        },
        accessor: "action",
        Header: "Hantera",
        align: "center",
        hiddenFilter: true
      })
    }
  }, [])

  return (
    <>
      <ExpenseSidebar
        vehicleData={vehicleData}
        showSidebar={showExpenseSidebar}
        setShowSidebar={setShowExpenseSidebar}
        clickedExpense={clickedExpense}
        handleSubmit={() => {
          expensesApi.get(apiEndpoints.vehicleexpense.getvehicleexpensesbyvehicleid.replace("{vehicleId}", vehicleData.id.toString()))
        }}
      />
      <ModalDialog
        modalData={modalData}
        setModalData={setModalData}
        setShowSidebar={setShowExpenseSidebar}
        handleSubmit={() => {
          const expenseId = clickedExpense?.id
          if (!expenseId) return
          expensesDeleteApi.del(apiEndpoints.vehicleexpense.deletevehicleexpense.replace("{id}", expenseId.toString()))
        }}
      />
      <Grid md={8}>
        <Grid container justifyContent="flex-end">
          <Button
            disabled={showExpenseSidebar}
            variant="contained"
            onClick={() => {
              setClickedExpense(undefined)
              setShowExpenseSidebar(true)
            }}
          >
            Lägg till kostnad
          </Button>
        </Grid>
        <ClpTable
          columns={columns}
          rows={expensesResult.status === 2 && expensesResult.data}
          status={expensesResult.status}
        />
      </Grid>
    </>
  )
}