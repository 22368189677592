// The main App entry point
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate } from "@azure/msal-react"
import CssBaseline from "@mui/material/CssBaseline"
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles"
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import ExternalPagesRouter from "components/ExternalPagesRouter"
import { GlobalLoaderProvider } from "components/GlobalLoadingProvider"
import Navigation from "components/navigation/Navigation"
import light from "css/theme/light.js"
import i18next from "i18next"
import "moment/locale/en-gb"
import "moment/locale/sv"
import { Suspense, useEffect, useState } from "react"
import "react-big-calendar/lib/css/react-big-calendar.css"
import { BrowserRouter as Router } from "react-router-dom"
import AppContextProvider from "./components/AppContextProvider"
import PagesRouter from "./components/PagesRouter"
import "./i18n"
const getLang = (lng) => {
  switch (lng) {
    case "en-US":
      return "en"
    case "sv-SE":
      return "sv"
    default:
      return lng
  }
}
const App = ({ pca }) => {
  const [language, setLanguage] = useState(
    getLang(i18next.language ? i18next.language : window.localStorage.i18nextLng)
  )
  useEffect(() => {
    const handleLanguageChanged = () => {
      setLanguage(getLang(i18next.language ? i18next.language : window.localStorage.i18nextLng))
    }

    i18next.on("languageChanged", handleLanguageChanged)

    return () => {
      i18next.off("languageChanged", handleLanguageChanged)
    }
  }, [i18next.language])

  return (
    <Suspense fallback={null}>
      <Router>
        <MsalProvider instance={pca}>
          <AppContextProvider>
            <LocalizationProvider
              dateAdapter={AdapterMoment}
              adapterLocale={language ? getLang(language) : "sv"}>
              <StyledEngineProvider injectFirst>
                <ThemeProvider theme={light}>
                  <GlobalLoaderProvider>
                    <CssBaseline />
                    <AuthenticatedTemplate>
                      <Navigation />
                      <PagesRouter />
                    </AuthenticatedTemplate>
                    <UnauthenticatedTemplate>
                      <ExternalPagesRouter />
                    </UnauthenticatedTemplate>
                  </GlobalLoaderProvider>
                </ThemeProvider>
              </StyledEngineProvider>
            </LocalizationProvider>
          </AppContextProvider>
        </MsalProvider>
      </Router>
    </Suspense>
  )
}

export default App
