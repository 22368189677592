import React, { useState } from "react"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { faFile, faMagnifyingGlass, faTrashCanXmark } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, Card, CardActions, DialogContent, DialogTitle, Grid, Modal, Typography } from "@mui/material"
import { IFormUploadAttachmentProps } from "./formuploadfile.interface"
import { MaterialDialog, StyledCardMedia } from "./FormPreviewFileThumbnail.styled"

const protectedRoutes = [
  "/vehicleregistry/vehicles/",
  "/customer/settings/users/",
]

const isImageFile = (fileId: string): boolean => {
  const imageExtensions = ['png', 'jpg', 'jpeg', 'gif', 'bmp', 'svg', 'webp'];
  const fileExtension = fileId?.split('.').pop()?.toLowerCase();
  return imageExtensions.includes(fileExtension || '');
}

const FormPreviewFileThumbnail = (props: IFormUploadAttachmentProps) => {
  const { fileId, fileName, Data, removeFile } = props
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)
  const shouldShowDeleteButton = !protectedRoutes.some(route => window.location.href.includes(route))

  const closeModal = () => {
    setDialogOpen(false)
  }

  return (
    <>
      {Data !== null && (
        <>
          <Card>
            {fileName && isImageFile(fileName) ? (
              <>
                <StyledCardMedia
                  component={"img"}
                  sx={{ height: 150 }}
                  alt={`Bild nr ${fileId}`}
                  image={`data:image/png;base64,${Data}`}
                  onClick={() => setDialogOpen(true)}
                />
                <CardActions sx={{ justifyContent: "center" }}>
                  <Button size="small" onClick={() => setDialogOpen(true)}>
                    <FontAwesomeIcon
                      icon={faMagnifyingGlass as IconProp}
                      style={{ color: "#34727F", marginRight: "5px" }}
                    />
                    <Typography>Öppna</Typography>
                  </Button>
                  {shouldShowDeleteButton && (
                    <Button size="small" onClick={() => removeFile(fileId)}>
                      <FontAwesomeIcon
                          icon={faTrashCanXmark as IconProp}
                          style={{ color: "#E45C41", marginRight: "5px" }}
                      />
                      <Typography>Ta bort</Typography>
                    </Button>
                  )}
                </CardActions>
              </>
            ) : (
              <Grid container gap={2} alignItems="center">
                <FontAwesomeIcon icon={faFile} size="xl"  />
                <Typography variant="paragraphLarge" fontWeight="500">
                  {fileName}
                </Typography>
              </Grid>
            )}
          </Card>
          <Modal open={dialogOpen} onClose={() => closeModal()} aria-labelledby="image">
            <MaterialDialog onClose={closeModal} aria-labelledby="customized-dialog-title" open={dialogOpen}>
              <Grid container justifyContent={"space-between"} alignItems={"center"} flexWrap={"nowrap"}>
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                  {fileName}
                </DialogTitle>
                <Button
                  aria-label="close"
                  onClick={closeModal}
                  color="primary"
                  variant="outlined"
                  sx={{
                    mr: 2
                  }}>
                  Stäng
                </Button>
              </Grid>

              <DialogContent>
                <img
                  style={{
                    maxWidth: "100%"
                  }}
                  src={`data:image/jpeg;base64,${Data}`}
                  alt={`${fileName}`}
                  onClick={() => closeModal()}
                />
              </DialogContent>
            </MaterialDialog>
          </Modal>
        </>
      )}
    </>
  )
}

export default FormPreviewFileThumbnail
