import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Checkbox, FormControlLabel, List, ListItem } from "@mui/material"
import { t } from "i18next"
import React, { useEffect, useState } from "react"
import styles from "../style"
import {
  StyleAccordion,
  StyleAccordionDetails,
  StyleAccordionSummary,
  StyledFormControlLabelBold,
  StyleSubListItem
} from "./PermissionAndRolesSelect.styled"

interface Department {
  id: number
  name: string
  selected: boolean
}

interface Company {
  id: number
  name: string
  selected: boolean
  departments: Department[]
}

interface PermissionAndRolesSelectProps {
  companies: Company[]
  initCompanies: number[]
  initDepartments: number[]
  onChange?: (companies: Company[]) => void
  disabled?: boolean
  sharedByAll?: boolean
  mainCompanySelect?: boolean
  values?: { mainCompanyId?: number }
  setFieldValue?: (field: string, value: any) => void
  handleBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> | null | undefined
  handleChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
  touched?: { mainCompanyId?: boolean }
  errors?: { mainCompanyId?: string }
}

function makeStateFromData(
  companies: Company[],
  initCompanies: number[],
  initDepartments: number[],
  enabled?: boolean
): Company[] {
  const companiesWithSelection: Company[] = []

  if (companies) {
    companies.forEach((c) => {
      const departmentsWithSelection: Department[] = []
      c.departments.forEach((d) => {
        departmentsWithSelection.push({
          id: d.id,
          name: d.name,
          selected: enabled !== undefined ? enabled : initDepartments?.indexOf(d.id) >= 0
        })
      })
      companiesWithSelection.push({
        id: c.id,
        name: c.name,
        selected: enabled !== undefined ? enabled : initCompanies?.indexOf(c.id) >= 0,
        departments: departmentsWithSelection
      })
    })
  }

  return companiesWithSelection
}

const PermissionAndRolesSelect: React.FC<PermissionAndRolesSelectProps> = ({
  companies,
  initCompanies,
  initDepartments,
  onChange,
  disabled,
  sharedByAll,
  setFieldValue = null
}) => {
  const classes = styles()
  const [state, setState] = useState<Company[]>([])
  const [enabled, setEnabled] = useState<boolean>(
    companies.every(
      (x) =>
        initCompanies.some((y) => y === x.id) &&
        x.departments.every((d) => initDepartments.some((di) => di === d.id))
    )
  )

  useEffect(() => {
    const state = makeStateFromData(companies, initCompanies, initDepartments)
    setState(state)
  }, [companies, initCompanies, initDepartments])

  useEffect(() => {
    const allActive = state.every((x) => x.selected && x.departments.every((d) => d.selected))
    setEnabled(allActive)
  }, [state])

  return (
    <>
      <ListItem className={classes.listItem}>
        <FormControlLabel
          label={t(`Alla bolag`)}
          control={
            <Checkbox
              disabled={disabled}
              onChange={(e) => {
                const state = makeStateFromData(companies, initCompanies, initDepartments, !enabled)
                setState(state)
                setEnabled(e.target.checked)

                const checked = e.target.checked
                const newCompanies: Company[] = []

                state.forEach((c) => {
                  const newDepartments: Department[] = []
                  c.departments.forEach((d) => {
                    newDepartments.push({
                      ...d,
                      selected: checked
                    })
                  })
                  newCompanies.push({
                    ...c
                  })
                })
                setState(newCompanies)
                onChange && onChange(newCompanies)
                const departmentIds: number[] = []
                const companyIds: number[] = []

                newCompanies.forEach((c) => {
                  if (c.selected) companyIds.push(c.id)
                  c.departments.forEach((d) => {
                    if (d.selected) departmentIds.push(d.id)
                  })
                })
                setFieldValue && setFieldValue("departmentIds", departmentIds)
                setFieldValue && setFieldValue("companyIds", companyIds)
              }}
              id={`company`}
              checked={sharedByAll || enabled}
            />
          }
        />
      </ListItem>
      <List>
        {state.map((company) => {
          return (
            <React.Fragment key={company.id}>
              <ListItem className={classes.listItem}>
                {company.departments.length > 0 ? (
                  <StyleAccordion>
                    <StyleAccordionSummary
                      expandIcon={<FontAwesomeIcon icon={faChevronDown as IconProp} />}
                      aria-controls="settings">
                      <StyledFormControlLabelBold
                        control={
                          <Checkbox
                            disabled={disabled}
                            onChange={(e) => {
                              const checked = e.target.checked
                              const newCompanies: Company[] = []

                              state.forEach((c) => {
                                const newDepartments: Department[] = []
                                c.departments.forEach((d) => {
                                  newDepartments.push({
                                    ...d,
                                    selected: checked
                                  })
                                })

                                if (c.id === company.id) {
                                  newCompanies.push({
                                    ...c,
                                    selected: checked,
                                    departments: newDepartments
                                  })
                                } else {
                                  newCompanies.push({
                                    ...c
                                  })
                                }
                              })
                              setState(newCompanies)
                              onChange && onChange(newCompanies)
                              const departmentIds: number[] = []
                              const companyIds: number[] = []

                              newCompanies.forEach((c) => {
                                if (c.selected) companyIds.push(c.id)
                                c.departments.forEach((d) => {
                                  if (d.selected) departmentIds.push(d.id)
                                })
                              })
                              setFieldValue && setFieldValue("departmentIds", departmentIds)
                              setFieldValue && setFieldValue("companyIds", companyIds)
                            }}
                            id="company"
                            checked={sharedByAll || company.selected}
                          />
                        }
                        label={company.name}
                      />
                    </StyleAccordionSummary>
                    <StyleAccordionDetails>
                      {company.departments.map((department) => {
                        return (
                          <StyleSubListItem key={department.id}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  disabled={disabled}
                                  onChange={(e) => {
                                    const checked = e.target.checked

                                    const newCompanies: Company[] = []

                                    state.forEach((c) => {
                                      const newDepartments: Department[] = []
                                      let departmentFound = false
                                      c.departments.forEach((d) => {
                                        if (d.id === department.id) {
                                          departmentFound = true
                                          newDepartments.push({
                                            ...d,
                                            selected: checked
                                          })
                                        } else {
                                          newDepartments.push({
                                            ...d
                                          })
                                        }
                                      })
                                      if (departmentFound) {
                                        newCompanies.push({
                                          ...c,
                                          selected: checked ? checked : c.selected,
                                          departments: newDepartments
                                        })
                                      } else {
                                        newCompanies.push({
                                          ...c
                                        })
                                      }
                                    })

                                    setState(newCompanies)
                                    onChange && onChange(newCompanies)
                                    const departmentIds: number[] = []
                                    const companyIds: number[] = []

                                    newCompanies.forEach((c) => {
                                      if (c.selected) companyIds.push(c.id)
                                      c.departments.forEach((d) => {
                                        if (d.selected) departmentIds.push(d.id)
                                      })
                                    })
                                    setFieldValue && setFieldValue("departmentIds", departmentIds)
                                    setFieldValue && setFieldValue("companyIds", companyIds)
                                  }}
                                  id="department"
                                  checked={sharedByAll || department.selected}
                                />
                              }
                              label={department.name}
                            />
                          </StyleSubListItem>
                        )
                      })}
                    </StyleAccordionDetails>
                  </StyleAccordion>
                ) : (
                  <StyledFormControlLabelBold
                    control={
                      <Checkbox
                        disabled={disabled}
                        onChange={(e) => {
                          const checked = e.target.checked
                          const newCompanies: Company[] = []

                          state.forEach((c) => {
                            const newDepartments: Department[] = []
                            c.departments.forEach((d) => {
                              newDepartments.push({
                                ...d,
                                selected: checked
                              })
                            })

                            if (c.id === company.id) {
                              newCompanies.push({
                                ...c,
                                selected: checked,
                                departments: newDepartments
                              })
                            } else {
                              newCompanies.push({
                                ...c
                              })
                            }
                          })
                          setState(newCompanies)
                          onChange && onChange(newCompanies)
                          const departmentIds: number[] = []
                          const companyIds: number[] = []

                          newCompanies.forEach((c) => {
                            if (c.selected) companyIds.push(c.id)
                            c.departments.forEach((d) => {
                              if (d.selected) departmentIds.push(d.id)
                            })
                          })
                          setFieldValue && setFieldValue("departmentIds", departmentIds)
                          setFieldValue && setFieldValue("companyIds", companyIds)
                        }}
                        id="company"
                        checked={sharedByAll || company.selected}
                      />
                    }
                    label={company.name}
                  />
                )}
              </ListItem>
            </React.Fragment>
          )
        })}
      </List>
    </>
  )
}

export default PermissionAndRolesSelect
