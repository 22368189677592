import { faBuilding } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { Box, FormControl, InputAdornment, Select, Typography } from "@mui/material"
import MenuItem from "@mui/material/MenuItem"
import { styled } from "@mui/material/styles"
import { AppContext } from "components/AppContextProvider"
import theme from "css/theme/light.js"
import { useContext } from "react"

const StyledSelect = styled(Select)({
  color: theme.palette.primary["contrastText"],
  fontSize: "14px",
  fontWeight: 400,
  border: "0"
})

const StyledSelectText = styled(Box)({
  color: theme.palette.primary["contrastText"],
  fontSize: "14px",
  fontWeight: 400,
  display: "flex",
  padding: "4px 8px 5px 14px",
  "& span": {
    marginLeft: "8px"
  }
})

const CompanySelect = () => {
  const appContext = useContext(AppContext)

  return appContext.currentCompanyId ? (
    appContext?.appUser?.customer?.companies?.length > 1 ? (
      <FormControl sx={{ pr: "8px" }}>
        <StyledSelect
          IconComponent={ExpandMoreIcon}
          value={appContext.currentCompanyId}
          autoWidth
          variant="outlined"
          sx={{
            ".MuiSelect-icon": {
              color: theme.palette.primary["contrastText"]
            },
            ".css-16t0q34-MuiSelect-select-MuiInputBase-input-MuiInput-input:focus": {
              backgroundColor: "transparent"
            },
            ".MuiOutlinedInput-notchedOutline": {
              border: "0"
            }
          }}
          startAdornment={
            <InputAdornment position="start">
              <FontAwesomeIcon color={theme.palette.primary["main"]} size="lg" icon={faBuilding} />
            </InputAdornment>
          }>
          {appContext?.appUser?.customer?.companies?.map((company: any) => (
            <MenuItem
              key={company.id}
              value={company.id}
              sx={{
                fontWeight: company.id === appContext.currentCompanyId ? "bold" : "disabled",
                color: company.id !== appContext.currentCompanyId ? theme.palette.grey[700] : undefined
              }}
              onClick={() => appContext.setCurrentCompanyId(company.id)}>
              {company.name}
            </MenuItem>
          ))}
        </StyledSelect>
      </FormControl>
    ) : (
      <StyledSelectText>
        <FontAwesomeIcon color={theme.palette.primary["main"]} size="lg" icon={faBuilding} />
        <Typography variant="bodySmall">{appContext?.appUser?.customer?.companies[0]?.name}</Typography>
      </StyledSelectText>
    )
  ) : (
    <></>
  )
}

export default CompanySelect
