import { faTrashCan } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, Button, ButtonGroup, Container, Grid, IconButton } from "@mui/material"
import { hasRole } from "auth/hasRole"
import { useApi } from "auth/useApi3"
import { AppContext } from "components/AppContextProvider"
import ClpTable from "components/ClpTable"
import { GlobalLoaderContext } from "components/GlobalLoadingProvider"
import globalUseStyles from "css/theme/globalUseStyles.js"
import { apiEndpoints } from "generated/apiEndpoints"
import { allRoles } from "generated/roles"
import React, { useContext, useEffect, useMemo, useState } from "react"
import { ActionType } from "shared/vehicle/components/InspectionBookingSidebar.interfaces"
import { initialModalState, ModalDialog } from "shared/vehicle/components/ModalDialog"
import CustomFieldGroupForm from "./customFieldGroupForm"

const CustomFieldGroups = () => {
  const appContext = useContext(AppContext)
  const loadingContext = useContext(GlobalLoaderContext)
  const [listResult, listApi] = useApi()
  const [addResult, addApi] = useApi()
  const [updateResult, updateApi] = useApi()
  const [editItemResult, editItemApi] = useApi()
  const [deleteGroupResult, deleteGroupApi] = useApi()
  const [modalData, setModalData] = useState(initialModalState)
  const [clickedGroup, setClickedGroup] = useState()
  const [editMode, setEditMode] = useState(false)
  const [showSidebarForm, setShowSidebarForm] = useState(false)
  const globalClasses = globalUseStyles()

  useEffect(() => {
    if (updateResult.status === 2 || addResult.status === 2 || deleteGroupResult.status === 2) {
      listApi.get(apiEndpoints.vehicleregistryadmin.getcustomfieldgroups)
      setShowSidebarForm(false)
    }
  }, [updateResult, addResult, deleteGroupResult])

  useEffect(() => {
    loadingContext.setLoading(() => listResult.status === 1)
  })

  useEffect(() => {
    listApi.get(apiEndpoints.vehicleregistryadmin.getcustomfieldgroups)
  }, [listApi])

  const openEdit = (id) => {
    setEditMode(true)
    setShowSidebarForm(true)
    editItemApi.get(apiEndpoints.vehicleregistryadmin.getcustomfieldgroupbyid.replace("{id}", id))
    updateApi.reset()
    addApi.reset()
    deleteGroupApi.reset()
  }

  const openAdd = () => {
    setEditMode(false)
    setShowSidebarForm(true)
    editItemApi.reset()
    updateApi.reset()
    addApi.reset()
    deleteGroupApi.reset()
  }

  const onAddSubmit = (values) => {
    addApi.put(apiEndpoints.vehicleregistryadmin.upsertcustomfieldgroup, values)
  }

  const onUpdateSubmit = (values) => {
    updateApi.put(apiEndpoints.vehicleregistryadmin.upsertcustomfieldgroup, values)
  }

  const columns = useMemo(
    () => [
      {
        accessor: "name",
        Header: "Namn"
      },
      {
        accessor: (row) => {
          return row?.customFieldNames ? row.customFieldNames.map(customField => customField.name).join(", ") : ""
        },
        Header: "Fält"
      },
      {
        accessor: (row) => {
          return row?.vehiclesInfo ? row.vehiclesInfo.map(vehicles => vehicles.regNumber).join(", ") : ""
        },
        Header: "Fordon"
      }
    ],
    []
  )

  useEffect(() => {
    if (hasRole(appContext.appUser, allRoles.VehicleRegistryAdmin)) {
      columns.push({
        accessor: "action",
        Cell: ({ cell }) => {
          return (
            <IconButton sx={{ padding: 0 }} onClick={() => {
              const groupName = cell.row.original.name
              setClickedGroup(cell.row.original)
              setModalData({
                actionType: ActionType.Confirm,
                isOpen: true,
                title: "Radera grupp",
                message: `Är du säker på att du vill radera ${groupName}?`
              })
            }}>
              <FontAwesomeIcon icon={faTrashCan} size="xs" />
            </IconButton>
          )
        },
        align: "right",
        Header: "",
        hiddenFilter: true
      })
    }
  }, [])

  return (
    <>
      <ModalDialog
        modalData={modalData}
        setModalData={setModalData}
        setShowSidebar={setShowSidebarForm}
        handleSubmit={() => {
          const groupId = clickedGroup?.id
          if (!groupId) return
          const url = apiEndpoints.vehicleregistryadmin.deletecustomfieldgroup.replace("{customFieldGroupId}", groupId)
          deleteGroupApi.del(url)
        }}
      />
      <Container>
        <CustomFieldGroupForm
          showSidebar={showSidebarForm}
          setShowSidebarForm={setShowSidebarForm}
          editItemResult={editItemResult}
          addResult={addResult}
          updateResult={updateResult}
          editMode={editMode}
          onAddSubmit={onAddSubmit}
          onUpdateSubmit={onUpdateSubmit}
        />
        <Box mb={4}>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <ButtonGroup color="primary">
              <Button
                color="primary"
                onClick={() => {
                  openAdd()
                }}
                variant="contained">
                Lägg till grupp
              </Button>
            </ButtonGroup>
          </Grid>
        </Box>
      </Container>
      <Box className={globalClasses.tableContainer}>
        <ClpTable
          columns={columns}
          cellProps={(row) => ({
            onClick: () => {
              openEdit(row.original.id)
            }
          })}
          rows={listResult.status === 2 && listResult.data}
          status={listResult.status}
        />
      </Box>
    </>
  )
}

export default CustomFieldGroups