// Renders components for the routes
import { makeStyles } from "@mui/styles"
import ExternalWorkorder from "pages/external/pages/workorder"
import { useContext, useEffect, useState } from "react"
import { Route, Switch } from "react-router-dom"
import { AppContext } from "./AppContextProvider"

const ExternalPagesRouter = (props) => {
  const appContext = useContext(AppContext)

  const [routes, setRoutes] = useState([])

  useEffect(() => {
    setRoutes(appContext.appUser.app.routes)
  }, [appContext])

  const useStyles = makeStyles((theme) => ({
    content: {
      flexGrow: 1,
      background: theme.palette.background.default,
      minHeight: "100vh"
    },
    root: {
      paddingTop: "88px",
      [theme.breakpoints.up("sm")]: {
        paddingTop: "48px",
        height: "100%",
        "& > div": {
          position: "relative",
          height: "100%"
        }
      }
    }
  }))

  const classes = useStyles()
  return (
    <main className={classes.content}>
      <div className={classes.root}>
        <Switch>
          <Route
            exact
            {...props}
            path="/external/workorder/:guid"
            render={(props) => {
              return <ExternalWorkorder {...props} />
            }}></Route>
        </Switch>
      </div>
    </main>
  )
}
export default ExternalPagesRouter
