import { Autocomplete, Box, ListItem, ListItemText, Stack, TextField } from "@mui/material"
import React from "react"
import { useStyles } from "../../../components/CustomForms/useStyles"

const DashboardSelect = (props) => {
  const { vehiclesResult, setChosenVehicle, setActiveIssueReports } = props
  const classes = useStyles()

  return (
    <ListItem className={classes.listItem}>
      <Box>
        <ListItemText
          classes={{
            secondary: classes.listItemText,
            primary: classes.listItemTextPrimary
          }}
          style={{ paddingBottom: "8px" }}
          primary="Välj fordon"
        />
      </Box>

      <Autocomplete
        className={classes.fullWidth}
        id="vehicleId"
        options={vehiclesResult.data}
        getOptionLabel={(option) => option.internnumber && option.internnumber}
        value={vehiclesResult.data.find((i) => i.id === i.vehicleId)}
        getOptionSelected={(option, value) => {
          return option.id === value.id
        }}
        onChange={(e, value) => {
          setChosenVehicle(value)
          setActiveIssueReports(value)
        }}
        renderOption={(props, option) => (
          <li {...props}>
            <Stack direction="row" spacing={2} justifyContent="space-between">
              <Box sx={{ fontWeight: 600, minWidth: "100px" }}>{option.internnumber}</Box>
              <Box sx={{ opacity: 0.6 }}>{option.regNumber}</Box>
            </Stack>
          </li>
        )}
        renderInput={(params) => <TextField {...params} variant="outlined" name="vehicleId" />}
      />
    </ListItem>
  )
}

export default DashboardSelect
