import { faRefresh } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, Button, Container, Grid, Typography } from "@mui/material"
import { useApi } from "auth/useApi3"
import { GlobalLoaderContext } from "components/GlobalLoadingProvider"
import PaperBox from "components/PaperBox"
import theme from "css/theme/light"
import { apiEndpoints } from "generated/apiEndpoints"
import { useContext, useEffect, useState } from "react"
import FormPreviewFileThumbnail from "../../components/Form/FormComponents/FormUploadFile/FormPreviewFileThumbnail"
import FormUploadFile from "../../components/Form/FormComponents/FormUploadFile/FormUploadFile"
import UnitDetails from "./components/details/UnitDetails"
import UnitResult from "./components/result/UnitResult"
import NoSearchData from "./components/search/NoSearchData"
import SearchBar from "./components/search/SearchBar"
import { SkeletonDetails } from "./skeletons/SkeletonDetails"
import { SkeletonResultCard } from "./skeletons/SkeletonResultCard"

const Assembly = () => {
  const [clickedRegnr, setRegnr] = useState({ type: "regNr", value: null })
  const [assemblyResult, assemblyApi] = useApi()
  const [attachments, setAttachments] = useState([])
  const loadingContext = useContext(GlobalLoaderContext)
  const [fileResult, fileApi] = useApi()
  const [fileUploadResult, fileUploadApi] = useApi()

  const updateAssembly = () => {
    assemblyApi.get(
      apiEndpoints.assembly.getassemblydetails
        .replace("{value}", clickedRegnr.value)
        .replace("{type}", clickedRegnr.type)
    )
  }

  const removeFile = (filePath) => {
    const fileParams = filePath.split("/")
    const feature = fileParams.slice(0, -1).join("/")
    const fileName = fileParams[fileParams.length - 1]
    const url =
      apiEndpoints.attachment.deleteattachment.replace("{fileid}", fileName) +
      `?global=true&feature=${feature}`
    fileApi.del(url).then((_) => {
      setAttachments(
        attachments.filter((attachment) => {
          const filePath = attachment.fileId.split("/")
          const attachmentFileName = filePath[filePath.length - 1]
          return attachmentFileName !== fileName
        })
      )
    })
  }

  useEffect(() => {
    loadingContext.setLoading(() => assemblyResult.status === 1)
  })

  useEffect(() => {
    clickedRegnr.value && updateAssembly()
    setAttachments(assemblyResult?.data?.attachments && assemblyResult?.data?.attachments)
  }, [clickedRegnr, assemblyApi])

  const handleChange = (files) => {
    if (files.length <= 0) return
    const regNr = assemblyResult?.data?.generalInfo?.find((x) => x.name === "Regnr")?.value
    const url = apiEndpoints.attachment.addattachment.replace("{feature}", "assembly" + "?containerName=" + regNr) + "&global=true"
    files.forEach(file => {
      fileUploadApi.file(url, file)
    })
  }

  useEffect(() => {
    if (fileUploadResult.status !== 2) return
    const newAttachments = fileUploadResult.data.map((file) => ({
      fileId: file.fileId,
      base64: file.base64,
      name: file.fileName
    }))
    setAttachments(prevStats => [...newAttachments, ...prevStats])
  }, [fileUploadResult])

  useEffect(() => {
    if (assemblyResult.status === 2) {
      setAttachments(assemblyResult?.data?.attachments)
    }
  }, [assemblyResult])

  function UploadImageComponent() {
    return (
      assemblyResult.status === 2 && (
        <>
          {assemblyResult.status === 2 &&
            assemblyResult?.data?.generalInfo?.find((x) => x.name === "Regnr")?.value !== null && (
              <Grid item xs={12}>
                <FormUploadFile
                  handleChange={handleChange}
                />
              </Grid>
            )}

          {attachments &&
            attachments.map((attachment) => (
              <Grid item xs={12} md={6}>
                <FormPreviewFileThumbnail
                  Data={attachment.base64}
                  fileId={attachment.fileId}
                  fileName={attachment.name}
                  removeFile={removeFile}
                />
              </Grid>
            ))}
        </>
      )
    )
  }

  return (
    <Container sx={{ pb: "24px" }}>
      <Box display="flex" justifyContent="space-between">
        <Box mb={4}>
          <Typography variant="h1">Montering</Typography>
        </Box>
        <Box>
          <Button
            onClick={() => updateAssembly()}
            endIcon={<FontAwesomeIcon icon={faRefresh} />}
            variant="contained"
            color="primary">
            Uppdatera data
          </Button>
        </Box>
      </Box>
      <Grid container justifyContent="space-between">
        <Grid item md={4} xs={12}>
          <PaperBox marginY={0}>
            <SearchBar clickedRegnr={clickedRegnr} setRegnr={setRegnr} />
          </PaperBox>
        </Grid>
      </Grid>

      <Grid mt={4} container spacing={2}>
        <Grid item md={4} xs={12}>
          {clickedRegnr.value !== null && assemblyResult.status !== 3 ? (
            <Grid container spacing={2}>
              {assemblyResult.status === 1 ? (
                <SkeletonResultCard />
              ) : (
                <UnitResult result={assemblyResult.status === 2 && assemblyResult?.data} />
              )}
              <UploadImageComponent />
            </Grid>
          ) : (
            <NoSearchData
              title={
                assemblyResult.status === 3
                  ? assemblyResult.messages.map((item) => {
                      return (
                        <>
                          <Typography variant="h1">
                            {item} för:{" "}
                            <Box display="inline-block" color={theme.palette.primary.main}>
                              {clickedRegnr.value}
                            </Box>
                          </Typography>
                        </>
                      )
                    })
                  : "Ingen enhet vald"
              }
              text={assemblyResult.status !== 3 && `Sök i listan för att hämta monteringsstatus på enhet`}
            />
          )}
        </Grid>
        {clickedRegnr.value !== null && assemblyResult.status !== 3 && (
          <Grid item md={8} xs={12}>
            {assemblyResult.status === 1 ? (
              <PaperBox marginY={0}>
                <SkeletonDetails />
              </PaperBox>
            ) : (
              <PaperBox marginY={0}>
                <UnitDetails
                  category="Grundinfo"
                  fields={assemblyResult.data?.generalInfo?.length > 0 && assemblyResult.data?.generalInfo}
                />
                <UnitDetails
                  category="Position"
                  fields={assemblyResult.data?.positions?.length > 0 && assemblyResult.data?.positions}
                />
                <UnitDetails
                  category="Canbus"
                  fields={
                    assemblyResult.data?.mastercanData?.length > 0 && assemblyResult.data?.mastercanData
                  }
                />
              </PaperBox>
            )}
          </Grid>
        )}
      </Grid>
    </Container>
  )
}

export default Assembly
