import { faCircleCheck, faClock, faExclamationCircle, faUsers } from "@fortawesome/pro-solid-svg-icons"
import { Box, Container, Grid, Typography } from "@mui/material"
import { useApi } from "auth/useApi3"
import Button from "components/Button"
import ContinueDialog from "components/Dialogs/ContinueDialog"
import { GlobalLoaderContext } from "components/GlobalLoadingProvider"
import { apiEndpoints } from "generated/apiEndpoints"
import { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { AddUserToExamDialogForm } from "./components/AddUserToExamDialogForm"
import { AttendeeCountCard } from "./components/AttendeeCountCard"
import { AttendeeTable } from "./components/AttendeeTable"
import ReminderSettingsForm from "./components/ReminderSettingsForm.js"
import SkeletonPage from "./components/SkeletonPage"

const AdminLearningExamInfo = (props) => {
  const { match } = props

  const { t } = useTranslation("translation", { keyPrefix: "learning" })

  const [examSummaryResult, examSummaryApi] = useApi()
  const [attendeesResult, attendeesApi] = useApi()
  const [updateSettingsResult, updateSettingsApi] = useApi()
  const [examSettingsResult, examSettingsApi] = useApi()
  const [sendReminderResult, sendReminderApi] = useApi()
  const [sendReminderToAllResult, sendReminderToAllApi] = useApi()
  const [addUserResult, addUserApi] = useApi()
  const [showAddUserDialog, setShowAddUserDialog] = useState(false)
  const [clickedItem, setClickedItem] = useState()
  const [sendReminderToAllModal, setShowReminderToAllModal] = useState(false)
  const [showReminderModal, setShowReminderModal] = useState(false)
  const [clickedRowIds, setClickedRowIds] = useState({
    userExaminationId: null,
    userId: null,
    userName: ""
  })

  useEffect(() => {
    const initLoad = async () => {
      examSummaryApi.get(apiEndpoints.learningadmin.getexamsummary.replace("{id}", match.params.id))
      attendeesApi.get(apiEndpoints.learningadmin.getattendeestableforexam.replace("{id}", match.params.id))
      examSettingsApi.get(apiEndpoints.learningadmin.getexamsettings.replace("{id}", match.params.id))
    }

    initLoad()
  }, [examSummaryApi, attendeesApi, examSettingsApi])

  const loadingContext = useContext(GlobalLoaderContext)

  useEffect(() => {
    loadingContext.setLoading(() => examSummaryResult.status === 1)
  })
  useEffect(() => {
    loadingContext.setLoading(() => attendeesResult.status === 1)
  })
  useEffect(() => {
    loadingContext.setLoading(() => examSettingsResult.status === 1)
  })

  const handleSettingsSubmit = (values) => {
    updateSettingsApi.put(apiEndpoints.learningadmin.updateexaminationsettings, values)
  }

  const handleAddParticipants = (values) => {
    addUserApi.post(apiEndpoints.learningadmin.addattendeetoexam, values)
  }

  const handleSendRemindersToAll = () => {
    sendReminderToAllApi.post(
      apiEndpoints.learningadmin.sendexamreminderstoattendees.replace("{customerExamId}", match.params.id)
    )
    setShowReminderToAllModal(false)
  }

  const handleSendReminder = () => {
    if (clickedRowIds.userExaminationId && clickedRowIds.userId) {
      sendReminderApi.post(
        apiEndpoints.learningadmin.sendremindertoattendee
          .replace("{userExaminationId}", clickedRowIds.userExaminationId)
          .replace("{userId}", clickedRowIds.userId)
      )
      setShowReminderModal(false)
    }
  }

  useEffect(() => {
    if (
      addUserResult.status === 2 ||
      updateSettingsResult.status === 2 ||
      sendReminderResult.status === 2 ||
      sendReminderToAllResult.status === 2
    ) {
      setShowAddUserDialog(false)
      attendeesApi.get(apiEndpoints.learningadmin.getattendeestableforexam.replace("{id}", match.params.id))
    }
  }, [addUserResult, updateSettingsResult, sendReminderResult, sendReminderToAllResult])

  return examSummaryResult.status === 2 ? (
    <div>
      <Container>
        {sendReminderToAllModal && (
          <ContinueDialog
            result={sendReminderToAllResult}
            setShowContinueModal={setShowReminderToAllModal}
            onAddSubmit={handleSendRemindersToAll}
            message={t(`admin.overview.send_reminders_to_all`)}
            continueBtnText={t(`admin.overview.buttons.send_reminders`)}
            cancelBtnText={t(`admin.overview.buttons.cancel`)}
          />
        )}

        {showReminderModal && clickedRowIds && (
          <ContinueDialog
            result={sendReminderResult}
            setShowContinueModal={setShowReminderModal}
            onAddSubmit={handleSendReminder}
            // message={`Vill du skicka påminnelser till ${clickedRowIds.userName}?`}
            message={t(`admin.overview.send_reminder_to`) + " " + clickedRowIds.userName + "?"}
            continueBtnText={t(`admin.overview.buttons.send_reminder`)}
            cancelBtnText={t(`admin.overview.buttons.cancel`)}
          />
        )}

        <Box marginY={3}>
          <Grid item container direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h2">{examSummaryResult.data.examinationName}</Typography>

            <Box>
              <Button
                sx={{ mr: 1 }}
                color="primary"
                onClick={() => {
                  setShowReminderToAllModal(true)
                }}>
                {t(`admin.overview.buttons.send_reminders`)}
              </Button>
            </Box>
          </Grid>
          <AddUserToExamDialogForm
            result={addUserResult}
            setShowAddUserDialog={setShowAddUserDialog}
            handleAddParticipants={handleAddParticipants}
            setClickedItem={setClickedItem}
            clickedItem={clickedItem}
            onCancel={() => {
              setShowAddUserDialog(false)
            }}
            onSelect={(item) => {}}
            showModalOnClick={showAddUserDialog}
            customerExaminationId={match.params.id}
          />
        </Box>

        <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={3} mb={3}>
          <AttendeeCountCard
            data={examSummaryResult.data.attendeesCount}
            subtitle={t(`admin.titles.participant`)}
            icon={faUsers}
          />

          <AttendeeCountCard
            color="success"
            data={examSummaryResult.data.passedCount}
            subtitle={t(`admin.titles.approved`)}
            icon={faCircleCheck}
          />

          <AttendeeCountCard
            color="warning"
            data={examSummaryResult.data.expiresIn30Days}
            subtitle={t(`admin.titles.expiresIn30Days`)}
            icon={faClock}
          />
          <AttendeeCountCard
            color="error"
            data={examSummaryResult.data.expired}
            subtitle={t(`admin.titles.expires`)}
            icon={faExclamationCircle}
          />
        </Grid>

        <ReminderSettingsForm
          result={updateSettingsResult}
          examSettingsResult={examSettingsResult.status === 2 && examSettingsResult.data}
          handleSettingsSubmit={handleSettingsSubmit}
          examSummaryResult={examSummaryResult.status === 2 && examSummaryResult.data}
        />
      </Container>
      <Box marginBottom={2}></Box>
      <AttendeeTable
        setClickedRowIds={setClickedRowIds}
        setShowReminderModal={setShowReminderModal}
        items={attendeesResult}
        examinationName={examSummaryResult?.data?.examinationName}
      />
    </div>
  ) : (
    <SkeletonPage />
  )
}

export default AdminLearningExamInfo
