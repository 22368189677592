import { faFlag, faMemoCircleCheck } from "@fortawesome/pro-solid-svg-icons"
import OpenInNewIcon from "@mui/icons-material/OpenInNew"
import { Box, Button, Grid, Typography } from "@mui/material"
import { CircleIcon } from "components/icons/CircleIcon"
import theme from "css/theme/light"
import { allRoutes } from "generated/routes"
import { issueReportStatusColor } from "helpers/IssueReports/IssueReportStatusColor"
import { issueReportStatusLabel } from "helpers/IssueReports/IssueReportStatusLabel"
import { workorderStatusColor } from "helpers/WorkOrder/WorkOrderStatusColor"
import { workorderStatusLabel } from "helpers/WorkOrder/WorkOrderStatusLabel"
import { default as React, useEffect, useState } from "react"
import Moment from "react-moment"
import { Link } from "react-router-dom"

const assignmentTranslation = (assignment) => {
  switch (assignment.assignmentType) {
    case 1:
      return {
        title: "Felrapporter",
        route: allRoutes.IssuereportsArchiveIssuereport(assignment.id),
        routeTitle: "Öppna felrapport",
        icon: faFlag
      }
    case 2:
      return {
        title: "Arbetsorder",
        route: allRoutes.IssuereportsWorkordersDetails(assignment.id),
        routeTitle: "Öppna arbetsorder",
        icon: faFlag
      }
    default:
      return null
  }
}

const DashboardCard = ({ assignment, filteredIssueReports }) => {
  const [assignmentData, setAssignmentData] = useState()
  useEffect(() => {
    setAssignmentData(assignmentTranslation(assignment))
  }, [assignment])

  return (
    <Box
      gap={2}
      mb={filteredIssueReports && filteredIssueReports.length === 1 ? 0 : 2}
      sx={{ p: "20px" }}
      borderBottom={filteredIssueReports && filteredIssueReports.length === 1 ? 0 : 1}
      borderColor="#EEEEEE">
      <Box display="flex" flexDirection="row" alignItems="center">
        <Box mr={3}>
          <CircleIcon
            icon={assignment.assignmentType === 1 ? faFlag : faMemoCircleCheck}
            color={`${
              assignment.assignmentType === 1
                ? issueReportStatusColor(assignment.status)
                : workorderStatusColor(assignment.status)
            }`}
            size="lg"
          />
        </Box>
        <Grid container>
          <Grid sm={12} display="flex" flexDirection="row" flexWrap="wrap" alignItems="center">
            <Box pr={1}>
              {assignment?.assignmentType === 1 ? (
                <Typography variant="bodySmall" color={`${issueReportStatusColor(assignment.status)}.main`}>
                  {issueReportStatusLabel(assignment.status)}
                </Typography>
              ) : (
                <Typography variant="bodySmall" color={`${workorderStatusColor(assignment.status)}.main`}>
                  {workorderStatusLabel(assignment.status)}
                </Typography>
              )}
            </Box>
            <Box pr={1}>
              <Typography variant="bodySmall">{assignmentData?.title}</Typography>
            </Box>
            <Box pr={1}>
              <Typography variant="bodySmall" fontWeight="600">
                {assignment?.label ? `${assignment.assignmentType === 2 ? "#" : ""}${assignment.label}` : "-"}
              </Typography>
            </Box>
            <Box flexGrow={2}>
              <Typography variant="bodySmall" color="textSecondary">
                {assignment.secondaryLabel ? assignment.secondaryLabel : "-"}
              </Typography>
            </Box>

            <Box>
              <Typography variant="bodySmall" color="textSecondary ">
                {assignment?.updatedDate ? (
                  <Moment utc local format="YYYY-MM-DD HH:mm">
                    {assignment.updatedDate}
                  </Moment>
                ) : (
                  "-"
                )}
              </Typography>
            </Box>
          </Grid>

          <Grid md={9} xl={10} xs={12} pt={1}>
            <Typography variant="bodyMedium" fontWeight={600} sx={{ wordBreak: "break-word" }}>
              {assignment?.description ? assignment.description : "-"}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Grid container direction="row" justifyContent="flex-end" alignItems="center">
        <Button
          component={Link}
          variant="text"
          to={assignmentData?.route}
          endIcon={<OpenInNewIcon style={{ color: theme.palette.primary["main"] }} />}
          fontSize="inherit">
          <Typography variant="labelSmallBold" color="textPrimary">
            {assignmentData?.routeTitle}
          </Typography>
        </Button>
      </Grid>
    </Box>
  )
}

export default DashboardCard
