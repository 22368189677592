import { faEdit, faTrashAlt } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, Button, IconButton } from "@mui/material"
import { IApiFunc, IApiResult } from "auth/interface/api.interface"
import { useApi } from "auth/useApi"
import { AppContext } from "components/AppContextProvider"
import ClpTable from "components/ClpTable"
import ContinueDialog from "components/Dialogs/ContinueDialog"
import { Sidebar } from "components/navigation/Sidebar"
import globalUseStyles from "css/theme/globalUseStyles"
import { apiEndpoints } from "generated/apiEndpoints"
import { useContext, useEffect, useState } from "react"
import { ICustomerCustomRole } from "./customercustomrole.interface"
import CustomRoleForm from "./CustomRoleForm"
const CustomRoleList = (props: { isSystemAdmin: boolean; customerId: number }) => {
  const { isSystemAdmin, customerId } = props

  const globalClasses = globalUseStyles()
  const appContext = useContext(AppContext)
  const [roleResult, listRoleApi] = useApi() as [IApiResult, IApiFunc]
  const [addRoleResult, addRole] = useApi() as [IApiResult, IApiFunc]
  const [roleDetailsResult, roleDetails] = useApi() as [IApiResult, IApiFunc]
  const [deleteRoleResult, deleteRole] = useApi() as [IApiResult, IApiFunc]
  const [initialValues, setInitialValues] = useState<ICustomerCustomRole>({
    id: 0,
    name: ""
  })
  const [deleteDialog, setDeleteDialog] = useState<{
    open: boolean
    selectedId: number
  }>({ open: false, selectedId: 0 })

  const handleSubmit = (values: ICustomerCustomRole) => {
    if (isSystemAdmin) {
      values.customerId = customerId
    }
    if (values.id > 0) {
      isSystemAdmin
        ? addRole.put(apiEndpoints.systemadmincustomers.updatecustomercustomrole, values)
        : addRole.put(apiEndpoints.customeradmin.updatecustomercustomrole, values)
    } else {
      isSystemAdmin
        ? addRole.post(apiEndpoints.systemadmincustomers.addcustomercustomrole, values)
        : addRole.post(apiEndpoints.customeradmin.addcustomercustomrole, values)
    }
  }

  const handleEdit = (id: number) => {
    isSystemAdmin
      ? roleDetails.get(
          apiEndpoints.systemadmincustomers.getcustomercustomrolebyid.replace("{id}", id.toString())
        )
      : roleDetails.get(apiEndpoints.customeradmin.getcustomercustomrolebyid.replace("{id}", id.toString()))
  }

  const handleDelete = () => {
    isSystemAdmin
      ? deleteRole.del(
          apiEndpoints.systemadmincustomers.deletecustomercustomrole.replace(
            "{id}",
            deleteDialog.selectedId.toString()
          )
        )
      : deleteRole.del(
          apiEndpoints.customeradmin.deletecustomercustomrole.replace(
            "{id}",
            deleteDialog.selectedId.toString()
          )
        )
  }
  const openDeleteDialog = (id: number) => {
    setDeleteDialog({ open: true, selectedId: id })
  }

  useEffect(() => {
    isSystemAdmin
      ? listRoleApi.get(
          apiEndpoints.systemadmincustomers.getcustomercustomroles.replace(
            "{customerid}",
            customerId.toString()
          )
        )
      : listRoleApi.get(apiEndpoints.customeradmin.getcustomercustomroles)
  }, [])

  useEffect(() => {
    if (addRoleResult.status === 2) {
      isSystemAdmin
        ? listRoleApi.get(
            apiEndpoints.systemadmincustomers.getcustomercustomroles.replace(
              "{customerid}",
              customerId.toString()
            )
          )
        : listRoleApi.get(apiEndpoints.customeradmin.getcustomercustomroles)
      appContext.toggleSideBar()
    }
  }, [addRoleResult])

  useEffect(() => {
    if (deleteRoleResult.status === 2) {
      isSystemAdmin
        ? listRoleApi.get(
            apiEndpoints.systemadmincustomers.getcustomercustomroles.replace(
              "{customerid}",
              customerId.toString()
            )
          )
        : listRoleApi.get(apiEndpoints.customeradmin.getcustomercustomroles)
      setDeleteDialog({ open: false, selectedId: 0 })
    }
  }, [deleteRoleResult])

  useEffect(() => {
    if (roleDetailsResult.status === 2) {
      setInitialValues(roleDetailsResult.data)

      !appContext.isSidebarOpen && appContext.toggleSideBar(true)
    }
  }, [roleDetailsResult])

  useEffect(() => {
    if (!appContext.isSidebarOpen) {
      setInitialValues({
        id: 0,
        name: ""
      })
    }
  }, [appContext.isSidebarOpen])

  const columns = [
    {
      accessor: "name",
      Header: "Roll"
    },

    {
      accessor: "edit",
      numeric: false,
      disablePadding: true,
      Header: "",
      align: "right",
      Cell: ({ cell }: any) => {
        return (
          <>
            <IconButton size="large" onClick={() => handleEdit(cell.row.original.id)}>
              <FontAwesomeIcon icon={faEdit} size="xs" />
            </IconButton>
            <IconButton size="large" onClick={() => openDeleteDialog(cell.row.original.id)}>
              <FontAwesomeIcon icon={faTrashAlt} size="xs" />
            </IconButton>
          </>
        )
      }
    }
  ]

  return (
    <>
      <Sidebar>
        <CustomRoleForm initialValues={initialValues} handleSubmit={handleSubmit} results={addRoleResult} />
      </Sidebar>

      <ContinueDialog
        onAddSubmit={handleDelete}
        message={`Vill du ta bort roll?`}
        continueBtnText="Ta bort roll"
        cancelBtnText="Avbryt"
        result={deleteRoleResult}
        setShowContinueModal={(value: any) => setDeleteDialog({ open: value, selectedId: 0 })}
        isOpen={deleteDialog.open}
      />

      <Box mb={2}>
        <Box display="flex" flexDirection="row" justifyContent="flex-end">
          <Box order={3} p={1}>
            <Button onClick={() => appContext.toggleSideBar()} variant="contained" color="primary">
              Lägg till roll
            </Button>
          </Box>
        </Box>
      </Box>
      <Box className={globalClasses.tableContainer}>
        <ClpTable
          rows={roleResult.status === 2 && roleResult.data}
          status={roleResult.status}
          columns={columns}
        />
      </Box>
    </>
  )
}
export default CustomRoleList
