import { Container, Grid, Typography } from "@mui/material"
import { useApiGet } from "auth/useApi2"
import { GlobalLoaderContext } from "components/GlobalLoadingProvider"
import { TabPanel } from "components/navigation/TabPanel"
import PaperBox from "components/PaperBox"
import { SkeletonCard } from "components/skeleton/SkeletonCard"
import SubTabs from "components/SubTabs"
import { apiEndpoints } from "generated/apiEndpoints"
import { useContext, useEffect, useState } from "react"
import CustomRoleList from "shared/customer/CustomRoles/CustomRoleList"
import { EditCustomerForm } from "../../components/EditCustomerForm"

const tabData = [
  { index: 0, label: "Kunduppgifter", queryParam: "customerSettings" },
  { index: 1, label: "Roller", queryParam: "roles" }
]

const EditCustomer = () => {
  const [customerResult, getCustomer] = useApiGet()
  const [logoResult, getLogoData] = useApiGet()
  const [tabValue, setTabValue] = useState(0)
  const loadingContext = useContext(GlobalLoaderContext)

  useEffect(() => {
    getCustomer(apiEndpoints.customeradmin.getcustomer)
  }, [getCustomer])
  useEffect(() => {
    if (customerResult.data && customerResult.data.logoName) {
      getLogoData(
        apiEndpoints.customeradmin.getcustomerlogobyname.concat("?name=" + customerResult.data.logoName)
      )
    }
  }, [customerResult, getLogoData])

  useEffect(() => {
    loadingContext.setLoading(() => customerResult.status === 1)
  })
  useEffect(() => {
    loadingContext.setLoading(() => logoResult.status === 1)
  })

  return (
    <>
      {customerResult.status === 2 ? (
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h1" gutterBottom>
                Kunduppgifter
              </Typography>
            </Grid>
          </Grid>
          <SubTabs tabs={tabData} value={tabValue} setValue={setTabValue} />
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <PaperBox>
                <TabPanel value={tabValue} index={0}>
                  <EditCustomerForm initialValues={customerResult.data} logoResult={logoResult} />
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                  <CustomRoleList />
                </TabPanel>
              </PaperBox>
            </Grid>
          </Grid>
        </Container>
      ) : (
        <Container>
          <SkeletonCard />
        </Container>
      )}
    </>
  )
}
export default EditCustomer
