import { styled } from "@mui/material/styles"
import { CardMedia, Dialog } from "@mui/material"
import ExtendedCardMediaProps from "./FormPreviewFileThumbnail.interfaces"

export const MaterialDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(0, 2, 1, 2)
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1)
  },
  "& .MuiPaper-root": {
    maxWidth: "70%",
    borderRadius: "12px",
    [theme.breakpoints.between("xs", "md")]: {
      margin: 0,
      maxWidth: "80%"
    }
  },
  "& .MuiBackdrop-root": {
    backgroundColor: "rgba(0, 0, 0, 0.30)"
  }
}))

export const StyledCardMedia = styled(CardMedia)<ExtendedCardMediaProps>({
  display: "flex",
  justifyContent: "center"
})