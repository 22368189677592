import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Grid, Tab, Tabs, useTheme } from "@mui/material"
import useMediaQuery from "@mui/material/useMediaQuery"
import { useEffect, useState } from "react"
import { NavLink, useLocation } from "react-router-dom"
import styles from "./style"

export const PageTabs = ({ tabs, routeBack }) => {
  const [selectedPath, setPath] = useState()
  const classes = styles()
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up("sm"))

  const location = useLocation()

  useEffect(() => {
    if (tabs.length > 0) {
      setPath(location.pathname)
    }
  }, [location.pathname, tabs])

  const handleChange = (_event, newValue) => {
    setPath(newValue)
  }

  const backText = tabs && tabs.length > 0 && tabs[0].title ? tabs[0].title : "Tillbaka"

  return tabs.length > 0 && !routeBack && selectedPath ? (
    <div style={{ width: "100%" }}>
      <Tabs
        value={selectedPath}
        onChange={handleChange}
        className={classes.tabsLevel2}
        indicatorColor="primary"
        classes={{
          indicator: classes.bigIndicator,
          scroller: classes.scroller,
          root: classes.tabsRoot
        }}
        variant={"scrollable"}
        scrollButtons={matches && "auto"}>
        {tabs.map((item, index) => {
          return (
            <Tab
              key={item.title}
              component={NavLink}
              to={item.path}
              label={item.title}
              value={item.path}
              isActive={(match, location) => {
                if (!match) {
                  return false
                }
                setPath(match.url)
              }}
            />
          )
        })}
      </Tabs>
    </div>
  ) : (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      className={classes.tabsLevel2}>
      <NavLink to={tabs[0].path}>
        <FontAwesomeIcon icon={faChevronLeft} size="xs" /> {backText}
      </NavLink>
    </Grid>
  )
}
