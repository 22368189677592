import makeStyles from "@mui/styles/makeStyles"

export const useStyles = makeStyles((theme) => ({
  folderFrame: {
    display: "block",
    borderRadius: 4,
    padding: "8px 15px",
    background: theme.palette.background.paper,
    "&.active": {
      background: theme.palette.primary["light"],
      borderColor: theme.palette.primary["dark"]
    }
  }
}))
