import { useApi } from "auth/useApi3"
import React, { useContext, useEffect, useMemo, useState } from "react"
import { AppContext } from "components/AppContextProvider"
import { apiEndpoints } from "generated/apiEndpoints"
import { hasRole } from "auth/hasRole"
import { allRoles } from "generated/roles"
import { Box, Container, Grid, IconButton, MenuItem, Typography, useTheme } from "@mui/material"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEdit, faPlus } from "@fortawesome/pro-solid-svg-icons"
import Button from "components/Button"
import SubTabs from "components/SubTabs"
import PaperBox from "components/PaperBox"
import { TabPanel } from "components/navigation/TabPanel"
import ClpTable from "components/ClpTable"
import { IApiFunc, IApiResult } from "auth/interface/api.interface"
import IconForButton from "components/ButtonIcon"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { UserCustomFieldCategorySidebar } from "./UserCustomFieldCategorySidebar"
import { UserCustomFieldSidebar } from "./UserCustomFieldSidebar"
import { UserCustomFieldCategory } from "./userCustomField.interfaces"
import { StyledGreyTypography, StyledPopover } from "./userCustomFields.styled"
import { getFieldTypeDescription } from "./userCustomField.helpers"
import { UserCustomFieldDto } from "../edit/UserCustomField.interfaces"

const tabData = [
  { index: 0, label: "Egna fält", queryParam: "customfields" },
  { index: 1, label: "Kategorier", queryParam: "categories" }
]

const UserCustomFields = () => {
  const [userCustomFieldsResult, userCustomFieldsApi] = useApi() as [IApiResult, IApiFunc]
  const [userCustomFieldsCategoriesResult, userCustomFieldsCategoriesApi] = useApi() as [IApiResult, IApiFunc]
  const [userCustomFieldsActiveCategoriesResult, userCustomFieldsActiveCategoriesApi] = useApi() as [IApiResult, IApiFunc]
  const [customerResult, customerApi] = useApi() as [IApiResult, IApiFunc]
  const appContext = useContext(AppContext)
  const [tabValue, setTabValue] = useState(0)
  const [showCategorySidebar, setShowCategorySidebar] = useState(false)
  const [showCustomFieldSidebar, setShowCustomFieldSidebar] = useState(false)
  const [anchorElement, setAnchorElement] = useState<Element | null>(null)
  const [clickedCategory, setClickedCategory] = useState<UserCustomFieldCategory>()
  const [clickedUserCustomField, setClickedUserCustomField] = useState<UserCustomFieldDto>()
  const initialSort = [
    { id: "name", desc: false },
  ]

  useEffect(() => {
    customerApi.get(apiEndpoints.customeradmin.getcustomer)
  }, [])

  useEffect(() => {
    if (customerResult.status !== 2) return
    fetchData()
  }, [customerResult])

  useEffect(() => {
    if (showCategorySidebar) return
    setClickedCategory(undefined)
  }, [showCategorySidebar])

  useEffect(() => {
    if (showCustomFieldSidebar) return
    setClickedUserCustomField(undefined)
  }, [showCustomFieldSidebar])

  const fetchData = () => {
    const customFieldUrl = apiEndpoints.usercustomfieldsadmin.getusercustomfieldbycustomerid
    userCustomFieldsApi.get(customFieldUrl)
    const categoriesUrl = apiEndpoints.usercustomfieldsadmin.getcustomfieldcategoriesbycustomerid
    userCustomFieldsCategoriesApi.get(categoriesUrl)
    const activeCategoriesUrl = apiEndpoints.usercustomfieldsadmin.getactivecustomfieldcategoriesbycustomerid
    userCustomFieldsActiveCategoriesApi.get(activeCategoriesUrl)
  }

  const customFieldsColumns: any = useMemo(
    () => [
      {
        accessor: "name",
        Header: "Namn",
        Cell: ({ row }: any) => (
          <StyledGreyTypography active={row.original?.category?.active}>{row.original?.name}</StyledGreyTypography>
        )
      },
      {
        accessor: "category.name",
        Header: "Kategori",
        Cell: ({ row }: any) => {
          const text = row.original?.category?.active ? row.original?.category?.name : `${row.original?.category?.name} (Inaktiv)`
          return <StyledGreyTypography active={row.original?.category?.active}>{text}</StyledGreyTypography>
        }
      },
      {
        accessor: "types",
        Header: "Fälttyp",
        Cell: ({ row }: any) => {
          const types = row.original?.valueFields?.map((valueField: any) => {
            return getFieldTypeDescription(valueField.component, valueField.dataType)
          }).join(", ")
          return <StyledGreyTypography active={row.original?.category?.active}>{types}</StyledGreyTypography>
        }
      },
      {
        accessor: "active",
        Header: "Aktiv",
        Cell: ({ row }: any) => {
          const activeText = row.original.active ? "Ja" : "Nej"
          return <StyledGreyTypography active={row.original?.category?.active}>{activeText}</StyledGreyTypography>
        }
      }
    ],
    []
  )

  useEffect(() => {
    if (hasRole(appContext.appUser, allRoles.VehicleRegistryAdmin)) {
      customFieldsColumns.push({
        accessor: "edit",
        disablePadding: true,
        Header: "",
        align: "right",
        Cell: ({ cell }: any) => {
          return (
            <IconButton
              color="primary"
              size="large"
              onClick={() => {
                setClickedUserCustomField(cell.row.original)
                setShowCustomFieldSidebar(true)
              }}
            >
              <FontAwesomeIcon icon={faEdit} size="xs" color="grey" />
            </IconButton>
          )
        }
      })
    }
  }, [])

  const customFieldsCategoriesColumns: any = useMemo(
    () => [
      {
        accessor: "name",
        Header: "Namn",
        Cell: ({ row }: any) => <>{row.original.name}</>
      },
      {
        accessor: "active",
        Header: "Aktiv",
        Cell: ({ row }: any) => <>{row.original.active ? "Ja" : "Nej"}</>
      }
    ],
    []
  )

  useEffect(() => {
    if (hasRole(appContext.appUser, allRoles.VehicleRegistryAdmin)) {
      customFieldsCategoriesColumns.push({
        accessor: "edit",
        disablePadding: true,
        Header: "",
        align: "right",
        Cell: ({ cell }: any) => {
          return (
            <IconButton
              color="primary"
              size="large"
              onClick={() => {
                setClickedCategory(cell.row.original)
                setShowCategorySidebar(true)
              }}
            >
              <FontAwesomeIcon icon={faEdit} size="xs" color="grey" />
            </IconButton>
          )
        }
      })
    }
  }, [])

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElement(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorElement(null)
  }
  const open = Boolean(anchorElement)
  const id = open ? "simple-popover" : undefined

  const categoriesExists = userCustomFieldsCategoriesResult.status === 2 && userCustomFieldsCategoriesResult.data && userCustomFieldsCategoriesResult.data.length > 0
  const activeCategoriesExists = userCustomFieldsActiveCategoriesResult.status === 2 && userCustomFieldsActiveCategoriesResult.data && userCustomFieldsActiveCategoriesResult.data.length > 0
  const userCustomFieldsExists = userCustomFieldsResult.status === 2 && userCustomFieldsResult.data && userCustomFieldsResult.data.length > 0
  const loadingData = userCustomFieldsCategoriesResult.status !== 2 || userCustomFieldsResult.status !== 2

  return (
    <Container>
      <UserCustomFieldCategorySidebar
        showSidebar={showCategorySidebar}
        setShowSidebar={setShowCategorySidebar}
        clickedCategory={clickedCategory}
        handleSubmit={() => {
          fetchData()
        }}
      />
      <UserCustomFieldSidebar
        showSidebar={showCustomFieldSidebar}
        setShowSidebar={setShowCustomFieldSidebar}
        clickedUserCustomField={clickedUserCustomField}
        handleSubmit={() => {
          fetchData()
        }}
      />
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid direction="column">
          <h2 style={{ marginBottom: "10px" }}>Egna fält</h2>
          <Typography variant="bodySmall">Ställ in egna fält.</Typography>
        </Grid>
        <Box>
          <Button
            disabled={!categoriesExists}
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              handleClick(event)
            }}
            startIcon={<IconForButton icon={faPlus as IconProp} disabled={!categoriesExists} color="primary" />}
          >
            Lägg till
          </Button>
          <StyledPopover
            id={id}
            open={open}
            anchorEl={anchorElement}
            onClose={handlePopoverClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
            disableRestoreFocus
          >
            <MenuItem
              disabled={!activeCategoriesExists}
              onClick={() => {
                setShowCustomFieldSidebar(true)
                handlePopoverClose()
              }}
            >
              Eget fält
            </MenuItem>
            <MenuItem
              onClick={() => {
                setShowCategorySidebar(true)
                handlePopoverClose()
              }}
            >
              Kategori
            </MenuItem>
          </StyledPopover>
        </Box>
      </Grid>
      <SubTabs tabs={tabData} value={tabValue} setValue={setTabValue} />
      <Grid container direction="row">
        <Grid item xs={12} md={12} lg={12}>
          <PaperBox mt={0}>
            <TabPanel value={tabValue} index={0}>
              {loadingData || userCustomFieldsExists ? (
                <>
                  <Typography variant="bodySmall">
                    Här hanterar du egna fält för användare.
                  </Typography>
                  <Typography variant="bodySmall" sx={{ marginBottom: "-60px" }}>
                    Individuella inställningar hanteras på respektive användare.
                  </Typography>
                  <ClpTable
                    hideFilter
                    hideSearch
                    hideSubFilter
                    sortParams={initialSort}
                    columns={customFieldsColumns}
                    rows={userCustomFieldsResult.status === 2 && userCustomFieldsResult.data}
                    status={userCustomFieldsResult.status}
                  />
                </>
              ) : loadingData || activeCategoriesExists ? (
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  direction="column"
                  gap={4}
                  sx={{ height: "200px" }}
                >
                  <Typography variant="bodySmall">
                    Du behöver skapa egna fält
                  </Typography>
                  <Button
                    color="primary"
                    onClick={() => {
                      setShowCustomFieldSidebar(true)
                    }}
                  >Skapa eget fält</Button>
                </Grid>
              ) : (
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  direction="column"
                  gap={4}
                  sx={{ height: "200px" }}
                >
                  <Typography variant="bodySmall">
                    Du behöver skapa kategorier för att kunna lägga till egna fält.
                  </Typography>
                  <Button
                    color="primary"
                    onClick={() => {
                      setShowCategorySidebar(true)
                    }}
                  >Skapa kategori</Button>
                </Grid>
              )}
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              {loadingData || categoriesExists ? (
                <>
                  <Typography variant="bodySmall">
                    Här skapar du globala kategorier för egna fält för användare.
                  </Typography>
                  <Typography variant="bodySmall" sx={{ marginBottom: "-60px" }}>
                    Individuella inställningar hanteras på respektive användare.
                  </Typography>
                  <ClpTable
                    hideFilter
                    hideSearch
                    hideSubFilter
                    columns={customFieldsCategoriesColumns}
                    rows={userCustomFieldsCategoriesResult.status === 2 && userCustomFieldsCategoriesResult.data}
                    status={userCustomFieldsCategoriesResult.status}
                  />
                </>
              ) : (
                <Grid container justifyContent="center" alignItems="center" direction="column" gap={4} sx={{ height: "200px" }}>
                  <Typography variant="bodySmall">Du har inte skapat några kategorier än.</Typography>
                  <Button
                    color="primary"
                    onClick={() => {
                      setShowCategorySidebar(true)
                    }}
                  >Skapa kategori</Button>
                </Grid>
              )}
            </TabPanel>
          </PaperBox>
        </Grid>
      </Grid>
    </Container>
  )
}

export default UserCustomFields
