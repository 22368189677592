import { faCloudUpload } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, Typography } from "@mui/material"
import theme from "css/theme/light"
import { useEffect, useMemo, useState } from "react"
import { DropzoneState, useDropzone } from "react-dropzone"

import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { IFormUploadFileProps } from "./formuploadfile.interface"

const baseStyle = {
  flex: 1,
  display: "flex",
  alignItems: "center",
  padding: "20px",
  color: theme.palette.primary.dark,
  outline: "none",
  transition: "background .24s ease-in-out",

  cursor: "pointer",
  margin: "0 15px",
  borderRadius: 4
}

const activeStyle = {
  backgroundColor: "white"
}

const acceptStyle = {
  backgroundColor: "white"
}

const rejectStyle = {
  borderColor: "#ff1744"
}

const FormUploadFile = ({ handleChange, acceptedFileFormat = {} }: IFormUploadFileProps) => {
  const [previewFiles, setPreviewFiles] = useState<File[]>([])
  const [wrongFileFormatWarning, setWrongFileFormatWarning] = useState(false)

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject }: DropzoneState =
    useDropzone({
      multiple: true,
      onDrop: (acceptedFiles: File[], fileRejections) => {
        // handleChange && handleChange(acceptedFiles)
        if (fileRejections.length > 0) {
          setWrongFileFormatWarning(true)
        } else {
          setWrongFileFormatWarning(false)
          setPreviewFiles(acceptedFiles)
        }
      },
      accept: Object.keys(acceptedFileFormat).length > 0 ? acceptedFileFormat : undefined
    })

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isDragActive, isDragReject, isDragAccept]
  )

  const formatAcceptedFileFormat = (formats: { [mimeType: string]: string[] }): string => {
    return Object.values(formats).flat().join(", ")
  }

  const acceptedFileFormatString = formatAcceptedFileFormat(acceptedFileFormat)

  useEffect(() => {
    if (previewFiles && previewFiles.length > 0) {
      handleChange && handleChange(previewFiles)
    }
    return () => {
      // Cleanup function here if needed
    }
  }, [previewFiles])

  return (
    <>
      <Box
        bgcolor="primary.light"
        border={wrongFileFormatWarning ? "1px solid" : "2px dashed"}
        borderColor={wrongFileFormatWarning ? "error.main" : "primary.dark"}
        borderRadius={2}
        marginBottom={1}>
        <Box py={2} {...getRootProps({ style })} flexDirection="column">
          <input {...getInputProps()} />
          <FontAwesomeIcon icon={faCloudUpload as IconProp} color={theme.palette.primary["dark"]} size="3x" />
          <Typography variant="bodyMedium" fontWeight="600">
            Släpp eller klicka här för att välja filer
          </Typography>
          <Typography variant="bodyMedium" fontWeight="600">
            {acceptedFileFormatString && acceptedFileFormatString}
          </Typography>
        </Box>
      </Box>
      <Box>
        {wrongFileFormatWarning && (
          <Box textAlign="left" sx={{ marginTop: "3px", marginX: "14px" }}>
            <Typography variant="labelSmall" color="error.main">
              Filformat stöds inte, filer som stöds är: {acceptedFileFormatString}
            </Typography>
          </Box>
        )}
      </Box>
    </>
  )
}

export default FormUploadFile
